import React from 'react';
import {useTranslation} from 'react-i18next';
import FormButtons from '../../../common/component/form/FormButtons';

type Props = {
    onRequest: () => void,
    onDiscard: () => void
}

function ChildSetupWelfareYesNo({onRequest, onDiscard}: Readonly<Props>) {
    const [t] = useTranslation();

    return <>
        <span>{t('ChildSetup.WELFARE.ASK_DESCRIPTION')}</span>

        <div className="d-flex justify-content-end mt-4">
            <FormButtons
                onSave={onRequest}
                onCancel={onDiscard}
                saveLabelKey="Button.YES"
                cancelLabelKey="Button.NO"
                cancelButtonStyle="secondary"
                buttonsClassName="px-5"
                sticky={false}
            />
        </div>
    </>;
}

export default ChildSetupWelfareYesNo;
