import Iban from 'iban';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {markChanges, unmarkChanges} from '../../common/action/pageLeaveActions';
import CancelButton from '../../common/component/CancelButton';
import ConfirmButton from '../../common/component/ConfirmButton';
import TextInput from '../../common/component/form/TextInput';
import {convertAmount, formatAmount} from '../../common/util/CurrencyUtil';
import {checkValidation} from '../../common/util/ValidationUtil';
import {showError, showMessage} from '../../message/action/messageActions';
import withdrawalApi from '../api/withdrawalApi';
import {capitalizeFirstTwoLetters, checkIsBicMandatory, formatIban} from '../util/BankDataUtils';

function BalanceWithdrawalByCatererTask({
                                            withdrawalAmount: inputWithdrawalAmount,
                                            onDone,
                                            onCancel
                                        }) {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const dispatch = useDispatch();
    const balance = useSelector(state => state.user.account.balance);
    const [withdrawalRequest, setWithdrawalRequest] = useState({
        withdrawalAmount: inputWithdrawalAmount
    });
    const [validation, setValidation] = useState({
        receiverName: true,
        iban: true
    });
    const [showErrors, setShowErrors] = useState(false);

    const handleChange = useCallback((key, value) => {
        dispatch(markChanges());
        setWithdrawalRequest((currentValue) => {
            return {
                ...currentValue,
                [key]: value
            };
        });
    }, [dispatch]);

    const validate = useCallback(() => {
        const withdrawalAmountAsFloat = convertAmount(withdrawalRequest.withdrawalAmount, currentLanguage);

        const newValidation = {
            receiverName: !!withdrawalRequest.receiverName,
            withdrawalAmount: !!withdrawalRequest.withdrawalAmount && balance >= withdrawalAmountAsFloat
                && withdrawalAmountAsFloat > 0,
            iban: !!withdrawalRequest.iban && Iban.isValid(withdrawalRequest.iban),
            bic: !checkIsBicMandatory(withdrawalRequest.iban) || !!withdrawalRequest.bic
        };
        setValidation(newValidation);
        setShowErrors(true);

        return checkValidation(newValidation);
    }, [withdrawalRequest, balance, currentLanguage]);

    useEffect(() => {
        if (showErrors) {
            validate();
        }
    }, [withdrawalRequest, validate, showErrors]);

    const isInvalidIban = useMemo(() => withdrawalRequest.iban && withdrawalRequest.iban.length > 0 && !Iban.isValid(withdrawalRequest.iban), [withdrawalRequest]);

    const formatIbanOnBlur = useCallback(() => {
        handleChange('iban', capitalizeFirstTwoLetters(formatIban(withdrawalRequest.iban)));
    }, [withdrawalRequest, handleChange]);

    const handleSave = useCallback(() => {
        if (validate()) {
            setShowErrors(false);

            const withdrawalRequestToSave = {
                ...withdrawalRequest,
                withdrawalAmount: convertAmount(withdrawalRequest.withdrawalAmount, currentLanguage)
            };

            withdrawalApi.requestWithdrawalWithTask(withdrawalRequestToSave).then((response) => {
                if (response.data.success) {
                    dispatch(showMessage('Finance.BALANCE_WITHDRAWAL.SAVE_SUCCESS'));
                    dispatch(unmarkChanges());
                    onDone();
                }
            });
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
        }
    }, [withdrawalRequest, validate, currentLanguage, dispatch, onDone]);

    const handleCancel = useCallback(() => {
        onCancel();
        dispatch(unmarkChanges());
    }, [onCancel, dispatch]);

    return (<>
        {t('Finance.BALANCE_WITHDRAWAL.CATERER_TRANSFER_INSTRUCTIONS')}

        <div className="mt-4">
            <TextInput
                label={t('Finance.BALANCE_WITHDRAWAL.WITHDRAWAL_AMOUNT_INPUT')}
                value={formatAmount(withdrawalRequest.withdrawalAmount, currentLanguage)}
                readonly={true}
            />
            <TextInput
                label={t('Finance.BALANCE_WITHDRAWAL.ACCOUNT_HOLDER')}
                onChange={value => handleChange('receiverName', value)}
                value={withdrawalRequest.receiverName}
                isValid={!showErrors || validation.receiverName}
                required={true}
            />
            <TextInput
                label={t('PaymentInformation.IBAN')}
                onChange={value => handleChange('iban', value)}
                value={withdrawalRequest.iban}
                isValid={!showErrors || validation.iban}
                errorMessage={isInvalidIban ? t('Validation.INVALID_IBAN') : null}
                onBlur={formatIbanOnBlur}
                required={true}
            />
            <TextInput
                label={t('PaymentInformation.BIC')}
                onChange={value => handleChange('bic', value)}
                value={withdrawalRequest.bic}
                isValid={!showErrors || validation.bic}
                required={checkIsBicMandatory(withdrawalRequest.iban)}
                readonly={!withdrawalRequest.bic && !checkIsBicMandatory(withdrawalRequest.iban)}
                placeholder={!checkIsBicMandatory(withdrawalRequest.iban) ? t('Finance.SEPA.BIC_NOT_REQUIRED') : null}
            />
        </div>

        <div className="text-right mt-4">
            <CancelButton onClick={handleCancel}/>
            <ConfirmButton onClick={handleSave} disabled={balance <= 0} label={t('Button.SAVE')}/>
        </div>
    </>);
}

export default BalanceWithdrawalByCatererTask;
