import React, {useRef, useState} from 'react';
import {Overlay, Popover} from 'react-bootstrap';

const PopoverContext = React.createContext({});

function DefaultPopover({placement, children}) {

    const [showPopover, setShowPopover] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const ref = useRef(null);

    const togglePopover = (event) => {
        event.preventDefault();
        setShowPopover(!showPopover);
        setPopoverTarget(event.target);
    };

    const value = {
        showPopover,
        popoverTarget,
        togglePopover,
        setShowPopover,
        placement,
        ref
    };
    return (
        <PopoverContext.Provider value={value}>
            {children}
        </PopoverContext.Provider>
    );
}

function usePopoverContext() {
    const context = React.useContext(PopoverContext);
    if (!context) {
        throw new Error('Popover content outside of popover');
    }
    return context;
}

/**
 * Adds ref and onClick to child element
 * @param children
 */
function Trigger({children}) {
    const {ref, togglePopover} = usePopoverContext();
    return React.cloneElement(children, {
        ref: ref,
        onClick: togglePopover
    });
}

/**
 * Displays overlay as popover
 * @param id popover id
 * @param children popover content
 */
function Content({id = 'popover-content', children}) {
    const {showPopover, ref, popoverTarget, setShowPopover, placement} = usePopoverContext();
    return <Overlay
        rootClose={true}
        show={showPopover}
        target={popoverTarget}
        placement={placement}
        container={ref.current}
        onHide={() => setShowPopover(false)}
    >
        <Popover id={id}>
            <Popover.Content>
                {children}
            </Popover.Content>
        </Popover>
    </Overlay>;
}

DefaultPopover.Trigger = Trigger;
DefaultPopover.Content = Content;

export default DefaultPopover;

