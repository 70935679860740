import React from 'react';
import {NavDropdown} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import NavigationCaterer from './NavigationCaterer';

function NavigationAdmin() {
    const [t] = useTranslation();
    const history = useHistory();

    const location = useLocation();
    const activeAdminLocation = location.pathname && !!location.pathname.includes('/users');

    function handleItemClick(destiny) {
        history.push(destiny);
    }

    const buildAdditionalOptions = () => {
        return (
            <>
                <NavDropdown.Item onClick={() => handleItemClick('/users')}
                                  className={`dropdown-navigation-link ${location.pathname.includes('/users') ? 'active' : ''}`}
                >
                    {t('Navigation.ACCOUNT_ADMINISTRATION')}
                </NavDropdown.Item>
            </>
        );
    };

    return (
        <>
            <NavigationCaterer additionalOptions={buildAdditionalOptions()} additionalActiveOptions={activeAdminLocation} isAdmin/>
        </>
    );
}

export default NavigationAdmin;
