import React from 'react';
import {useTranslation} from 'react-i18next';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';

type Props = {
    onClick: () => void,
    className?: string
}

function BackButton({onClick, className}: Readonly<Props>) {
    const [t] = useTranslation();

    return <button className={'btn btn-transparent ' + (className ?? '')} onClick={onClick}>
        <div className="mr-2"><ArrowLeftIcon/></div>
        {t('Button.BACK')}
    </button>;
}

export default BackButton;
