export const setCarrierTableFilter = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CARRIER_FILTER',
        value
    });
};

export const resetCarrierTableFilter = () => (dispatch) => {
    dispatch({
        type: 'RESET_CARRIER_FILTER'
    });
};

export const setCarrierTablePageIndex = (pageIndex) => (dispatch) => {
    dispatch({
        type: 'SET_CARRIER_PAGE_INDEX',
        pageIndex: pageIndex
    });
};
