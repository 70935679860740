import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import accountApi from '../../../account/api/accountApi';
import {Child} from '../../../types/Child';
import {ChildWelfare} from '../../../types/child/ChildWelfare';
import childApi from '../../api/ChildApi';
import ChildSetupWelfareSelection from './ChildSetupWelfareSelection';
import ChildSetupWelfareYesNo from './ChildSetupWelfareYesNo';

type Props = {
    child: Child,
    onProceed: (welfare: ChildWelfare) => void,
    onSkip: () => void
}

function ChildSetupWelfare({child, onProceed, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();

    const [isWelfareRequested, setIsWelfareRequested] = useState(false);

    const updateWelfare = (welfare: ChildWelfare) => {
        childApi.createOrUpdateWelfare(welfare, child.id).then(welfareRes => {
            if (welfareRes.data.success) {
                const savedWelfare = welfareRes.data.result;

                if (welfare.file) {
                    const formData = new FormData();
                    formData.append('file', welfare.file as unknown as File);
                    formData.append('childWelfareId', savedWelfare.id);
                    formData.append('childId', child?.id.toString());
                    formData.append('welfareDepartment', welfare.welfareDepartment);
                    accountApi.uploadWelfareAppliance(formData);
                }

                onProceed(savedWelfare);
            }
        });

    }

    return <div className="card">
        {!isWelfareRequested && <div className="card-header">
            {t('ChildSetup.WELFARE.HEADER')}
        </div>}

        <div className="card-body">
            {
                isWelfareRequested ?
                    <ChildSetupWelfareSelection child={child}
                                                onSkip={() => onSkip()}
                                                onProceed={updateWelfare}
                    />
                    :
                    <ChildSetupWelfareYesNo onRequest={() => setIsWelfareRequested(true)}
                                            onDiscard={() => onSkip()}
                    />
            }
        </div>
    </div>;
}

export default ChildSetupWelfare;
