import React from 'react';

const ServingIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M11.75 7.5C11.3358 7.5 11 7.83579 11 8.25C11 8.66421 11.3358 9 11.75
                  9C13.072 9 14.4618 9.75866 15.1594 10.7557C15.3969 11.0951 15.8645 11.1778
                  16.2039 10.9403C16.5433 10.7028 16.6259 10.2352 16.3885 9.89583C15.4134
                  8.5022 13.5672 7.5 11.75 7.5ZM9.5 4.5C9.5 3.11929 10.6193 2 12 2C13.3807 2
                  14.5 3.11929 14.5 4.5V5.37957C17.8398 6.43318 20.25 9.56082 20.25 13.25C20.25
                  13.6642 19.9142 14 19.5 14H4.5C4.08579 14 3.75 13.6642 3.75 13.25C3.75 9.57135
                  6.17219 6.45696 9.5 5.39206V4.5ZM13 4.5C13 3.94772 12.5523 3.5 12 3.5C11.4477
                  3.5 11 3.94772 11 4.5V5.06264C11.3348 5.0213 11.6757 5.00002 12.0214 5.00002C12.3527
                  5.00002 12.6792 5.01954 13 5.0575V4.5ZM5.29153 12.5H18.7091C18.3382 9.12363 15.4925
                  6.50002 12.0214 6.50002C8.54556 6.50002 5.66724 9.12797 5.29153 12.5ZM4 15C2.89543
                  15 2 15.8954 2 17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17C22 15.8954
                  21.1046 15 20 15H4ZM3.5 17C3.5 16.7239 3.72386 16.5 4 16.5H20C20.2761 16.5 20.5 16.7239
                  20.5 17C20.5 17.2761 20.2761 17.5 20 17.5H4C3.72386 17.5 3.5 17.2761 3.5 17Z"
            />
        </svg>

    );
};

export default ServingIcon;
