import {httpClient} from '../../common/api/HttpClient';

export const publicCatererApi = {
    findCatererTextResources: (catererId, types) => httpClient.get(`public/caterer/${catererId}/resources/text`, {params: formatListParams('types', types)}),
    findCatererBinaryResources: (catererId, types) => httpClient.get(`public/caterer/${catererId}/resources/binary`, {params: formatListParams('types', types)}),
    findPublicCatererProfileById: (catererId) => httpClient.get(`caterers/public-profile/${catererId}`),
    findPublicCatererStyling: (catererId) => httpClient.get(`public/caterer/${catererId}/styling`),
    findCatererTimeManagements: (catererId) => httpClient.get(`public/caterer/${catererId}/time-management`),
    findCatererNews: (catererId) => httpClient.get(`public/caterer/${catererId}/news`)
};

function formatListParams(paramName, paramList) {
    const urlSearchParams = new URLSearchParams();
    paramList.forEach(param => urlSearchParams.append(paramName, param))

    return urlSearchParams;
}

export default publicCatererApi;
