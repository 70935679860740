import React from 'react';

const ArrowSortDownIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M11.6491 4.00685L11.7509 4C12.1306 4 12.4444 4.28215 12.494 4.64823L12.5009 4.75L12.5 17.446L15.7194
                  14.2248C15.9856 13.9585 16.4023 13.9341 16.696 14.1518L16.7801 14.2244C17.0465 14.4905 17.0709 14.9072 16.8531
                  15.2009L16.7806 15.2851L12.2843 19.7851C12.0182 20.0514 11.6015 20.0758 11.3078 19.8581L11.2237 19.7855L6.71989
                  15.2855C6.42688 14.9927 6.42668 14.5179 6.71945 14.2248C6.9856 13.9585 7.40226 13.9341 7.69596 14.1518L7.78011
                  14.2244L11 17.442L11.0009 4.75C11.0009 4.3703 11.283 4.05651 11.6491 4.00685L11.7509 4L11.6491 4.00685Z"/>
        </svg>
    );
};

export default ArrowSortDownIcon;
