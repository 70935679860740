import React from 'react';

type Props = {
    size?: number;
}

const ArrowRightIcon = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M13.7673 4.20889C13.4674 3.9232 12.9926 3.93475 12.7069 4.23467C12.4212 4.5346 12.4328 5.00933 12.7327 5.29502L18.9841
                     11.2496H4.25C3.83579 11.2496 3.5 11.5854 3.5 11.9996C3.5 12.4138 3.83579 12.7496 4.25 12.7496H18.9842L12.7327 18.7043C12.4328
                     18.99 12.4212 19.4648 12.7069 19.7647C12.9926 20.0646 13.4674 20.0762 13.7673 19.7905L21.1862 12.7238C21.3551 12.5629 21.4551
                     12.3576 21.4861 12.1443C21.4952 12.0975 21.5 12.0491 21.5 11.9996C21.5 11.9501 21.4952 11.9016 21.486 11.8547C21.455 11.6415
                     21.355 11.4364 21.1862 11.2756L13.7673 4.20889Z"
            />
        </svg>
    );
};

export default ArrowRightIcon;
