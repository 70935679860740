import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NextButton from '../../../common/component/NextButton';
import Week from '../../../common/component/Week';
import {ChildSubscription} from '../../../types/child/ChildSubscription';
import {Weekday} from '../../../types/enums/Weekday';

type Props = {
    weekdays: Array<string>,
    subscription: Partial<ChildSubscription>,
    onChange: (key: string, value: any) => void,
    onProceed: () => void,
    onSkip: () => void
}

function ChildSetupSubscriptionWeekdays({weekdays, subscription, onChange, onProceed, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();

    const [showMissingSelection, setShowMissingSelection] = useState(false);

    useEffect(() => {
        if (showMissingSelection) {
            setShowMissingSelection(!subscription.weekdays || subscription.weekdays.length === 0);
        }
    }, [subscription.weekdays, showMissingSelection]);

    const handleClickNext = useCallback(() => {
        if (subscription.weekdays && subscription.weekdays.length > 0) {
            onProceed();
        } else {
            setShowMissingSelection(true);
        }
    }, [subscription, onProceed]);

    return <div className="card">
        <div className="card-header">{t('ChildSetup.SUBSCRIPTION.HEADER_WEEKDAYS')}</div>
        <div className="card-body">
            <p>{t('ChildSetup.SUBSCRIPTION.SELECT_WEEKDAYS')}</p>
            {
                showMissingSelection &&
                <p>{t('')}</p>
            }
            {/* @ts-ignore */}
            <Week
                availableDays={weekdays}
                selectedDays={subscription.weekdays}
                onUpdateWeekdayArray={(weekdays: Array<Weekday>) => onChange('weekdays', weekdays)}
                isValid={!showMissingSelection}
            />
            <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-transparent mr-2" onClick={onSkip}>{t('ChildSetup.SKIP_SUBSCRIPTION')}</button>
                <NextButton onClick={handleClickNext}/>
            </div>
        </div>
    </div>;
}

export default ChildSetupSubscriptionWeekdays;
