import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {markChanges, unmarkChanges} from '../../../common/action/pageLeaveActions';
import ConfirmButton from '../../../common/component/ConfirmButton';
import TextInput from '../../../common/component/form/TextInput';
import NextButton from '../../../common/component/NextButton';
import Permission from '../../../common/enums/Permission';
import {useHasPermission} from '../../../common/util/PermissionUtil';
import {checkValidation} from '../../../common/util/ValidationUtil';
import {showMessage} from '../../../message/action/messageActions';
import {Child} from '../../../types/Child';
import {Group} from '../../../types/institution/Group';
import childApi from '../../api/ChildApi';
import GroupInput from '../GroupInput';
import ChildSetupOnSiteOrderLimits from './ChildSetupOnSiteOrderLimits';

type Props = {
    catererId: number,
    accountId: number,
    onProceed: (child: Child) => void,
    onSaveAndDone: (child: Child) => void
}

function ChildSetupBasics({catererId, accountId, onProceed, onSaveAndDone}: Readonly<Props>) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const hasAccountPermission = useHasPermission(Permission.WRITE_PARENT);

    const [childToSetup, setChildToSetup] = useState<Partial<Child>>({accountId});
    const [group, setGroup] = useState<Group>();
    const [validation, setValidation] = useState({
        firstName: true,
        lastName: true,
        groupCode: true,
        onSiteOrderLimit: true
    });
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [isGroupMandatoryState, setIsGroupMandatoryState] = useState(true);

    const validate = useCallback((isGroupMandatory?: boolean) => {
        const effectiveGroupMandatory = isGroupMandatory ?? isGroupMandatoryState;
        const newValidation = {
            firstName: !!childToSetup.firstName,
            lastName: !!childToSetup.lastName,
            groupCode: (!effectiveGroupMandatory && !childToSetup.groupCode) || !!group,
            onSiteOrderLimit: !group?.institutionUsesOnSiteOrder || !!childToSetup.dailyOnSiteOrderLimit || childToSetup.dailyOnSiteOrderLimit === 0
        };

        setShowValidationErrors(true);
        setValidation(newValidation);

        return checkValidation(newValidation);
    }, [childToSetup, isGroupMandatoryState, group]);

    useEffect(() => {
        if (showValidationErrors) {
            validate();
        }
    }, [childToSetup, showValidationErrors, validate]);

    const handleChildChange = useCallback((key: string, value: any) => {
        dispatch(markChanges());
        setChildToSetup(currentValue => (
            {
                ...currentValue,
                [key]: value
            }
        ));
    }, [dispatch]);

    const handleGroupChange = useCallback((group: Group | null, selectedGroupCode: string | null) => {
        if (group) {
            setGroup(group);
            handleChildChange('groupCode', group.code);
        } else {
            setGroup(undefined);
            handleChildChange('groupCode', selectedGroupCode);
        }
    }, [handleChildChange]);

    const saveChild = useCallback(() => {
        setIsGroupMandatoryState(false);
        if (validate(false)) {
            childApi.createOrUpdate(childToSetup).then(response => {
                if (response.data.success) {
                    dispatch(showMessage('Child.ADDED_NEW'));
                    dispatch(unmarkChanges());
                    onSaveAndDone(response.data.result);
                }
            });
        }
    }, [childToSetup, validate, dispatch, onSaveAndDone]);

    const handleProceed = useCallback(() => {
        setIsGroupMandatoryState(true);
        if (validate(true)) {
            childApi.createOrUpdate(childToSetup).then(response => {
                if (response.data.success) {
                    dispatch(showMessage('Child.ADDED_NEW'));
                    dispatch(unmarkChanges());
                    onProceed(response.data.result);
                }
            });
        }
    }, [validate, childToSetup, onProceed, dispatch]);

    return <div className="card">
            <div className="card-body">
            {
                !hasAccountPermission &&
                <div className="mb-3 pre-wrap">
                    {t('ChildSetup.INTRO')}
                </div>
            }

            <div className="row">
                <div className="col-12 col-md-6">
                    <TextInput
                        label={t('Child.FIRST_NAME')}
                        value={childToSetup?.firstName}
                        onChange={(value) => handleChildChange('firstName', value)}
                        required={true}
                        isValid={!showValidationErrors || validation.firstName}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextInput
                        label={t('Child.LAST_NAME')}
                        value={childToSetup?.lastName}
                        onChange={(value) => handleChildChange('lastName', value)}
                        required={true}
                        isValid={!showValidationErrors || validation.lastName}
                    />
                </div>
                <div className="col-12">
                    <GroupInput
                        group={group}
                        groupCode={childToSetup?.groupCode}
                        onChangeGroup={handleGroupChange}
                        isValid={validation.groupCode}
                        showError={!validation.groupCode && showValidationErrors}
                        childName={`${childToSetup.firstName} ${childToSetup.lastName}`}
                        catererId={catererId}
                        childId={childToSetup.id}
                        required={!hasAccountPermission}
                    />
                </div>
            </div>

            {
                group?.institutionUsesOnSiteOrder &&
                <ChildSetupOnSiteOrderLimits child={childToSetup as Child}
                                             onChange={(limit: number) => handleChildChange('dailyOnSiteOrderLimit', limit)}
                                             showValidationError={showValidationErrors && !validation.onSiteOrderLimit}                />
            }

            <div className="d-flex justify-content-end mt-4">
                {
                    hasAccountPermission &&
                    <ConfirmButton onClick={saveChild} label={t('Button.SAVE_AND_EXIT')} className="mr-2"/>
                }
                <NextButton onClick={handleProceed}/>
            </div>
        </div>
    </div>;
}

export default ChildSetupBasics;
