import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CancelButton from '../../../common/component/CancelButton';
import DateInput from '../../../common/component/form/DateInput';
import TextInput from '../../../common/component/form/TextInput';
import NextButton from '../../../common/component/NextButton';
import WelfareDepartmentType from '../../../common/enums/WelfareDepartmentType';
import {checkValidation} from '../../../common/util/ValidationUtil';
import taskApi from '../../../taskboard/api/taskApi';
import {Child} from '../../../types/Child';
import '../../style/childSetup/ChildSetup.scss';
import {ChildWelfare} from '../../../types/child/ChildWelfare';
import DepartmentSelectionButtons from '../welfare/DepartmentSelectionButtons';
import WelfareUploader from '../welfare/WelfareUploader';

type Props = {
    child: Child,
    onSkip: () => void,
    onProceed: (welfare: ChildWelfare) => void,
}

type ValidationType = {
    validTo?: boolean;
    birthDate?: boolean;
    bgNumber?: boolean;
    welfareDepartment?: boolean;
    file?: boolean;
}

type DocumentType = {
    name: string;
    content: string[];
    id: number;
}

function ChildSetupWelfareSelection({child, onSkip, onProceed}: Readonly<Props>) {
    const [t] = useTranslation();
    const welfare = child.childWelfare[0];

    const [welfareToEdit, setWelfareToEdit] = useState({
        ...welfare,
        welfareDepartment: welfare?.welfareDepartment || WelfareDepartmentType.LANDRATSAMT
    });
    const [validation, setValidation] = useState<ValidationType>({});
    const [showErrors, setShowErrors] = useState(false);
    const [documents, setDocuments] = useState<DocumentType[]>([]);

    const isLandratsamt = welfareToEdit.welfareDepartment === WelfareDepartmentType.LANDRATSAMT;
    const isJobcenter = welfareToEdit.welfareDepartment === WelfareDepartmentType.JOBCENTER;

    useEffect(() => {
        if (welfare) {
            setWelfareToEdit(welfare);
            if (welfare.task?.id) {
                taskApi.findTaskDocumentsById(welfare.task.id).then((res) => {
                    if (res.data.success) {
                        setDocuments(res.data.result);
                    }
                });
            }
        }
    }, [welfare]);

    function validate() {
        const newValidation: { [key: string]: boolean } = {};

        newValidation.welfareDepartment = !!welfareToEdit.welfareDepartment;

        switch (welfareToEdit.welfareDepartment) {
            // @ts-ignore intentional fall-through
            case WelfareDepartmentType.JOBCENTER:
                newValidation.bgNumber = !!welfareToEdit.bgNumber;
            // @ts-ignore intentional fall-through
            // eslint-disable-next-line no-fallthrough
            case WelfareDepartmentType.SOZIALAMT:
            // @ts-ignore intentional fall-through
            // eslint-disable-next-line no-fallthrough
            case WelfareDepartmentType.YOUTH_AND_FAMILY:
                newValidation.validTo = !!welfareToEdit.validTo;
                newValidation.file = !!welfareToEdit.file || documents.length > 0;
            // @ts-ignore intentional fall-through
            // eslint-disable-next-line no-fallthrough
            case WelfareDepartmentType.LANDRATSAMT:
                newValidation.birthDate = !!welfareToEdit.birthDate;
                newValidation.welfareDepartment = !!welfareToEdit.welfareDepartment;
        }

        setValidation(newValidation);
        setShowErrors(true);

        return checkValidation(newValidation);
    }

    function handleChange(field: string, value: any) {
        if (field === 'file') {
            value = value.target.files[0];
        }
        if ((field === 'birthDate' || field === 'validTo') && value) {
            value.setHours(12);
            value.setMinutes(0);
            value.setSeconds(0);
        }
        const newWelfare = {
            ...welfareToEdit,
            [field]: value
        };

        setWelfareToEdit(newWelfare);

        if (field === 'welfareDepartment') {
            setShowErrors(false);
        }
    }

    function confirm() {
        if (validate()) {
            welfareToEdit.edited = true;
            onProceed(welfareToEdit);
        }
    }

    function getDescription() {
        if (!welfareToEdit || !welfareToEdit.welfareDepartment) {
            return '';
        }
        if (welfareToEdit.welfareDepartment !== WelfareDepartmentType.LANDRATSAMT) {
            let verificationFile;
            switch (welfareToEdit.welfareDepartment) {
                case WelfareDepartmentType.SOZIALAMT:
                    verificationFile = t('Welfare.SOZIALAMT_DOCUMENT');
                    break;
                case WelfareDepartmentType.YOUTH_AND_FAMILY:
                    verificationFile = t('Welfare.YOUTH_AND_FAMILY_DOCUMENT_DOCUMENT');
                    break;
                case  WelfareDepartmentType.JOBCENTER:
                    verificationFile = t('Welfare.JOBCENTER_DOCUMENT');
                    break;
            }
            return t('Welfare.NEED_UPLOAD_DESCRIPTION', {verificationType: verificationFile});
        } else {
            return t('Welfare.UPLOAD_CATERER_DESCRIPTION');
        }
    }

    return <>
        <DepartmentSelectionButtons
            selectedDepartment={welfareToEdit.welfareDepartment}
            onClick={department => handleChange('welfareDepartment', department)}/>

        <div className="mt-4 mb-3">
            {getDescription()}
        </div>


        <div className="row">
            <div className="col-12 col-lg-6">
                <DateInput
                    label={t('Welfare.CHILD_BIRTHDATE')}
                    onChange={value => handleChange('birthDate', value)}
                    isValid={showErrors ? validation.birthDate : true}
                    value={welfareToEdit.birthDate ? new Date(welfareToEdit.birthDate) : null}
                    maxDate={Date.now()}
                    required={true}
                />
            </div>
            <div className="col-12 col-lg-6">
                <TextInput
                    label={t('Welfare.BG_NUMBER')}
                    onChange={value => handleChange('bgNumber', value)}
                    value={welfareToEdit.bgNumber}
                    isValid={!isJobcenter || (showErrors ? validation.bgNumber : true)}
                    required={isJobcenter}
                />
            </div>
            <div className="col-12 col-lg-6">
                <WelfareUploader
                    onChange={handleChange}
                    fileName={welfareToEdit.file ? welfareToEdit.file.name : documents && documents.length > 0 && documents[0].name}
                    showErrors={showErrors}
                    required={!isLandratsamt}
                />
            </div>
            <div className="col-12 col-lg-6">
                <DateInput
                    label={t('Welfare.VALID_TO')}
                    onChange={value => handleChange('validTo', value)}
                    value={welfareToEdit.validTo ? new Date(welfareToEdit.validTo) : null}
                    minDate={Date.now()}
                    isValid={showErrors ? validation.validTo : true}
                    required={!isLandratsamt}
                />
            </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
            <CancelButton onClick={onSkip} label={t('ChildSetup.SKIP_WELFARE')} showIcon={false}/>
            <NextButton onClick={confirm}/>
        </div>
    </>;
}

export default ChildSetupWelfareSelection;
