const Subject = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    PROFILE: 'PROFILE', // labelValues: name, address, email
    PASSWORD: 'PASSWORD',
    SEPA_MANDATE: 'SEPA_MANDATE',
    BALANCE_WITHDRAWAL: 'BALANCE_WITHDRAWAL', // labelValues: amount
    CHILD_PROFILE: 'CHILD_PROFILE', // labelValues: name
    CHILD_WELFARE: 'CHILD_WELFARE', // labelValues: department, from, to
    CHILD_ALLERGIES: 'CHILD_ALLERGIES', // labelValues: allergy
    ORDER: 'ORDER', // labelValues: mealDate, orderedByCaterer
    SUBSCRIPTION: 'SUBSCRIPTION', // labelValues from, to, weekdays :up to 5
    MENU_DOWNLOAD: 'MENU_DOWNLOAD',
    INSTITUTION: 'INSTITUTION',
    INSTITUTION_CONFIGURATION: 'INSTITUTION_CONFIGURATION',
    INSTITUTION_GROUP: 'INSTITUTION_GROUP',
    INSTITUTION_CONTACT: 'INSTITUTION_CONTACT',
    INSTITUTION_CLOSING_DAYS: 'INSTITUTION_CLOSING_DAYS',
    INSTITUTION_REGULAR_CLOSING_DAYS: 'INSTITUTION_REGULAR_CLOSING_DAYS',
    RFID: 'RFID',
    RFID_BLOCKED: 'RFID_BLOCKED',
    GROUP_ORDER: 'GROUP_ORDER',
    GROUP_SUBSCRIPTION: 'GROUP_SUBSCRIPTION',
    INSTITUTION_MENU_GROUPS: 'INSTITUTION_MENU_GROUPS',
    INSTITUTION_PRICES: 'INSTITUTION_PRICES',
    INSTITUTION_PRICE_RESET: 'INSTITUTION_PRICE_RESET'
};

export default Subject;
