import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dropdown from '../../../common/component/form/Dropdown';
import InstitutionType from '../../../common/enums/InstitutionType';
import {emptyDropdown} from '../../util/AccountEditUtil';

function InstitutionTypeDropDown({
                                     value,
                                     onChange
                                 }) {
    const [t] = useTranslation();
    const [selectedType, setSelectedType] = useState(emptyDropdown);

    useEffect(() => {
        setSelectedType(value ? {
            label: t(`InstitutionType.${value}`),
            value: value
        } : emptyDropdown);
    }, [value]);

    function getOptions() {
        let options = [];
        options = options.concat(Object.values(InstitutionType).map(type => ({
            label: t(`InstitutionType.${type}`),
            value: type
        })));
        return options;
    }

    return (
        <Dropdown
            label={t('Institution.TYPE')}
            options={getOptions()}
            value={selectedType}
            onChange={onChange}
        />
    );
}

export default InstitutionTypeDropDown;
