import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useSelector} from 'react-redux';
import Meal from '../../common/component/meal/Meal';
import Icon from '../../common/icons/Icon';
import LeftIcon from '../../common/icons/LeftIcon';
import RightIcon from '../../common/icons/RightIcon';
import {isCatererBusinessDay} from '../../common/util/CatererBusinessDaysUtil';
import {equalDate, formatDate} from '../../common/util/DateUtil';
import '../style/MealSlider.scss';

function MealSlider({
                        meals,
                        fromDate,
                        toDate
                    }) {
    const [t] = useTranslation();
    const catererBusinessDays = useSelector(state => state.caterer.timeManagementChild?.businessDays);
    const carouselRef = useRef(null);
    const [slideIndex, setSlideIndex] = useState(0);
    const [totalItems, setTotalItems] = useState(1);
    const responsive = {
        desktop: {
            breakpoint: {
                max: 8000,
                min: 1024
            },
            items: 5
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1
        }
    };
    if (!meals || !meals.length) {
        return <p className="text-muted text-center mt-3">{t('MealPlan.NO_ORDER')}</p>;
    }

    function showMeals() {
        const res = [];
        const d = new Date(fromDate);
        while (true) {
            if (catererBusinessDays && isCatererBusinessDay(d, catererBusinessDays)) {
                const mealItems = meals.filter(meal => equalDate(new Date(meal.mealDate), d)).map(meal =>
                    <div key={meal.mealDate}>
                        <Meal meal={meal}/>
                    </div>);
                if (mealItems.length > 0) {
                    res.push(mealItems);
                } else {
                    res.push(
                        <div key={d}>
                            <Meal meal={{
                                mealDate: formatDate('en-US', d),
                                menus: [{date: formatDate('en-US', d)}]
                            }}/>
                        </div>);
                }
            }

            d.setDate(d.getDate() + 1);
            if (d > toDate) {
                break;
            }
        }
        return res;
    }

    return (
        <div id="meal-slider">
            <div className="arrow-left">
                {
                    slideIndex > 0 &&
                    <Icon src={<LeftIcon size={0.75}/>}
                          className="multiple-carousel-arrow-left"
                          onClick={() => {
                              setTotalItems(carouselRef.current.state.totalItems - carouselRef.current.state.slidesToShow);
                              setSlideIndex(carouselRef.current.state.currentSlide - 1);
                              carouselRef.current.previous();
                          }}
                    />
                }

            </div>
            <div className="carousel-list">
                <Carousel responsive={responsive}
                          autoPlaySpeed={800000}
                          ref={carouselRef}
                          arrows={false}
                          afterChange={(previousSlide, {
                              currentSlide,
                              onMove
                          }) => {
                              setSlideIndex(currentSlide);
                              setTotalItems(carouselRef.current.state.totalItems - carouselRef.current.state.slidesToShow);
                          }}
                >
                    {
                        showMeals()
                    }
                </Carousel>
            </div>
            <div className="arrow-right">
                {
                    slideIndex < totalItems &&
                    <Icon src={<RightIcon size={0.75}/>}
                          className="multiple-carousel-arrow-right"
                          onClick={() => {
                              setTotalItems(carouselRef.current.state.totalItems - carouselRef.current.state.slidesToShow);
                              setSlideIndex(carouselRef.current.state.currentSlide + 1);
                              carouselRef.current.next();
                          }}
                    />
                }
            </div>
        </div>
    );
}

export default MealSlider;
