import React from 'react';
import CheckIcon from '../icons/CheckIcon';
import Icon from '../icons/Icon';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';
import '../style/ConfirmCard.scss';

type Props = {
    title: string,
    description?: string,
    primaryButtonTitle: string,
    onClickPrimary: () => void,
    hidePrimaryIcon?: boolean,
    secondaryButtonTitle?: string,
    onClickSecondary?: () => void,
    hideSecondaryIcon?: boolean,
    showCheckmark?: boolean,
}

const ConfirmCard = ({title, description, primaryButtonTitle, onClickPrimary, hidePrimaryIcon, secondaryButtonTitle, onClickSecondary, hideSecondaryIcon, showCheckmark = true}: Readonly<Props>) => {
    return (
        <div className="card confirm-card">
            {showCheckmark && <Icon src={<CheckIcon size={3}/>} className="mt-3 icon-primary-not-clickable"/>}

            <div className="card-header">{title}</div>

            <div className="card-body px-4 pb-4">
                {!!description && <div className="pb-1 pre-wrap">{description}</div>}

                <div className="d-flex justify-content-end mt-4">
                    {
                        !!secondaryButtonTitle && !!onClickSecondary &&
                        <CancelButton onClick={onClickSecondary} label={secondaryButtonTitle} showIcon={!hideSecondaryIcon} buttonStyle="secondary" className="px-5"/>
                    }
                    <ConfirmButton onClick={onClickPrimary} label={primaryButtonTitle} showIcon={!hidePrimaryIcon} className="px-5"/>
                </div>
            </div>
        </div>
    );
};

export default ConfirmCard;
