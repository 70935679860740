import React from 'react';

const LoginIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="currentColor"
                d="M8.99995 20.4C8.66858 20.4 8.39995 20.1313 8.39995 19.8C8.39995 19.4687 8.66858
                19.2 8.99995 19.2H16.8C18.1255 19.2 19.2 18.1255 19.2 16.8V7.2C19.2 5.87451 18.1255
                4.8 16.8 4.8H8.99995C8.66858 4.8 8.39995 4.53137 8.39995 4.2C8.39995 3.86863 8.66858
                3.6 8.99995 3.6H16.8C18.7882 3.6 20.4 5.21178 20.4 7.2V16.8C20.4 18.7883 18.7882
                20.4 16.8 20.4H8.99995ZM9.17569 7.37574C9.41 7.14141 9.7899 7.14141 10.0242 7.37574L14.2243
                11.5757C14.4585 11.8101 14.4585 12.19 14.2243 12.4243L10.0242 16.6243C9.7899 16.8586
                9.41 16.8586 9.17569 16.6243C8.94137 16.39 8.94137 16.01 9.17569 15.7757L12.3514
                12.6H1.79995C1.46858 12.6 1.19995 12.3313 1.19995 12C1.19995 11.6686 1.46858 11.4
                1.79995 11.4H12.3514L9.17569 8.22426C8.94137 7.98995 8.94137 7.61005 9.17569 7.37574Z"/>
        </svg>
    );
};

export default LoginIcon;
