import React from 'react';
import {useTranslation} from 'react-i18next';
import Tag from '../../common/component/Tag';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';

type Props = {
    state: TaskStateEnum
    size?: string
}

function TaskStatus({state, size = 'tiny'}: Props) {
    const [t] = useTranslation();

    let className = 'light';

    switch (state) {
        case TaskStateEnum.IN_PROGRESS:
            className = 'warning';
            break;
        case TaskStateEnum.ACCEPTED:
            className = 'success';
            break;
        case TaskStateEnum.OPEN:
            className = 'info';
            break;
        case TaskStateEnum.REJECTED:
            className = 'danger';
            break;
    }

    return (
        <Tag type={size} className={className} label={t(`TaskState.${state}`)}/>
    );
}

export default TaskStatus;
