import {Dispatch} from 'react';
import {AppThunk} from '../../common/reducer/reducers';
import {TaskFilterType} from '../../types/filter/TaskFilterType';
import {Task} from '../../types/Task';
import taskApi from '../api/taskApi';

export const emptyFilter: TaskFilterType = {page: 0, pageSize: 10};
export const SET_TASKS = 'SET_TASKS';
export const SET_TASKBOARD_FILTER = 'SET_TASKBOARD_FILTER';

interface SetTaskAction {
    type: typeof SET_TASKS;
    total: number;
    tasks: Task[];
    filter: TaskFilterType;
}

interface SetTaskBoardFilter {
    type: typeof SET_TASKBOARD_FILTER;
    filter: TaskFilterType;
}

export type TaskActions = SetTaskAction | SetTaskBoardFilter;

export const searchTasks = (filter: TaskFilterType): AppThunk => (dispatch: Dispatch<SetTaskAction>) => {
    taskApi.search(filter).then((response) => {
        dispatch({
            type: SET_TASKS,
            total: response.data.success ? response.data.total : 0,
            tasks: response.data.success ? response.data.result : [],
            filter
        });
    });
};

export const setTaskboardFilter = (filter: TaskFilterType): AppThunk => (dispatch: Dispatch<SetTaskBoardFilter>) => {
    dispatch({
        type: SET_TASKBOARD_FILTER,
        filter: filter
    });
};

export const resetTaskboard = (): AppThunk => (dispatch: Dispatch<SetTaskAction>) => {
    dispatch({
        type: SET_TASKS,
        total: 0,
        tasks: [],
        filter: emptyFilter
    });
};
