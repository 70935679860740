import {Dispatch} from 'redux';
import {AppThunk} from '../../common/reducer/reducers';

export const SET_DELIVERY_GROUP_FILTER = 'SET_DELIVERY_GROUP_FILTER';

interface SetDeliverGroupFilterAction {
    type: typeof SET_DELIVERY_GROUP_FILTER;
    filter: string;
}

export type DeliverGroupActions = SetDeliverGroupFilterAction

export const setDeliverGroupFilter = (filter: string): AppThunk =>
    (dispatch: Dispatch<DeliverGroupActions>) => {
        dispatch({
            type: SET_DELIVERY_GROUP_FILTER,
            filter
        });
    };
