import React from 'react';
import {useTranslation} from 'react-i18next';
import OrderOption from '../../common/enums/OrderOption';
import '../style/OrderTypeSwitch.scss';
import OrderTypeOption from './OrderTypeOption';

function OrderTypeSwitch({
                             selectedOption,
                             onSelectOption,
                             isInstitution = false,
                             catererId
                         }) {
    const [t] = useTranslation();
    return (
        <div className="row order-type-switch mb-4 mt-5">
            <OrderTypeOption
                orderOption={OrderOption.SUBSCRIPTION}
                isSelected={selectedOption === OrderOption.SUBSCRIPTION}
                onSelect={() => onSelectOption(OrderOption.SUBSCRIPTION)}
                isInstitution={isInstitution}
                catererId={catererId}
            />

            <div className="d-block d-md-none col-12 text-center my-3">
                <strong>{t('OrderOption.OR')}</strong>
            </div>

            <OrderTypeOption
                orderOption={OrderOption.SINGLE}
                isSelected={selectedOption === OrderOption.SINGLE}
                onSelect={() => onSelectOption(OrderOption.SINGLE)}
                isInstitution={isInstitution}
                catererId={catererId}
            />
        </div>
    );
}

export default OrderTypeSwitch;
