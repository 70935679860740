import {httpClient} from '../../common/api/HttpClient';

const paymentProviderIntegrationApi = {
    createPaymentIntent: (catererId, paymentIntent) => httpClient.post('payment-provider/payment-intents', paymentIntent, { params: { catererId }}),
    fetchSepaMandateForAccount: accountId => httpClient.get(`payment-provider/accounts/${accountId}/sepa-mandate`),
    fetchSepaMandate: setupIntentId => httpClient.get(`payment-provider/sepa-mandates/${setupIntentId}`),
    setupSepaDirectDebit: (catererId, accountId) => httpClient.post('payment-provider/sepa-setups', null,{ params: { catererId, accountId }}),
    revokeSepaMandate: accountId => httpClient.delete(`payment-provider/accounts/${accountId}/sepa-mandate`),
    calculateAmountIncludingFee: netAmount => httpClient.get('payment-provider/amount-including-fee', { params: { netAmount }, immediate: true }),
    prepareAccountForBankTransfer: accountId => httpClient.post(`payment-provider/bank-transfer-setups/${accountId}`),
    fetchBankTransferInstructions: accountId => httpClient.get(`payment-provider/bank-transfer-setups/${accountId}`)
};

export default paymentProviderIntegrationApi;
