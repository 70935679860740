import React from 'react';
import '../style/Tab.scss';

function Tabs({
                  tabs,
                  onSelectTab,
                  activeTab
              }) {
    return (
        <ul className="tab nav nav-pills nav-justified mt-5">
            {
                tabs && tabs.map((tab, index) =>
                    <li className={'nav-item'} key={tab.id}>
                        <a className={`nav-link ${activeTab.id === tab.id ? 'active' : ''} ${index === 0 ? 'first' : ''} ${index === tabs.length - 1 ? ' last' : ''}`}
                           onClick={(e) => {
                               e.preventDefault();
                               onSelectTab(tab);
                           }}
                           href="#">{tab.label}</a>
                    </li>
                )
            }
        </ul>
    );
}

export default Tabs;
