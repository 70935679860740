import {Dispatch} from 'react';
import {AppThunk} from '../reducer/reducers';

export const SET_DOMAIN_TYPE = 'SET_DOMAIN_TYPE';

interface SetDomainTypeAction {
    type: typeof SET_DOMAIN_TYPE;
    domainType: string | null;
}

export type DomainContextActions = SetDomainTypeAction;

export const setDomainType = (domainType: string | null): AppThunk => (dispatch: Dispatch<SetDomainTypeAction>) => {
    dispatch({
        type: SET_DOMAIN_TYPE,
        domainType
    });
};
