import React from 'react';

const UploadIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M18.7498 3.50962C19.164 3.50974 19.5 3.17405 19.5 2.75984C19.5 2.34563 19.1644 2.00974
                  18.7502 2.00962L5.75022 2.00586C5.336 2.00574 5 2.34143 5 2.75564C5 3.16986 5.33557 3.50574 5.74978
                  3.50586L18.7498 3.50962ZM12.1482 21.9978L12.25 22.0047C12.6297 22.0047 12.9435 21.7225 12.9932
                  21.3564L13 21.2547L12.999 7.56968L16.7208 11.29C16.9871 11.5562 17.4038 11.5804 17.6974 11.3625L17.7815
                  11.2899C18.0477 11.0236 18.0719 10.6069 17.854 10.3133L17.7814 10.2292L12.7837 5.23262C12.5176 4.96653
                  12.1012 4.94223 11.8076 5.15979L11.7235 5.23232L6.72003 10.2289C6.42694 10.5216 6.42661 10.9965 6.71931
                  11.2896C6.98539 11.556 7.40204 11.5805 7.6958 11.3629L7.77997 11.2903L11.499 7.57668L11.5 21.2547C11.5
                  21.6344 11.7822 21.9482 12.1482 21.9978Z"
            />
        </svg>

    );
};

export default UploadIcon;
