import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import isEmail from 'validator/es/lib/isEmail';
import accountApi from '../../../account/api/accountApi';
import '../../../account/style/AccountEdit.scss';
import catererApi from '../../../caterer/api/catererApi';
import {markChanges, navigate, unmarkChanges} from '../../../common/action/pageLeaveActions';
import Address from '../../../common/component/Address';
import ConfirmationDialog from '../../../common/component/form/ConfirmationDialog';
import Dropdown from '../../../common/component/form/Dropdown';
import FormButtons from '../../../common/component/form/FormButtons';
import LoginCredentials from '../../../common/component/form/LoginCredentials';
import Person from '../../../common/component/form/Person';
import PageLeaveGuard from '../../../common/component/PageLeaveGuard';
import TextOutput from '../../../common/component/TextOutput';
import ErrorCode from '../../../common/enums/ErrorCode';
import Permission from '../../../common/enums/Permission';
import Role from '../../../common/enums/Role';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import Icon from '../../../common/icons/Icon';
import {useHasPermission} from '../../../common/util/PermissionUtil';
import {logOut} from '../../../common/util/UserSessionUtils';
import {checkValidation} from '../../../common/util/ValidationUtil';
import {showError, showMessage} from '../../../message/action/messageActions';
import {updateAccount} from '../../../user/action/userActions';
import transactionApi from '../../api/transactionApi';
import {emptyAccount, emptyDropdown, initialValidationState} from '../../util/AccountEditUtil';
import AccountDeletionUserInputModal from '../parents/AccountDeletionUserInputModal';

function UserEdit() {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const isProfile = match.path === '/my-profile';
    const isNew = !isProfile && match.params.id === 'new';
    const currentUser = useSelector(state => state.user);
    // The account that we are building/editing here has a nested login property. (This is different from the structure of the current user.)
    const [account, setAccount] = useState(emptyAccount);
    const hasWritePermission = useHasPermission(Permission.WRITE_ACCOUNT);
    const readOnly = isProfile ? false : !hasWritePermission;
    const [isValidated, setIsValidated] = useState(false);
    const [validation, setValidation] = useState(initialValidationState);
    const languageOptions = useSelector(state => state.i18n.languages);
    const currentRole = useSelector(state => state.user.login.role);
    const [language, setLanguage] = useState(emptyDropdown);
    const [mailErrorCode, setMailErrorCode] = useState(null);
    const [deletionCheckResult, setDeletionCheckResult] = useState(null);
    const [balancePlusCancelableOrders, setBalancePlusCancelableOrders] = useState();

    const [role, setRole] = useState({
        label: t(`Role.${account.login.role}`),
        value: account.login.role
    });

    const [catererOptions, setCatererOptions] = useState([]);
    const [selectedCaterer, setSelectedCaterer] = useState(null);
    const hasChanges = useSelector(state => state.pageLeave.hasUnsavedChanges);

    const roleOptions = Object.values(Role).map(aRole => ({
        label: t(`Role.${aRole}`),
        value: aRole
    }));

    useEffect(() => {
        if (currentRole === Role.ADMINISTRATOR) {
            catererApi.fetchActiveCaterers().then(response => {
                if (response.data.success) {
                    const options = response.data.result.map(caterer => {
                        return {
                            label: caterer.companyName,
                            value: caterer.id
                        };
                    });
                    setCatererOptions(options);
                } else {
                    dispatch(showError('User.FAILED_TO_LOAD_CATERERS'));
                }
            });
        }
    }, []);

    useEffect(() => {
        handleFetchAccount();
    }, []);

    useEffect(() => {
        if (currentRole === Role.PARENT) {
            transactionApi.findBalancePlusCancelableOrders().then(res => {
                if (res.data.success) {
                    setBalancePlusCancelableOrders(res.data.result);
                }
            });
        }
    }, []);

    useEffect(() => {
        setLanguage(account.language ? languageOptions.find(language => language.value === account.language) : emptyDropdown);
    }, [account.language]);

    function handleFetchAccount() {
        if (isProfile) {
            // user.account is only present for Account-based users. The assumption here is, that an Account is always present for "my-profile" requests.
            const accountId = currentUser.account.id;
            accountApi.findById(accountId).then((res) => handleSetAccount(res));
        } else if (!isNew) {
            accountApi.findById(match.params.id).then((res) => handleSetAccount(res));
        }
    }

    const handleSetAccount = (res, saved = false) => {
        if (res.data.success) {
            const fetchedAccount = res.data.result;
            setRole({
                label: t(`Role.${fetchedAccount.role}`),
                value: fetchedAccount.login.role
            });
            setAccount(fetchedAccount);
            if (saved) {
                dispatch(showMessage('User.SAVE_SUCCESS'));
                if (isProfile) {
                    dispatch(updateAccount(fetchedAccount));
                } else if (isNew) {
                    dispatch(navigate('/users'));
                }
            }
        }
    };

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [account]);

    function handleRoleChange(newRole) {
        setRole({
            label: t(`Role.${newRole.value}`),
            value: newRole.value
        });
        handleLoginChange('role', newRole.value);
    }

    function handleLanguageChange(newLanguage) {
        setLanguage(newLanguage);
        handleAccountChange('language', newLanguage.value);
    }

    function handleCatererChange(catererOption) {
        setSelectedCaterer(catererOption);
        handleAccountChange('catererId', catererOption.value);
    }

    function handleLoginChange(field, value) {
        dispatch(markChanges());
        if (field === 'emailAddress') {
            setMailErrorCode(null);
        }
        setAccount({
            ...account,
            login: {
                ...account.login,
                [field]: value
            }
        });
    }

    function handleAccountChange(field, value) {
        dispatch(markChanges());
        setAccount({
            ...account,
            [field]: value
        });
    }

    function saveUser() {
        if (validate()) {
            accountApi.createOrUpdate(account).then((res) => {
                if (res.data.success) {
                    handleSetAccount(res, true);
                    dispatch(unmarkChanges());
                } else {
                    setMailErrorCode(res.errorCode);
                }
            });
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
        }
    }

    function handleClickDelete() {
        accountApi.checkDeletable(account.id).then(res => {
            if (res.data.success) {
                setDeletionCheckResult(res.data.result);
            } else {
                dispatch(showError('Error.MISSING_GENERAL'));
            }
        }).catch(error => {
            dispatch(showError('Error.GENERAL', error));
        });
    }

    function handleConfirmDeleteAccount() {
        setDeletionCheckResult(null)
        accountApi.purge(account.id).then(res => {
            if (res.data.success) {
                logoutGoodbye();
            } else {
                dispatch(showError('Error.GENERAL'));
            }
        }).catch(error => {
            dispatch(showError('Error.GENERAL', error));
        });
    }

    function handleConfirmDeletionPreparation(withdrawalRequest) {
        setDeletionCheckResult(null)
        accountApi.prepareDeletion(account.id, withdrawalRequest).then(res => {
            if (res.data.success) {
                logoutGoodbye();
            } else {
                dispatch(showError('Error.GENERAL'));
            }
        }).catch(error => {
            dispatch(showError('Error.GENERAL', error));
        });
    }

    function logoutGoodbye() {
        logOut(dispatch, () => history.push('/goodbye'));
    }

    function validate() {
        const newValidation = {
            login: {
                emailAddress: account.login.emailAddress && isEmail(account.login.emailAddress),
                password: !account.login.passwordRepeat || account.login.password,
                passwordRepeat: !account.login.password || (account.login.password === account.login.passwordRepeat)
            },
            person: {
                firstName: !!account.person.firstName,
                lastName: !!account.person.lastName,
                salutation: !!account.person.salutation
            },
            address: {
                street: !!account.address.street,
                houseNumber: !!account.address.houseNumber,
                zip: !!account.address.zip,
                city: !!account.address.city
            },
            language: isNew ? !!account.language : true,
            catererId: account.login.role !== Role.CATERER || !!account.catererId
        };
        setIsValidated(true);
        setValidation(newValidation);

        return checkValidation(newValidation);
    }

    const goBack = () => {
        dispatch(unmarkChanges());
        if (!isProfile) {
            dispatch(navigate('/users'));
        } else {
            dispatch(navigate('/'));
        }
    };

    let header = t('User.HEADER_EDIT');
    if (isProfile) {
        header = t('User.YOUR_PROFILE');
    } else if (isNew) {
        header = t('User.HEADER_NEW');
    }

    const chooseConfirmationDialogBodyText = useCallback(() => {
        if (!deletionCheckResult) {
            return '';
        }

        if (deletionCheckResult.hasPendingWithdrawal) {
            return t('User.DELETION.WITHDRAWAL_SUFFICIENT');
        } else {
            return t('User.DELETION.NO_LEFTOVER_CREDIT');
        }
    }, [deletionCheckResult, t]);

    return (
        <div className="container">
            <h1>
                {header}
            </h1>
            <div className="account-type account-type-small account-type-parent"/>
            {
                !isProfile &&
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <Dropdown
                                    label={t('User.ROLE')}
                                    options={roleOptions}
                                    value={role}
                                    onChange={value => handleRoleChange(value)}
                                    required={true}
                                    isDisabled={!isNew}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <Dropdown
                                    label={t('User.LANGUAGE')}
                                    options={languageOptions}
                                    value={language}
                                    onChange={value => handleLanguageChange(value)}
                                    required={true}
                                    isValid={validation.language}
                                    isDisabled={!isNew}
                                />
                            </div>
                        </div>
                        <div className={account.login.role === Role.CATERER ? 'row' : 'd-none'}>
                            <div className="col-12 col-lg-6">
                                <Dropdown
                                    label={t('User.CATERER')}
                                    options={catererOptions}
                                    value={selectedCaterer}
                                    onChange={handleCatererChange}
                                    required={true}
                                    isValid={validation.catererId}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="card mb-3">
                <div className="card-body">
                    {
                        account.customerNumber &&
                        <div className="text-right account-customer-number">
                            <TextOutput
                                label="Kundennummer"
                                text={account.customerNumber}
                            />
                        </div>
                    }
                    <Person
                        person={account.person}
                        onChange={(value) => handleAccountChange('person', value)}
                        validation={validation.person}
                        readOnly={!isProfile && readOnly}
                    />
                    {
                        isProfile && !isNaN(balancePlusCancelableOrders) &&
                        <div className="float-right" data-for="tooltip_delete"
                             data-tip={balancePlusCancelableOrders < 0 ? t('User.DELETION.CANNOT_DELETE_WITH_NEGATIVE_BALANCE') : ''}>
                            <button
                                className="btn btn-secondary"
                                onClick={handleClickDelete}
                                disabled={balancePlusCancelableOrders < 0}
                            >
                                <Icon src={<DeleteIcon/>} className="mr-2"/>
                                {t('User.DELETION.DELETE_BUTTON')}
                            </button>
                            <div className="delete-button-tooltip">
                                <ReactTooltip id={'tooltip_delete'} type="light" multiline={true}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-header">
                    {t('User.ADDRESS')}
                </div>
                <div className="card-body">
                    <Address
                        address={account.address}
                        handleChange={(value) => handleAccountChange('address', value)}
                        validation={validation.address}
                        readOnly={!isProfile && readOnly}/>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    {t('User.CREDENTIALS')}
                </div>
                <div className="card-body">
                    <LoginCredentials
                        login={account.login}
                        onChange={handleLoginChange}
                        validation={validation.login}
                        readOnly={!isProfile && readOnly}
                        emailReadOnly={!isNew}
                        showPassword={!isNew}
                        isPasswordRequired={isNew}
                        mailErrorCode={mailErrorCode === ErrorCode.LOGIN_ALREADY_EXISTS.code ? mailErrorCode : null}
                    />
                </div>
            </div>
            <FormButtons sticky={false} className="mt-4" onSave={saveUser} onCancel={goBack}/>

            {/* No further user input needed: Delete right away or mark for deletion: */}
            {
                deletionCheckResult &&
                <ConfirmationDialog
                    open={deletionCheckResult && !deletionCheckResult.needsWithdrawal}
                    title={t('User.DELETION.CONFIRMATION_HEADER')}
                    body={chooseConfirmationDialogBodyText()}
                    confirmLabel={t('Button.DELETE')}
                    onConfirm={() => {
                        if (deletionCheckResult.hasPendingWithdrawal) {
                            handleConfirmDeletionPreparation({});
                        } else {
                            handleConfirmDeleteAccount();
                        }
                    }}
                    onCancel={() => setDeletionCheckResult(null)}
                />
            }

            {/* Let the user choose a Stripe payment method or enter bank account details: */}
            {
                deletionCheckResult &&
                <AccountDeletionUserInputModal
                    visible={deletionCheckResult.needsWithdrawal}
                    deletionCheckResult={deletionCheckResult}
                    withdrawalAmount={balancePlusCancelableOrders}
                    onConfirm={deletionCheckResult.stripeRefundPaymentMethods.length > 0 && !deletionCheckResult.hasPendingWithdrawal ?
                        handleConfirmDeleteAccount : handleConfirmDeletionPreparation}
                    onCancel={() => setDeletionCheckResult(null)}
                />
            }
            <PageLeaveGuard hasChanges={hasChanges}/>
        </div>
    );
}

export default UserEdit;
