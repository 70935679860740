import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FilePickerDropzone from '../../common/component/FilePickerDropzone';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import FoodRestrictionState from '../../common/enums/FoodRestrictionState';
import {UPLOAD_IMAGE_TYPES_ALL} from '../../common/GlobalConstants';
import CheckIcon from '../../common/icons/CheckIcon';
import ClockIcon from '../../common/icons/ClockIcon';
import Icon from '../../common/icons/Icon';
import InfoIcon from '../../common/icons/InfoIcon';
import {getFileExtension} from '../../common/util/FileUtil';
import '../style/FoodRestriction.scss';

function FoodRestriction({
                             foodRestriction,
                             handleRestrictionChange,
                             handleRestrictionDelete,
                             handleValidationChange,
                             showErrors,
                             hasRestriction,
                             nutritionClass
                         }) {
    const [t] = useTranslation();
    const [state, setState] = useState(FoodRestrictionState.UNSELECTED);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showClarification, setShowClarification] = useState(false);
    const [valid, setValid] = useState(true);

    useEffect(() => {
        if (!foodRestriction) {
            setState(FoodRestrictionState.UNSELECTED);
        } else if (foodRestriction.id && foodRestriction.declined) {
            setState(FoodRestrictionState.SELECTED_DECLINED);
        } else if (foodRestriction.verified) {
            if (!nutritionClass.isAttestRequired) {
                setState(FoodRestrictionState.SELECTED_ACCEPTED);
            } else {
                setState(FoodRestrictionState.SELECTED_APPROVED);
            }
        } else if (foodRestriction.id && !foodRestriction.verified && !foodRestriction.declined) {
            setState(FoodRestrictionState.SELECTED_NO_VERIFICATION);
        } else if (!foodRestriction.file && nutritionClass.isAttestRequired) {
            setState(FoodRestrictionState.SELECTED_NO_UPLOAD);
        } else if (foodRestriction.file) {
            setState(FoodRestrictionState.SELECTED_UPLOADED);
        }
    }, [foodRestriction]);

    useEffect(() => {
        validate();
    }, [state]);

    function validate() {
        const foodRestrictionForValidation = {...foodRestriction};
        if (state === FoodRestrictionState.SELECTED_NO_UPLOAD) {
            handleValidationChange({
                nutritionClassId: nutritionClass.id,
                verified: foodRestrictionForValidation.verified,
                valid: !!foodRestrictionForValidation.verified
            });
            setValid(false);
        } else if (state === FoodRestrictionState.UNSELECTED) {
            handleValidationChange({
                nutritionClassId: nutritionClass.id,
                verified: true,
                valid: true
            });
        } else {
            if (!valid) {
                handleValidationChange({
                    nutritionClassId: nutritionClass.id,
                    verified: foodRestrictionForValidation.verified,
                    valid: true
                });
                setValid(true);
            }
        }
    }

    function toggleRestriction() {
        switch (state) {
            case FoodRestrictionState.UNSELECTED: {
                if (hasRestriction) {
                    setShowClarification(true);
                } else {
                    handleRestrictionChange({
                        allergy: nutritionClass.name,
                        verified: !nutritionClass.isAttestRequired,
                        declined: false,
                        nutritionClassId: nutritionClass.id,
                        nutritionClass: nutritionClass,
                        isAttestRequired: nutritionClass.isAttestRequired
                    });
                }
            }
                break;
            case FoodRestrictionState.SELECTED_NO_UPLOAD:
            case FoodRestrictionState.SELECTED_ACCEPTED: {
                handleRestrictionDelete(foodRestriction);
                setState(FoodRestrictionState.UNSELECTED);
            }
                break;
            default: {
                setShowDeleteDialog(true);
            }
        }
    }

    function handleImage(file) {
        const newRestriction = {
            ...foodRestriction,
            file: file
        };
        handleRestrictionChange(newRestriction);
    }

    const selected = state > FoodRestrictionState.UNSELECTED;
    const preUpload = state === FoodRestrictionState.SELECTED_NO_UPLOAD;
    const afterUpload = state > FoodRestrictionState.SELECTED_NO_UPLOAD;

    const logoExtension = getFileExtension(nutritionClass.logo);
    const selectedClass = !valid && showErrors ? 'invalid' : 'selected';
    const whiteText = selected ? 'text-white' : '';
    return (
        <>
            <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div
                    onClick={() => afterUpload && toggleRestriction()}
                    className={`food-restriction p-1 p-sm-3 ${selected ? selectedClass : ''}${preUpload ? ' pre-upload' : ''}`}>
                    {
                        (state === FoodRestrictionState.SELECTED_UPLOADED || state === FoodRestrictionState.SELECTED_NO_VERIFICATION) &&
                        <Icon src={<ClockIcon/>}
                              className="float-right text-warning icon-size-md"
                        />
                    }
                    {
                        (state === FoodRestrictionState.SELECTED_APPROVED || state === FoodRestrictionState.SELECTED_ACCEPTED) &&
                        <Icon src={<CheckIcon/>}
                              className="float-right text-success icon-size-md"
                        />
                    }
                    {
                        (state === FoodRestrictionState.SELECTED_DECLINED) &&
                        <Icon src={<InfoIcon/>}
                              className="float-right text-danger icon-size-md"
                        />
                    }
                    <div className="align-items-center row no-gutters">
                        <div className="col-6 col-sm-3 col-md-6 col-xl-5">
                            <div
                                className={`btn px-0 d-flex flex-column text-center ${(selected ? 'btn-primary' : 'btn-outline-primary')}`}
                                onClick={toggleRestriction}>
                                {
                                    nutritionClass.logo ?
                                        <>
                                            <img className={'my-0'} alt="" aria-hidden={true} src={`data:image/${logoExtension};base64,${nutritionClass.logo}`}/>
                                            <div className={`spelling my-auto mx-auto small ${whiteText}`}>
                                                {nutritionClass.name}
                                            </div>
                                        </>
                                        :
                                        <div className={`p-1 spelling my-auto mx-auto ${whiteText}`}>
                                            {nutritionClass.name}
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="col-6 col-sm-9 col-md-6 col-xl-7 text-center text-sm-left text-lg-center">
                            {
                                state === FoodRestrictionState.UNSELECTED &&
                                nutritionClass.isAttestRequired &&
                                <div className="text-muted">
                                    {t('FoodRestriction.NEED_ATTEST')}
                                </div>
                            }
                            {
                                state === FoodRestrictionState.SELECTED_NO_UPLOAD &&
                                <FilePickerDropzone label={t('FoodRestriction.UPLOAD_HERE')}
                                                    allowedFileTypes={UPLOAD_IMAGE_TYPES_ALL}
                                                    handleChange={handleImage}
                                                    showError={showErrors}
                                                    errorLabel={t('FoodRestriction.ERROR')}/>
                            }
                            {
                                state === FoodRestrictionState.SELECTED_UPLOADED &&
                                t('FoodRestriction.INIT_NEEDS_CHECK')
                            }
                            {
                                state === FoodRestrictionState.SELECTED_NO_VERIFICATION &&
                                t('FoodRestriction.NEEDS_CHECK')
                            }
                            {
                                state === FoodRestrictionState.SELECTED_APPROVED &&
                                t('FoodRestriction.VERIFICATION_SUCCESS')
                            }
                            {
                                state === FoodRestrictionState.SELECTED_ACCEPTED &&
                                t('FoodRestriction.SELECTION_ACCEPTED')
                            }
                            {
                                state === FoodRestrictionState.SELECTED_DECLINED &&
                                t('FoodRestriction.SELECTION_DECLINED')
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                foodRestriction &&
                <ConfirmationDialog
                    open={showDeleteDialog}
                    title={t('FoodRestriction.DELETE_CONFIRMATION_TITLE')}
                    body={t('FoodRestriction.DELETE_DESCRIPTION', {allergy: nutritionClass.name})}
                    confirmLabel={t('Button.DELETE')}
                    cancelLabel={t('Button.CANCEL')}
                    onConfirm={() => {
                        handleRestrictionDelete(foodRestriction);
                        setShowDeleteDialog(false);
                    }}
                    onCancel={() => setShowDeleteDialog(false)}
                />
            }
            <ConfirmationDialog
                open={showClarification}
                title={t('FoodRestriction.CLARIFICATION_CONFIRMATION_TITLE')}
                body={t('FoodRestriction.CLARIFICATION_DESCRIPTION')}
                confirmLabel={t('Button.OK')}
                onConfirm={() => {
                    setShowClarification(false);
                }}
            />
        </>
    );
}

export default FoodRestriction;
