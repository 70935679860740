import {httpClient} from '../../common/api/HttpClient';

export const mealPlanManagementApi = {
    fetchNutritionClassesForCaterer: (catererId) => httpClient.get('meal-plans/nutrition-classes', {params: {catererId}}),
    fetchNutritionClassById: (nutritionClassId) => httpClient.get(`meal-plans/nutrition-classes/${nutritionClassId}`),
    createOrUpdateNutritionClass: (nutritionClass) => httpClient.post('meal-plans/nutrition-classes', nutritionClass),
    deleteNutritionClass: (nutritionClassId) => httpClient.delete(`meal-plans/nutrition-classes/${nutritionClassId}`),
    fetchMenuGroupsForChild: (childId) => httpClient.get('meal-plans/menu-groups/child', {params: {childId}}),
    fetchMenuGroupsForInstitution: (institutionId, includeLogos, filterByHasNutritionClass, filterForDeactivationDate) => httpClient.get('meal-plans/menu-groups/institution', {
        params: {
            institutionId,
            includeLogos,
            filterByHasNutritionClass,
            filterForDeactivationDate
        }
    }),
    fetchMenuGroups: (catererId) => httpClient.get('meal-plans/menu-groups', {params: {catererId}}),
    fetchMenuGroupsApproved: (catererId) => httpClient.get('meal-plans/menu-groups/approved', {params: {catererId}}),
    fetchMenuGroupsSimple: (catererId) => httpClient.get('meal-plans/menu-groups/simple', {params: {catererId}}),
    createOrUpdateMenuGroup: (menuGroup) => httpClient.post('meal-plans/menu-groups', menuGroup),
    deleteMenuGroup: (menuGroupId) => httpClient.delete(`meal-plans/menu-groups/${menuGroupId}`),
    fetchServingSizes: (catererId) => httpClient.get('meal-plans/serving-sizes', {params: {catererId}}),
    fetchApprovedServingSizes: (catererId) => httpClient.get('meal-plans/serving-sizes/approved', {params: {catererId}}),
    fetchServingSizesForInstitution: (institutionId) => httpClient.get('meal-plans/serving-sizes/institution', {params: {institutionId}}),
    createOrUpdateServingSize: (servingSize) => httpClient.post(`meal-plans/serving-sizes`, servingSize),
    deleteServingSize: (servingSizeId) => httpClient.delete(`meal-plans/serving-sizes/${servingSizeId}`),

    fetchCurrentMenuPrices: (catererId) => httpClient.get(`menu-prices/${catererId}/current`),
    addToCurrentMenuPrices: (catererId, prices) => httpClient.post(`menu-prices/${catererId}/current`, prices),
    fetchMenuPriceUpdates: (catererId) => httpClient.get(`menu-prices/${catererId}/updates`),
    updateMenuPriceUpdates: (catererId, priceUpdates) => httpClient.post(`menu-prices/${catererId}/updates`, priceUpdates),
    deletePriceUpdates: (catererId) => httpClient.delete(`menu-prices/${catererId}/updates`),

    createOrUpdateInstitutionPriceUpdates: (institutionId, priceUpdates) => httpClient.post(`menu-prices/institution/${institutionId}`, priceUpdates),
    deleteInstitutionPriceUpdates: (institutionId) => httpClient.delete(`menu-prices/institution/${institutionId}`),
    resetInstitutionPrices: (institutionId, resetAt) => httpClient.post(`menu-prices/institution/${institutionId}/reset`, null, {params: {resetAt}}),
    removeResetInstitutionPrices: (institutionId) => httpClient.delete(`menu-prices/institution/${institutionId}/reset`),
    fetchInstitutionPrices: (institutionId) => httpClient.get(`menu-prices/institution/${institutionId}/current`),
    fetchFutureInstitutionPrices: (institutionId) => httpClient.get(`menu-prices/institution/${institutionId}/future`),
    downloadMenuPrice: (catererId) => httpClient.get(`menu-prices/${catererId}/download`, {responseType: 'blob'})
};

export default mealPlanManagementApi;
