import React from 'react';

const MinusIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M3.75391 12.9H20.2458C20.66 12.9 20.9958 12.5642 20.9958 12.15C20.9958 11.7358 20.66 11.4
                  20.2458 11.4H3.75391C3.33969 11.4 3.00391 11.7358 3.00391 12.15C3.00391 12.5642 3.33969
                  12.9 3.75391 12.9Z"/>
        </svg>

    );
};

export default MinusIcon;
