import {useTranslation} from 'react-i18next';
import React from 'react';
import {Link} from 'react-router-dom';

function OnboardingInfo() {
    const [t] = useTranslation();

    return (
        <div className="container">
            <h1>{t('Onboarding.WELCOME')}</h1>
            <div className="card bg-primary">
                <div className="card-body">
                    <div className="zack zack-spoon float-right"/>
                    <div className="clearfix">{t('Onboarding.INFO')}</div>
                    <div className="button-footer">
                        <Link to="/onboarding" className="btn btn-primary">{t('Onboarding.LETS_GO')}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnboardingInfo;
