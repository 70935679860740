import {dateDifference} from '../../common/util/DateUtil';
import {ChildWelfare} from '../../types/child/ChildWelfare';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';

export function isWelfareActiveForDate(childWelfareArray: Array<ChildWelfare>, checkDate: Date | string): boolean {
    return childWelfareArray.some(welfare =>
        welfare.verified && !!welfare.validTo && dateDifference(new Date(welfare.validTo), new Date(checkDate)) >= 0
        && dateDifference(new Date(welfare.validFrom), new Date(checkDate)) <= 0);
}

export function isWelfareInProgressForDate(childWelfareArray: Array<ChildWelfare>, checkDate: Date | string): boolean {
    return childWelfareArray.some(welfare =>
        welfare.task?.taskState === TaskStateEnum.IN_PROGRESS
        && !!welfare.validTo && dateDifference(new Date(welfare.validTo), new Date(checkDate)) >= 0
        && dateDifference(new Date(welfare.validFrom), new Date(checkDate)) <= 0);
}
