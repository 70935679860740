import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {combineBusinessDays} from '../../common/util/CatererBusinessDaysUtil';
import {CatererNews} from '../../types/caterer/CatererNewsType';
import {
    CatererActions,
    SET_CATERER_BASICS,
    SET_CATERER_ICON,
    SET_CATERER_IMPRINT,
    SET_CATERER_LOGO,
    SET_CATERER_NEWS,
    SET_CATERER_PRIVACY,
    SET_CATERER_TERMS_CONDITION,
    SET_CATERER_TIME_MANAGEMENT
} from '../action/catererActions';
import {CatererTimeManagementData} from '../types/CatererTimeManagementData';

export type CatererStateType = {
    catererId?: number,
    logo?: any,
    catererName?: string,
    displayName?: string,
    companyShortName?: string,
    menuLimit?: number,
    news?: CatererNews
    imprint?: string,
    privacy?: string,
    termsCondition?: string,
    icon?: any,
    timeManagementChild?: CatererTimeManagementData,
    timeManagementGroup?: CatererTimeManagementData
};

const initialState: CatererStateType = {
    catererId: undefined,
    logo: undefined,
    catererName: undefined,
    displayName: undefined,
    companyShortName: undefined,
    menuLimit: 1,
    news: undefined,
    imprint: undefined,
    privacy: undefined,
    termsCondition: undefined,
    timeManagementChild: undefined,
    timeManagementGroup: undefined
};

const catererReducer: Reducer<CatererStateType, CatererActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case SET_CATERER_BASICS: {
            if (action.catererId) {
                return {
                    ...state,
                    catererId: action.catererId,
                    companyName: action.companyName,
                    displayName: action.displayName,
                    companyShortName: action.companyShortName,
                    menuLimit: action.menuLimit
                };
            }
        }
            break;
        case SET_CATERER_IMPRINT: {
            return {
                ...state,
                imprint: action.imprint
            };
        }
        case SET_CATERER_PRIVACY: {
            return {
                ...state,
                privacy: action.privacy
            };
        }
        case SET_CATERER_TERMS_CONDITION: {
            return {
                ...state,
                termsCondition: action.termsCondition
            };
        }
        case SET_CATERER_LOGO: {
            return {
                ...state,
                logo: action.logo
            };
        }
        case SET_CATERER_ICON: {
            return {
                ...state,
                icon: action.icon
            };
        }
        case SET_CATERER_NEWS: {
            return {
                ...state,
                news: action.news
            };
        }
        case SET_CATERER_TIME_MANAGEMENT: {
            return {
                ...state,
                timeManagementChild: action.timeManagementChild,
                timeManagementGroup: action.timeManagementGroup,
                combinedBusinessDays: combineBusinessDays(action.timeManagementChild.businessDays, action.timeManagementGroup.businessDays)
            };
        }
    }
    return state;
};

export default catererReducer;
