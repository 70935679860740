import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ChildAdd from '../../child/component/ChildAdd';
import {setOnboarding} from '../action/onboardingActions';

function Onboarding() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setOnboarding(true));
    }, [dispatch]);

    return (
        <ChildAdd/>
    );
}

export default Onboarding;
