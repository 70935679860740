import React from 'react';

const BurgerIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.98926 6.25717C2.98926 5.85676 3.31385 5.53217 3.71426 5.53217H20.2857C20.6861
                  5.53217 21.0107 5.85676 21.0107 6.25717C21.0107 6.65757 20.6861 6.98217 20.2857
                  6.98217H3.71426C3.31385 6.98217 2.98926 6.65757 2.98926 6.25717ZM2.98926
                  12.4C2.98926 11.9996 3.31385 11.675 3.71426 11.675H20.2857C20.6861 11.675
                  21.0107 11.9996 21.0107 12.4C21.0107 12.8004 20.6861 13.125 20.2857 13.125H3.71426C3.31385
                  13.125 2.98926 12.8004 2.98926 12.4ZM3.71426 17.8179C3.31385 17.8179 2.98926 18.1425 2.98926
                  18.5429C2.98926 18.9433 3.31385 19.2679 3.71426 19.2679H20.2857C20.6861 19.2679 21.0107
                  18.9433 21.0107 18.5429C21.0107 18.1425 20.6861 17.8179 20.2857 17.8179H3.71426Z"
            />
        </svg>

    );
};

export default BurgerIcon;
