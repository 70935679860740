import {applyMiddleware, createStore} from 'redux';

import {createLogger} from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import rootReducer from './common/reducer/reducers';

const logger = createLogger({
    predicate: () => false // set to true for logging
});

const middleware = applyMiddleware(promise(), thunk, logger);

export var store = createStore(rootReducer, middleware);
