import React from 'react';
import {useTranslation} from 'react-i18next';
import '../../style/Menu.scss';

function Menu({
                  meals,
                  showAllergens = false
              }) {
    const [t] = useTranslation();

    if (!meals) {
        return null;
    }

    let allergens = [];
    if (meals) {
        const allergenLists = meals.map(meal => meal.allergens);
        allergenLists.forEach(allergenList => allergens = allergens.concat(allergenList));
    }

    return (
        <div className="food-courses">
            {
                meals.map((meal, index) =>
                    <div key={index} className="food-course">
                        {meal.name}
                    </div>
                )
            }
            {
                showAllergens && allergens.length > 0 &&
                <>
                    <div className="font-weight-bold mt-2">
                        {t('Meal.ALLERGENS')}
                    </div>
                    {
                        allergens.map((allergen, index) =>
                            <div key={index} className="food-course">
                                {allergen.name}
                            </div>
                        )
                    }
                </>
            }
        </div>
    );
}

export default Menu;
