import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Diet from '../../common/component/Diet';
import CheckIcon from '../../common/icons/CheckIcon';
import EditIcon from '../../common/icons/EditIcon';
import Icon from '../../common/icons/Icon';
import InfoIcon from '../../common/icons/InfoIcon';
import {compareDate, dateDifference, getTwoMonthsBefore} from '../../common/util/DateUtil';
import {sortDates} from '../../common/util/SortUtil';
import ChildSubscriptionView from '../../order/component/childOrder/ChildSubscriptionView';
import * as ChildWelfareUtils from '../util/ChildWelfareUtils';

function Child({child}) {
    const [t] = useTranslation();
    const history = useHistory();
    const hasVerifiedWelfare = child?.childWelfare && ChildWelfareUtils.hasVerifiedWelfare(child.childWelfare);
    let verifiedWelfareListInPast = [];
    if (child && child.childWelfare) {
        verifiedWelfareListInPast = child.childWelfare
            .filter(welfare => !!welfare.verified && dateDifference(new Date(welfare.validTo), new Date()) < 0)
            .sort((a, b) => sortDates(b.validTo, a.validTo));
    }
    const hasExpiredWelfareWithinTwoMonths = verifiedWelfareListInPast.length > 0
        && compareDate(new Date(verifiedWelfareListInPast[0].validTo), getTwoMonthsBefore());

    function edit() {
        history.push(`/children/${child.id}`);
    }

    const foodRestrictions = [
        ...child.allergies
            .filter(allergy => allergy.verified)
            .map(allergy => allergy.nutritionClass)
    ];

    return (
        <div className="card h-100">
            <div className="card-header">
                <Icon src={<EditIcon/>} className="float-right icon-primary small" onClick={edit} title={t('Button.EDIT')}/>
                {child.firstName} {child.lastName}</div>
            <div className="card-body px-3">
                <Diet selectedItems={foodRestrictions}/>
                <div className="mt-3">
                    <ChildSubscriptionView child={child}/>
                </div>
                {
                    child.group &&
                    <div className="mt-3 text-secondary">
                        {child.group.name}
                        <div className="small">{child.group.institutionName}</div>
                    </div>
                }
            </div>
            {
                hasVerifiedWelfare &&
                <div className="card-footer text-success">
                    <Icon src={<CheckIcon/>} className="mr-3"/> {t('Child.BUT')}
                </div>
            }

            {
                !hasVerifiedWelfare && hasExpiredWelfareWithinTwoMonths &&
                <div className="card-footer text-danger">
                    <Icon src={<InfoIcon/>}
                          className="mr-3"/>{t('Child.BUT_EXPIRED')}
                </div>
            }
        </div>
    );
}

export default Child;
