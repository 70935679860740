import {Reducer} from 'redux';

type OnboardingReducerType = {
    isOnboarding: boolean
}

const initialState: OnboardingReducerType = {
    isOnboarding: false
};

const onboardingReducer: Reducer<OnboardingReducerType, { type: string, payload: boolean }> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ONBOARDING':
            return {
                ...initialState,
                isOnboarding: action.payload
            };
        default:
            return state;
    }
};

export default onboardingReducer;
