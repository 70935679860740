import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import EditModal from '../../common/component/EditModal';
import DateInput from '../../common/component/form/DateInput';
import TextInput from '../../common/component/form/TextInput';
import WelfareDepartmentType from '../../common/enums/WelfareDepartmentType';
import {checkValidation} from '../../common/util/ValidationUtil';
import taskApi from '../../taskboard/api/taskApi';
import '../style/Welfare.scss';
import DepartmentSelection from './welfare/DepartmentSelection';
import WelfareUploader from './welfare/WelfareUploader';

function WelfareEditModal({
                              visible,
                              welfare,
                              onConfirm,
                              confirmLabel,
                              onCancel
                          }) {
    const [welfareToEdit, setWelfareToEdit] = useState({
        ...welfare,
        welfareDepartment: welfare?.welfareDepartment || WelfareDepartmentType.LANDRATSAMT
    });
    const [validation, setValidation] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [t] = useTranslation();

    useEffect(() => {
        if (welfare) {
            setWelfareToEdit(welfare);
            if (welfare.task?.id) {
                taskApi.findTaskDocumentsById(welfare.task.id).then((res) => {
                    if (res.data.success) {
                        setDocuments(res.data.result);
                    }
                });
            }
        }
    }, [welfare]);

    function validate() {
        const newValidation = {};
        newValidation.welfareDepartment = !!welfareToEdit.welfareDepartment;

        switch (welfareToEdit.welfareDepartment) {
            case WelfareDepartmentType.JOBCENTER:
                newValidation.bgNumber = !!welfareToEdit.bgNumber;
            //intentional fall-through
            case WelfareDepartmentType.SOZIALAMT:
            case WelfareDepartmentType.YOUTH_AND_FAMILY:
                newValidation.validTo = !!welfareToEdit.validTo;
                newValidation.file = !!welfareToEdit.file || documents.length > 0;
            //intentional fall-through
            case WelfareDepartmentType.LANDRATSAMT:
                newValidation.birthDate = !!welfareToEdit.birthDate;
                newValidation.welfareDepartment = !!welfareToEdit.welfareDepartment;
        }

        setValidation(newValidation);
        setShowErrors(true);

        return checkValidation(newValidation);
    }

    function handleChange(field, value) {
        if (field === 'file') {
            value = value.target.files[0];
        }
        if ((field === 'birthDate' || field === 'validTo') && value) {
            value.setHours(12);
            value.setMinutes(0);
            value.setSeconds(0);
        }
        const newWelfare = {
            ...welfareToEdit,
            [field]: value
        };

        setWelfareToEdit(newWelfare);

        if (field === 'welfareDepartment') {
            setShowErrors(false);
        }
    }

    function confirm() {
        if (validate()) {
            welfareToEdit.modified = true;
            onConfirm(welfareToEdit);
        }
    }

    function getDescription() {
        if (!welfareToEdit || !welfareToEdit.welfareDepartment) {
            return '';
        }
        if (welfareToEdit.welfareDepartment !== WelfareDepartmentType.LANDRATSAMT) {
            let verificationFile;
            switch (welfareToEdit.welfareDepartment) {
                case WelfareDepartmentType.SOZIALAMT:
                    verificationFile = t('Welfare.SOZIALAMT_DOCUMENT');
                    break;
                case WelfareDepartmentType.YOUTH_AND_FAMILY:
                    verificationFile = t('Welfare.YOUTH_AND_FAMILY_DOCUMENT_DOCUMENT');
                    break;
                case  WelfareDepartmentType.JOBCENTER:
                    verificationFile = t('Welfare.JOBCENTER_DOCUMENT');
                    break;
            }
            return t('Welfare.NEED_UPLOAD_DESCRIPTION', {verificationType: verificationFile});
        } else {
            return t('Welfare.UPLOAD_CATERER_DESCRIPTION');
        }
    }

    const isLandratsamt = welfareToEdit.welfareDepartment === WelfareDepartmentType.LANDRATSAMT;
    const isJobcenter = welfareToEdit.welfareDepartment === WelfareDepartmentType.JOBCENTER;

    function getHeaderText() {
        const header = t('Welfare.EDIT_APPLICATION');
        if (welfare && welfare.childName) {
            return `${header} (${welfare.childName})`;
        }
        return header;
    }

    return (
        <EditModal visible={visible} header={getHeaderText()} onCancel={onCancel} confirmLabel={confirmLabel}
                   onConfirm={confirm} fixedWidth="65%" className="welfareModal">
            <DepartmentSelection
                selectedDepartment={welfareToEdit.welfareDepartment}
                onClick={department => handleChange('welfareDepartment', department)}
            />
            <div className="mt-4 mb-3">
                {getDescription()}
            </div>
            <div className="row box mb-2">
                <div className="col-12 col-lg-6">
                    <DateInput
                        withYear={true}
                        label={t('Welfare.CHILD_BIRTHDATE')}
                        onChange={value => handleChange('birthDate', value)}
                        isValid={showErrors ? validation.birthDate : true}
                        value={welfareToEdit.birthDate ? new Date(welfareToEdit.birthDate) : null}
                        maxDate={Date.now()}
                        required={true}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <TextInput
                        label={t('Welfare.BG_NUMBER')}
                        onChange={value => handleChange('bgNumber', value)}
                        value={welfareToEdit.bgNumber}
                        isValid={!isJobcenter || (showErrors ? validation.bgNumber : true)}
                        required={isJobcenter}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <WelfareUploader
                        onChange={handleChange}
                        fileName={welfareToEdit.file ? welfareToEdit.file.name :
                            documents && documents.length > 0 && documents[0].name}
                        showErrors={showErrors}
                        required={!isLandratsamt}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <DateInput
                        label={t('Welfare.VALID_TO')}
                        onChange={value => handleChange('validTo', value)}
                        value={welfareToEdit.validTo ? new Date(welfareToEdit.validTo) : null}
                        minDate={Date.now()}
                        isValid={showErrors ? validation.validTo : true}
                        required={!isLandratsamt}
                    />
                </div>
            </div>
        </EditModal>
    );
}

export default WelfareEditModal;
