const initialState = {
    filter: null,
    pagination: {page: 0}
};

export default function carrierFilterReducer(state = initialState, action = {type: ''}) {
    switch (action.type) {
        case 'RESET_CARRIER_FILTER': {
            return {
                ...state,
                filter: null
            };
        }
        case 'SET_CARRIER_FILTER': {
            return {
                ...state,
                filter: action.value
            };
        }

        case 'SET_CARRIER_PAGE_INDEX': {
            if (!action.pageIndex) {
                return {
                    ...state,
                    pagination: initialState.pagination
                };
            }
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.pageIndex
                }
            };
        }
    }
    return state;
}
