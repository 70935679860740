import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import billApi from '../../caterer/bills/api/billApi';
import FilePreview from '../../common/component/FilePreview';
import CloseIcon from '../../common/icons/CloseIcon';
import DownloadIcon from '../../common/icons/DownloadIcon';
import Icon from '../../common/icons/Icon';
import InfoIcon from '../../common/icons/InfoIcon';
import RefreshIcon from '../../common/icons/RefreshIcon';
import {BillType} from '../../types/BillType';

function isCancelBill(billType: string): boolean {
    return billType.indexOf('CANCEL') >= 0;
}

type Props = {
    billId: number,
    billDisplayName: string,
    showAdminIcons?: boolean,
    row: any,
    onSelectBillToUpdate: (bill: BillType) => void,
    onDownloadBill: (billId: number, filename: string) => void
}

function BillTableToolCell({billId, billDisplayName, showAdminIcons = false, row, onSelectBillToUpdate, onDownloadBill}: Readonly<Props>) {
    const [t] = useTranslation();

    const updatable = useMemo(() => !(row.original.billType === 'MISSING_BILL' || row.original.billType.includes('CANCEL')), [row.original]);
    const outdated = useMemo(() => !!row.original.replacedByBillNumber || !!row.original.cancellingBillingNumber, [row.original]);

    return (
        <div className="d-flex justify-content-end align-items-center">
            {
                showAdminIcons &&
                <>
                    {
                        updatable && !outdated &&
                        <div className="file-preview-icon d-inline-block mr-2 mr-lg-4">
                            <Icon src={<RefreshIcon/>}
                                  onClick={() => onSelectBillToUpdate(row.original)}
                                  className="icon-primary"
                                  title={t('BillOverview.UPDATE')}/>
                        </div>
                    }
                    {
                        isCancelBill(row.original.billType) &&
                        <div className="d-inline-block text-secondary mr-2 mr-lg-4">
                            <Icon src={<CloseIcon/>} className="ml-2"
                                  title={`${t('Finance.CANCEL_BILL')} (${row.original.cancelledBillingNumber})`}/>
                        </div>
                    }
                    {
                        row.original.replacedByBillNumber &&
                        <div className="d-inline-block text-secondary mr-2 mr-lg-4">
                            <Icon src={<InfoIcon/>} className="ml-2"
                                  title={`${t('Finance.REPLACED_BILL')}: ${row.original.replacedByBillNumber}`}/>
                        </div>
                    }
                    {
                        row.original.cancellingBillingNumber && !row.original.replacedByBillNumber &&
                        <div className="d-inline-block text-secondary mr-2 mr-lg-4">
                            <Icon src={<InfoIcon/>} className="ml-2"
                                  title={`${t('Finance.CANCELLED_BY_BILL')}: ${row.original.cancellingBillingNumber}`}/>
                        </div>
                    }
                </>
            }
            <FilePreview
                downloadFunction={billApi.downloadBillDocumentById}
                id={billId}
                fileName={billDisplayName}
                title={undefined}
                fileType={undefined}
            />
            <div className="file-preview-icon d-inline-block mr-2 mr-lg-4">
                <Icon src={<DownloadIcon/>}
                      onClick={() => onDownloadBill(billId, billDisplayName)}
                      className="icon-primary"
                      title={t('FileButton.DOWNLOAD')}
                />
            </div>
        </div>
    );
}

export default BillTableToolCell;
