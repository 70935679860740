import React, {ChangeEvent, DragEvent, useRef, useState} from 'react';
import Icon from '../icons/Icon';
import UploadIcon from '../icons/UploadIcon';
import '../style/FilePickerDropzone.scss';

interface FilePickerDropzoneProps {
    label: string;
    handleChange: (file: File) => void;
    allowedFileTypes?: string;
    showError?: boolean;
    errorLabel?: string;
}

const FilePickerDropzone = (props: FilePickerDropzoneProps) => {
    const [isDragover, setIsDragover] = useState(false);

    const inputFile = useRef<any>(null);

    const isAllowedFileType = (file: File):boolean => {
        if(!props.allowedFileTypes){
            return true;
        }

        const allowedFileTypes = props.allowedFileTypes.split(',')?.map(fileType => fileType.trim());
        const currentFileType = file.name.split('.').reverse()[0];

        return allowedFileTypes.includes(`.${currentFileType}`);
    }

    const handleDropFile = (e: DragEvent) => {
        e.preventDefault();
        const file = (e?.dataTransfer?.files ?? [])[0];
        if(file && isAllowedFileType(file)){
            props.handleChange(file)
        }
        setIsDragover(false)
    }

    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        if(!isDragover){
            setIsDragover(true)
        }
    }

    const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        if(isDragover){
            setIsDragover(false)
        }
    }

    const openFile = (e: any) => {
        e.preventDefault();
        inputFile?.current?.click();
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = (e?.currentTarget?.files ?? [])[0];
        if(file){
            props.handleChange(file);
        }
    }

    const className = `file-picker-drop-zone ${isDragover ? 'dragover' : ''}`;

    return (
        <div className={className}
             onDragOver={handleDragOver}
             onDrop={handleDropFile}
             onDragLeave={handleDragLeave}>
            <a href="#" onClick={openFile} className="d-block text-center">
                {props.label}
                <Icon src={<UploadIcon/>} className="ml-2" onClick={undefined} title={undefined} hidden={undefined} skidding={undefined}/>
            </a>
            <input
                className="d-none"
                type="file"
                accept={props.allowedFileTypes}
                onChange={handleChange}
                ref={inputFile}/>
            {
                props.showError &&
                <div className="small align-bottom text-danger">
                    {props.errorLabel}
                </div>
            }
        </div>
    );
};

export default FilePickerDropzone;
