import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import TextInput from '../../../common/component/form/TextInput';
import TextOutput from '../../../common/component/TextOutput';
import ArrowRightIcon from '../../../common/icons/ArrowRightIcon';
import Icon from '../../../common/icons/Icon';
import {DefaultState} from '../../../common/reducer/reducers';
import {convertAmount, currencyFormatter} from '../../../common/util/CurrencyUtil';
import {callOnEnter} from '../../../common/util/FormUtil';
import paymentProviderIntegrationApi from '../../api/paymentProviderIntegrationApi';
import '../../style/StripePaymentCardWithFee.scss';

interface StripePaymentCardWithFeeProps {
    onDetermineErrorMessage: (amount: number) => string;
    onCreatePaymentIntent: (amount: number) => void;
    onCancel: () => void;
    isValid: boolean;
    setIsValid: (valid: boolean) => void;
}

function StripePaymentCardWithFee({
                                      onDetermineErrorMessage,
                                      isValid,
                                      setIsValid,
                                      onCreatePaymentIntent,
                                      onCancel
                                  }: StripePaymentCardWithFeeProps) {

    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const [amount, setAmount] = useState<string>('');
    const [amountInclFee, setAmountInclFee] = useState<number | null>();
    const financeConfig = useSelector((state: DefaultState) => state.financeConfig.financeConfig);
    const priceFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const sendCalculationRequest = useCallback(() => {
        paymentProviderIntegrationApi.calculateAmountIncludingFee(convertAmount(amount, currentLanguage)).then(response => {
            if (response.data.success) {
                setAmountInclFee(response.data.result);
            }
        });
    }, [amount, currentLanguage]);

    useEffect(() => {
        const convertedAmount = convertAmount(amount, currentLanguage);
        if (!amount || !convertedAmount || isNaN(convertedAmount) || Number(convertedAmount) <= 0) {
            // Don't trigger a request.
            setAmountInclFee(null);
            return;
        }

        // Cancel existing timeout before setting new timeout:
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(sendCalculationRequest, 1000);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [amount, sendCalculationRequest, currentLanguage]);

    const confirmAmount = useCallback(() => {
        onCreatePaymentIntent(
            // @ts-ignore
            convertAmount(amount, currentLanguage));
    }, [amount, currentLanguage, onCreatePaymentIntent]);

    return <>
        {t('Finance.PAYMENT_METHOD_HINTS.WITH_FEE')}
        <div className="row align-items-start mt-4">
            <div className={`col-12 ${financeConfig.FINANCE_FEE_ACTIVE ? 'col-lg-6' : ''}`}>
                <TextInput
                    label={t('Finance.AMOUNT_EURO_EXCL_FEE')}
                    value={amount}
                    onChange={(value) => {
                        setAmount(value);
                        setIsValid(true);
                    }}
                    required={true}
                    isValid={isValid}
                    onKeyDown={event => callOnEnter(event, confirmAmount)}
                    // @ts-ignore
                    errorMessage={onDetermineErrorMessage(convertAmount(amount, currentLanguage))}
                />
            </div>
            {
                financeConfig.FINANCE_FEE_ACTIVE &&
                <div className="col-12 col-lg-6">
                    <div className="row col">
                        <TextOutput
                            className="stripe-payment-with-fee"
                            label={t('Finance.AMOUNT_INCL_FEE')}
                            text={amountInclFee ? priceFormatter.format(amountInclFee) : ''}
                        />
                    </div>
                </div>
            }
        </div>

        <div className="text-right mt-2">
            <button className="btn btn-secondary mr-2" onClick={onCancel}>{t('Button.BACK')}</button>
            <button className="btn btn-primary" onClick={confirmAmount}>
                {t('Button.NEXT')}<Icon className="ml-2" src={<ArrowRightIcon/>}/>
            </button>
        </div>
    </>;
}

export default StripePaymentCardWithFee;
