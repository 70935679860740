import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Child} from '../../../types/Child';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import ChildSetupSpecialDietSelection from './ChildSetupSpecialDietSelection';
import ChildSetupSpecialDietYesNo from './ChildSetupSpecialDietYesNo';

type Props = {
    accountId: number,
    child: Child,
    specialMenuGroupsOpen: Array<MenuGroup>,
    specialMenuGroupsAttest: Array<MenuGroup>,
    onProceed: (menuGroup: MenuGroup | null) => void,
}

function ChildSetupSpecialDiet({accountId, child, specialMenuGroupsOpen, specialMenuGroupsAttest, onProceed}: Readonly<Props>) {
    const [t] = useTranslation();

    const [isSpecialMealRequested, setIsSpecialMealRequested] = useState(false);

    return <div className="card">
        <div className="card-header">
            {
                isSpecialMealRequested ?
                t('ChildSetup.SELECT_SPECIAL_MEAL_CARD_HEADER', {name: child.firstName})
                :
                t('ChildSetup.SPECIAL_MEAL_REQUIRED_CARD_HEADER', {name: child.firstName})
            }
        </div>

        <div className="card-body">
            {
                isSpecialMealRequested ?
                    <ChildSetupSpecialDietSelection
                        accountId={accountId}
                        child={child}
                        specialMenuGroupsOpen={specialMenuGroupsOpen}
                        specialMenuGroupsAttest={specialMenuGroupsAttest}
                        onSelect={menuGroup => onProceed(menuGroup)}
                        onSkip={() => onProceed(null)}
                    />
                    :
                    <ChildSetupSpecialDietYesNo
                        specialMenuGroupsOpen={specialMenuGroupsOpen}
                        specialMenuGroupsAttest={specialMenuGroupsAttest}
                        onRequest={() => setIsSpecialMealRequested(true)}
                        onDiscard={() => onProceed(null)}
                    />
            }
        </div>
    </div>;
}

export default ChildSetupSpecialDiet;
