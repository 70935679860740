import React from 'react';
import {SwitchType} from '../../types/enums/common/SwitchType';
import '../style/Switch.scss';

interface Prop {
    options?: Array<SwitchType>,
    selectedOption?: string,
    onSelect?: (value: any) => void,
    className?: string,
    classNameForNonActiveOption?: string
}

function Switch(props: Prop) {
    if (!props.options) {
        return null;
    }

    return (
        <div className={`switch pb-3 ${props.className ? props.className : ''}`}>
            {
                props.options.map((option, index) =>
                    <a className="switch-item" key={`tab-option-${index}`}>
                        <span
                            className={(option.id === props.selectedOption ? 'active' :
                                props.classNameForNonActiveOption ? props.classNameForNonActiveOption : '')}
                            onClick={() => props.onSelect ? props.onSelect(option.id) : undefined}>
                            {option.icon}
                            {option.label}
                        </span>
                    </a>
                )
            }
        </div>
    );
}

export default Switch;
