import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {SHOW_MESSAGE_SECONDS} from '../action/messageActions';
import '../style/Messages.scss';

export default function Messages() {
    const messages = useSelector(state => state.message.messages);
    const [visibleMessages, setVisibleMessages] = useState([]);
    const visibleMessagesRef = useRef(visibleMessages);
    visibleMessagesRef.current = visibleMessages;
    const [timers, setTimers] = useState({});

    const [t] = useTranslation();

    useEffect(() => {
        return () => {
            // Cancel timers on unmount
            cancelTimers();
        };
    }, []);

    useEffect(() => {
        let updatedTimers = {...timers};
        const updatedMessages = messages.filter(message => message.created >= (new Date()).getTime() - 1000 * SHOW_MESSAGE_SECONDS);

        // Create timers for all new messages
        for (const message of updatedMessages) {
            if (!updatedTimers[message.created + message.label]) {
                updatedTimers[message.created + message.label] = setTimeout(() => hideMessage(message),
                    SHOW_MESSAGE_SECONDS * 1000);
            }
        }

        setTimers(updatedTimers);
        sortMessages(updatedMessages);
        setVisibleMessages(updatedMessages);
    }, [messages]);

    const cancelTimers = () => {
        for (const timer of Object.values(timers)) {
            if (timer) {
                clearTimeout(timer);
            }
        }
    };

    const hideMessage = (message) => {
        const updatedMessages = visibleMessagesRef.current.filter(updatedMessage =>
            !(updatedMessage.created === message.created && updatedMessage.label === message.label));
        setVisibleMessages(updatedMessages);
    };

    const sortMessages = (unsortedMessages) => {
        unsortedMessages.sort((message1, message2) => {
            if (!message1) {
                return -1;
            }
            if (!message2) {
                return 1;
            }
            if (message1.created === message2.created) {
                return 0;
            }
            return message1.created > message2.created ? 1 : -1;
        });
    };

    if (!visibleMessages || !visibleMessages.length) {
        return null;
    }

    return (
        <div className="oc-message-parent fixed-top">
            {
                visibleMessages.map((message, index) =>
                    <div
                        key={message.created}
                        className={'oc-message shadow alert ' + (message.type === 'error' ? 'alert-danger' : 'alert-info')}
                        style={{top: ((index + 2) * 60) + 'px'}}
                        data-cy={message.type === 'error' ? 'alert-danger' : 'alert-info'}>
                        {t(message.label, message.labelParameters)}
                    </div>
                )
            }
        </div>
    );
}
