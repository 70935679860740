import React, {MouseEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '../icons/CloseIcon';

type Props = {
    label?: string,
    onClick: MouseEventHandler<HTMLButtonElement>,
    buttonStyle?: 'transparent' | 'secondary',
    disabled?: boolean,
    // Usually the cancel button sits left to the confirm button. Thus, by default there is a margin to the right of the cancel button.
    omitMargin?: boolean,
    showIcon?: boolean,
    className?: string
}

function CancelButton({label, onClick, buttonStyle, disabled, omitMargin = false, showIcon = true, className}: Readonly<Props>) {
    const [t] = useTranslation();
    const theLabel = useMemo(() => label ?? t('Button.CANCEL'), [label, t]);

    return <button className={`${className} btn btn-${buttonStyle ?? 'transparent'} ${omitMargin ? '' : 'mr-2'}`} onClick={onClick} disabled={disabled} data-cy="cancelButton">
        {
            showIcon &&
            <CloseIcon/>
        }
        <span className={showIcon ? 'ml-2' : ''}>{theLabel}</span>
    </button>;
}

export default CancelButton;
