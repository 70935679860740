import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Diet from '../../../common/component/Diet';
import PageTitleWithWallet from '../../../common/component/PageTitleWithWallet';
import {DefaultState} from '../../../common/reducer/reducers';
import {Child} from '../../../types/child/Child';
import Deadlines from '../Deadlines';

type Props = {
    child: Child
}

function ChildOrderHeader({child} : Readonly<Props>) {
    const [t] = useTranslation();

    const account = useSelector((state: DefaultState) => state.user.account);

    const foodRestrictions = useMemo(() => [
        ...child.allergies
            .filter(allergy => allergy.verified)
            .map(allergy => allergy.nutritionClass)
    ], [child]);

    return (
        <>
            <PageTitleWithWallet
                headerTitle={
                    <>
                        {t('Order.TITLE')}
                        <div className="d-table ml-4 mt-n2">
                            <small className="d-table-cell align-middle pr-3 pt-3">{t('Order.FOR')} {child.firstName}</small>
                            <div className="d-table-cell align-middle">
                                <Diet selectedItems={foodRestrictions}/>
                            </div>
                        </div>
                    </>
                }
                balance={account?.balance}
                displayBig={true}
                pricePositionOffset="-60px"
            />
            <p className="my-4">
                <Link to="/">
                    &larr; {t('Order.GO_BACK_TO_OVERVIEW')}
                </Link>
            </p>
            <Deadlines showAsCard={true} isGroup={false} catererId={child.group.catererId} label={undefined} showIcon={false}/>
        </>
    );
}

export default ChildOrderHeader;
