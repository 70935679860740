import React from 'react';
import {useTranslation} from 'react-i18next';
import Address from '../../common/component/Address';
import LoginCredentials from '../../common/component/form/LoginCredentials';
import Person from '../../common/component/form/Person';
import TextInput from '../../common/component/form/TextInput';

function CarrierInquiryInput({
                                 inquiry,
                                 validation,
                                 handleInquiryChange,
                                 handleCompanyChange,
                                 handleLoginChange,
                                 mailErrorCode
                             }) {
    const [t] = useTranslation();

    return (
        <>
            <TextInput
                id="company-name"
                type="text"
                label={t('Registration.COMPANY_NAME')}
                value={inquiry.company.name}
                onChange={value => handleCompanyChange('name', value)}
                required={true}
                isValid={validation.company.name}
            />
            <Address
                address={inquiry.company.address}
                handleChange={value => handleCompanyChange('address', value)}
                validation={validation.company.address}
            />
            <h3>{t('Registration.CONTACT_PERSON')}</h3>
            <Person
                person={inquiry.contactPerson}
                onChange={value => handleInquiryChange('contactPerson', value)}
                validation={validation.contactPerson}
                isContact={true}
                optionals={{firstName: true}}
            />
            <LoginCredentials
                login={inquiry.login}
                onChange={handleLoginChange}
                validation={validation.login}
                mailErrorCode={mailErrorCode}
                showPassword={false}
                isPasswordRequired={false}
            />
        </>
    );
}

export default CarrierInquiryInput;
