import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DefaultState} from '../../../common/reducer/reducers';
import {currencyFormatter} from '../../../common/util/CurrencyUtil';
import {Child} from '../../../types/Child';
import '../../style/childSetup/ChildSetup.scss';

type Props = {
    child: Child,
    onChange: (value: number) => void,
    showValidationError: boolean
}

function ChildSetupOnSiteOrderLimits({child, onChange, showValidationError}: Readonly<Props>) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const amountFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);
    const PREDEFINED_OPTIONS = [5, 10, 20, 0];

    return (
        <div className="my-4">
            <div className="card-header">{t('Child.DAILY_LIMIT_TITLE')}</div>

            <div className="card-body">
                <div className="row">
                    {
                        PREDEFINED_OPTIONS.map((option) =>
                            <div className="col-12 col-sm-6 mb-2" key={option}>
                                <div className={`shadow-card-btn ${child.dailyOnSiteOrderLimit === option ? 'active' : ''}`}
                                     onClick={() => onChange(option)}
                                >
                                    {option > 0 ? amountFormatter.format(option) : t('Child.NO_ON_SITE_ORDER_ALLOWED')}
                                </div>
                            </div>
                        )
                    }
                    {
                        showValidationError &&
                        <div className="invalid-feedback d-block">
                            {t('Validation.MISSING_CHOICE_FROM_OPTIONS')}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ChildSetupOnSiteOrderLimits;
