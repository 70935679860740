import {ChildSubscription} from '../../types/child/ChildSubscription';

export function validateChildSubscription(subscription: ChildSubscription) {
    return {
        startDate: !!subscription.startDate && (!subscription.pauseStartDate || new Date(subscription.pauseStartDate) >= new Date(subscription.startDate)),
        endDate: !subscription.endDate ||
            (new Date(subscription.endDate) > new Date(subscription.startDate) &&
                (!subscription.pauseEndDate || new Date(subscription.endDate) >= new Date(subscription.pauseEndDate))),
        pauseStartDate: !!subscription.pauseStartDate === !!subscription.pauseEndDate &&
            (!subscription.pauseStartDate || new Date(subscription.pauseStartDate) >= new Date(subscription.startDate)),
        pauseEndDate: !!subscription.pauseStartDate === !!subscription.pauseEndDate &&
            (!subscription.endDate || !subscription.pauseEndDate || new Date(subscription.endDate) >= new Date(subscription.pauseEndDate)),
        childId: !!subscription.childId,
        weekdays: !!subscription.weekdays && subscription.weekdays.length > 0
    };
}
