import React from 'react';
import {useTranslation} from 'react-i18next';
import ArrowRightIcon from '../icons/ArrowRightIcon';

type Props = {
    onClick: () => void
}

function NextButton({onClick}: Readonly<Props>) {
    const [t] = useTranslation();

    return <button className="btn btn-primary" onClick={onClick}>
        {t('Button.NEXT')}
        <div className="ml-2"><ArrowRightIcon/></div>
    </button>;
}

export default NextButton;
