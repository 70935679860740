import React, {useEffect, useState} from 'react';
import {Nav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import BurgerIcon from '../../icons/BurgerIcon';
import CloseIcon from '../../icons/CloseIcon';
import GabelEinsLogo from '../../icons/GabelEinsLogo';
import '../../style/Header.scss';
import {getFileExtension} from '../../util/FileUtil';
import {setFavicon, setGabel1Favicon, setTabTitle} from '../../util/HeaderUtil';
import NavigationLoggedIn from './NavigationLoggedIn';
import NavigationLoggedOut from './NavigationLoggedOut';

function Header() {
    const [navExtended, setNavExtended] = useState(false);

    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const displayName = useSelector(state => state.caterer.displayName);
    const companyName = useSelector(state => state.caterer.companyName);
    const catererLogo = useSelector(state => state.caterer.logo);
    const logoExtension = getFileExtension(catererLogo?.data);
    const catererIcon = useSelector(state => state.caterer.icon);
    const iconExtension = getFileExtension(catererIcon?.data);
    const domainType = useSelector(state => state.domainContext.domainType);
    const pageClickCount = useSelector(state => state.pageClick.pageClickCount);

    useEffect(() => {
        if (domainType === 'SUPPORT') {
            setGabel1Favicon();
        } else if (domainType === 'CATERER' && catererIcon) {
            setFavicon(catererIcon, iconExtension);
        }

        if (domainType === 'SUPPORT') {
            setTabTitle(undefined, 'Gabel1 - Support');
        } else {
            setTabTitle(displayName?.length > 0 ? displayName : companyName, 'Gabel1');
        }
    }, [domainType, catererIcon, iconExtension, displayName, companyName]);

    useEffect(() => {
        setNavExtended(false);
    }, [pageClickCount]);

    useEffect(() => {
        if (navExtended) {
            document.documentElement.style.overflow = 'hidden';
            document.body.scroll = 'no';
        } else {
            document.documentElement.style.overflow = 'scroll';
            document.body.scroll = 'yes';
        }
    }, [navExtended]);

    return (
        <>
            <header>
                <Navbar id="header" expand="lg" expanded={navExtended} onToggle={() => setNavExtended(!navExtended)}>
                    <Navbar.Brand>
                        <NavLink to="/">
                            {
                                catererLogo?.data ?
                                    <span className="caterer-logo">
                                        <img src={`data:image/${logoExtension};base64,${catererLogo.data}`} alt={companyName}/>
                                    </span>
                                    :
                                    <>
                                        {
                                            catererLogo?.tried &&
                                            <>
                                                <div className="d-none d-md-flex">
                                                    <GabelEinsLogo/>
                                                </div>
                                                <div className="d-flex d-md-none">
                                                    <GabelEinsLogo size={0.75}/>
                                                </div>
                                            </>
                                        }
                                    </>
                            }
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="header-navigation">
                        {
                            navExtended ?
                                <CloseIcon size={1.2}/>
                                :
                                <BurgerIcon size={1.2}/>
                        }
                    </Navbar.Toggle>


                    <Navbar.Collapse id="header-navigation" onClick={() => setNavExtended(false)}>
                        <Nav id="navigation" className="ml-lg-auto pt-4 pt-lg-0">
                            {
                                isLoggedIn ?
                                    <NavigationLoggedIn/>
                                    :
                                    <NavigationLoggedOut/>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
            <div id={'menu-overlay'} className={navExtended ? 'active' : ''}/>
        </>
    );
}

export default Header;
