export function setDynamicPrimaryColorHex(root, primaryColorHex) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (primaryColorHex.length === 4) {
        r = '0x'.concat(primaryColorHex[1]).concat(primaryColorHex[1]);
        g = '0x'.concat(primaryColorHex[2]).concat(primaryColorHex[2]);
        b = '0x'.concat(primaryColorHex[3]).concat(primaryColorHex[3]);
    } else if (primaryColorHex.length === 7) {
        r = '0x'.concat(primaryColorHex[1]).concat(primaryColorHex[2]);
        g = '0x'.concat(primaryColorHex[3]).concat(primaryColorHex[4]);
        b = '0x'.concat(primaryColorHex[5]).concat(primaryColorHex[6]);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin;
    let h = 0,
        s = 0,
        l = 0;

    if (delta !== 0) {
        if (cmax === r) {
            h = ((g - b) / delta) % 6;
        } else if (cmax === g) {
            h = (b - r) / delta + 2;
        } else {
            h = (r - g) / delta + 4;
        }
    }

    h = Math.round(h * 60);

    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    setDynamicPrimaryColorHsl(root, h, s, l);
}

export function setDynamicPrimaryColorHsl(root, h, s, l) {
    root.style.setProperty('--base-color-h', h);
    root.style.setProperty('--base-color-s', s + '%');
    root.style.setProperty('--base-color-l', l + '%');
}
