import {httpClient} from '../../common/api/HttpClient';

export const administrationApi = {
    findAccounts: (filter) => {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 15;
        }
        const queryParams = accountSearchCriteriaToQueryParams(filter);
        return httpClient.get(`accounts?${queryParams}`);
    },
    findAllInstitution: () => httpClient.get('institutions'),
    findAllCaterer: () => httpClient.get('caterers'),

    searchParents(filter) {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 15;
        }
        const queryParams = parentSearchCriteriaToQueryParams(filter);
        return httpClient.get(`accounts/parents?${queryParams}`);
    },

    findAllScheduledJobs: () => {
        return httpClient.get('config/gabel1/scheduled-jobs');
    },
    updateScheduledJob: (jobConfiguration) => {
        return httpClient.post('config/gabel1/scheduled-jobs', jobConfiguration);
    },
    fetchScheduledJobHistory: (filter) => {
        return httpClient.get('config/gabel1/scheduled-job-history', {
            params: filter
        });
    }
};

export const accountSearchCriteriaToQueryParams = (filter) => {
    const queryParams = new URLSearchParams();
    if (filter) {
        if (filter.page >= 0) {
            queryParams.append('page', filter.page);
        }
        if (filter.pageSize) {
            queryParams.append('pageSize', filter.pageSize);
        }
        if (filter.text) {
            queryParams.append('text', filter.text);
        }
        if (filter.role) {
            queryParams.append('role', filter.role);
        }
    }
    return queryParams.toString();
};

export const parentSearchCriteriaToQueryParams = (filter) => {
    const queryParams = new URLSearchParams();

    if (filter) {
        if (filter.page >= 0) {
            queryParams.append('page', filter.page);
        }
        if (filter.pageSize) {
            queryParams.append('pageSize', filter.pageSize);
        }
        if (filter.text) {
            queryParams.append('text', filter.text);
        }
        if (filter.currentBalance) {
            queryParams.append('currentBalance', filter.currentBalance);
        }
        if (filter.futureBalance) {
            queryParams.append('futureBalance', filter.futureBalance);
        }
        if (filter.mandate) {
            queryParams.append('mandate', filter.mandate);
        }
        if (filter.hasValidBut) {
            queryParams.append('hasValidBut', filter.hasValidBut);
        }
        if (filter.bankTransferApproval) {
            queryParams.append('hasBankTransferApproval', filter.bankTransferApproval);
        }
        if (filter.includePending) {
            queryParams.append('includePending', 'true');
        }
        if (filter.catererId) {
            queryParams.append('catererId', filter.catererId);
        }
    }
    return queryParams.toString();
};

export default administrationApi;
