import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import Price from '../../common/component/Price';
import DeleteIcon from '../../common/icons/DeleteIcon';
import Icon from '../../common/icons/Icon';
import {DefaultState} from '../../common/reducer/reducers';
import {currencyFormatter} from '../../common/util/CurrencyUtil';
import {formatDate, formatDateAndTime} from '../../common/util/DateUtil';
import TaskStatus from '../../taskboard/component/TaskStatus';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';
import {BalanceWithdrawalType} from '../../types/finance/BalanceWithdrawalType';

type Props = {
    balanceWithdrawals: Array<BalanceWithdrawalType>,
    onDelete: (withdrawalId: number) => void
}

function BalanceWithdrawalsWithTaskList({balanceWithdrawals, onDelete}: Readonly<Props>) {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const [withdrawalToDelete, setWithdrawalToDelete] = useState<BalanceWithdrawalType>();

    const withdrawalAmountFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);

    if (balanceWithdrawals.length === 0) {
        return null;
    }

    return (<>
        <p className="font-weight-bold">{t('Finance.BALANCE_WITHDRAWAL.EXISTING_WITHDRAWALS')}:</p>
        {
            balanceWithdrawals.map((withdrawal) =>
                <div className="row" key={withdrawal.id}>
                    <div className="fix-text-group col-3 mb-2">
                        <label>{t('Finance.BALANCE_WITHDRAWAL.ENQUIRY_DATE')}</label>
                        <p>{formatDateAndTime(withdrawal.created, currentLanguage, true, false)}</p>
                    </div>
                    <div className="fix-text-group col-3 mb-2">
                        <label>{t('Finance.BALANCE_WITHDRAWAL.WITHDRAWAL_AMOUNT')}</label>
                        <div className="mt-1">{withdrawal.withdrawalAmount ?
                            <Price price={withdrawal.withdrawalAmount} textRight={false}/> : '-'}</div>
                    </div>
                    <div className="fix-text-group col-3 mb-2">
                        <label>{t('Finance.BALANCE_WITHDRAWAL.RECEIVER')}</label>
                        <p>{withdrawal.receiverName}</p>
                    </div>
                    <div className="fix-text-group col-2 mb-2">
                        <label>{t('Finance.BALANCE_WITHDRAWAL.TASK_STATE')}</label>
                        <p><TaskStatus state={withdrawal.task.taskState}/></p>
                    </div>
                    <div className="pt-4 col-1">
                        {
                            withdrawal.task.taskState === TaskStateEnum.OPEN &&
                            <Icon src={<DeleteIcon/>}
                                  onClick={() => setWithdrawalToDelete(withdrawal)}
                                  className="icon-secondary ml-3"
                                  title={t('Button.DELETE')}
                            />
                        }
                    </div>
                </div>
            )
        }
        <div className="mt-3">
            <p className="font-weight-bold mt-3 mb-1">{t('Finance.BALANCE_WITHDRAWAL.STATUS_HINT_HEADER')}</p>
            <p className="pre-wrap">{t('Finance.BALANCE_WITHDRAWAL.STATUS_HINT')}</p>
        </div>

        {
            withdrawalToDelete &&
            <ConfirmationDialog
                open={!!withdrawalToDelete}
                onConfirm={() => onDelete(withdrawalToDelete.id)}
                onCancel={() => setWithdrawalToDelete(undefined)}
                title={t('Common.CONFIRM_DELETE_TITLE')}
                body={
                    t('Finance.BALANCE_WITHDRAWAL.CONFIRM_DELETE',
                        {
                            date: formatDate(currentLanguage, withdrawalToDelete.created),
                            amount: withdrawalAmountFormatter.format(withdrawalToDelete.withdrawalAmount)
                        }
                    )}
                confirmLabel={t('Button.DELETE')}
            />
        }
    </>);
}

export default BalanceWithdrawalsWithTaskList;
