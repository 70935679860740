export function getSeparator(locale, separatorType) {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    return Intl.NumberFormat(locale)
        .formatToParts(numberWithGroupAndDecimalSeparator)
        .find(part => part.type === separatorType)
        .value;
}

// formatToParts does not work on older browsers. We must handle numbers on our own.
export function convertAmount(inputAmount, language) {
    if (inputAmount) {
        if (language === 'de-DE') {
            return Number(inputAmount.toString().replace(/,/g, '.'));
        } else {
            return Number(inputAmount);
        }
    }
    return null;
}

// Format only amount (i.e. without currency symbol)
export function formatAmount(inputAmount, language) {
    if (!inputAmount) {
        return null;
    }

    if (language === 'de-DE') {
        const convertedAmount = convertAmount(inputAmount, language);
        if (Number.isNaN(convertedAmount)) {
            return inputAmount;
        }
        return convertedAmount.toFixed(2).replace(/\./g, ',');
    } else {
        const amountAsNumber = Number(inputAmount);
        if (Number.isNaN(amountAsNumber)) {
            return inputAmount;
        }
        return amountAsNumber.toFixed(2);
    }
}

export function currencyFormatter(currentLanguage, fractionDigits = 2) {
    return new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    });
}
