import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import {setLoaderHint} from '../../common/action/LoaderAction';
import {markChanges, unmarkChanges} from '../../common/action/pageLeaveActions';
import BackTo from '../../common/component/BackTo';
import Card from '../../common/component/Card';
import TextInput from '../../common/component/form/TextInput';
import Toggle from '../../common/component/Toggle';
import ArrowRightIcon from '../../common/icons/ArrowRightIcon';
import Icon from '../../common/icons/Icon';
import PayoutIcon from '../../common/icons/PayoutIcon';
import {DefaultState} from '../../common/reducer/reducers';
import {convertAmount, formatAmount} from '../../common/util/CurrencyUtil';
import {callOnEnter} from '../../common/util/FormUtil';
import {checkValidation} from '../../common/util/ValidationUtil';
import {showError, showMessage} from '../../message/action/messageActions';
import {BalanceWithdrawalResultType} from '../../types/finance/BalanceWithdrawalResultType';
import {BalanceWithdrawalType} from '../../types/finance/BalanceWithdrawalType';
import {BalanceWithdrawalValidation} from '../../types/finance/BalanceWithdrawalValidation';
import {reloadBalance} from '../../user/action/userActions';
import {searchTransactions} from '../actions/financeActions';
import withdrawalApi from '../api/withdrawalApi';
import BalanceWithdrawalByCatererTask from './BalanceWithdrawalByCatererTask';
import BalanceWithdrawalByPaymentSelection from './BalanceWithdrawalByPaymentSelection';
import BalanceWithdrawalsWithTaskList from './BalanceWithdrawalsWithTaskList';

function BalanceWithdrawal() {
    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const transactionFilter = useSelector((state: DefaultState) => state.finance.transactionFilter);
    const balance = useSelector((state: DefaultState) => !!state.user.account?.balance ? state.user.account?.balance : 0);
    const formatter = useMemo(() => new Intl.NumberFormat(currentLanguage, {
        style: 'currency',
        currency: 'EUR'
    }), [currentLanguage]);
    const [withdrawalResult, setWithdrawalResult] = useState<BalanceWithdrawalResultType>();
    const [balanceWithdrawal, setBalanceWithdrawal] = useState<BalanceWithdrawalType>({withdrawalAmount: formatAmount(balance, currentLanguage)} as BalanceWithdrawalType);
    const [existingBalanceWithdrawals, setExistingBalanceWithdrawals] = useState<Array<BalanceWithdrawalType>>();
    const [showErrors, setShowErrors] = useState(false);
    const [withAllAmount, setWithAllAmount] = useState(true);
    const [validation, setValidation] = useState<BalanceWithdrawalValidation>({
        withdrawalAmount: true,
        receiverName: true,
        iban: true
    });
    
    useEffect(() => {
        setBalanceWithdrawal({withdrawalAmount: formatAmount(balance, currentLanguage)} as BalanceWithdrawalType);
    }, [balance, currentLanguage]);

    const loadBalanceWithdrawals = useCallback(() => {
        withdrawalApi.findForAccount().then((res) => {
            if (res.data.success) {
                setExistingBalanceWithdrawals(res.data.result);
            }
        });
    }, []);

    useMount(loadBalanceWithdrawals);

    const handleChange = useCallback((field: string, value) => {
        dispatch(markChanges());
        setBalanceWithdrawal((currentValue) => {
            return {
                ...currentValue,
                [field]: value
            };
        });
    }, [dispatch]);

    const validate = useCallback(() => {
        const withdrawalAmountAsFloat = convertAmount(balanceWithdrawal?.withdrawalAmount, currentLanguage);
        const newValidation = {
            withdrawalAmount: !!balanceWithdrawal?.withdrawalAmount && withdrawalAmountAsFloat !== null && balance >= withdrawalAmountAsFloat
                && withdrawalAmountAsFloat > 0
        };
        setValidation(newValidation);

        return checkValidation(newValidation);
    }, [balanceWithdrawal, currentLanguage, balance]);

    useEffect(() => {
        if (showErrors) {
            validate();
        }
    }, [balanceWithdrawal, validate, showErrors]);

    function handleConfirmAmount() {
        const withdrawalAmount = convertAmount(balanceWithdrawal?.withdrawalAmount, currentLanguage);
        if (!withdrawalAmount) {
            dispatch(showError('Error.NUMBER_GREATER_ZERO'));
            return;
        }

        const moneyWithdrawalToSave: BalanceWithdrawalType = {
            ...balanceWithdrawal,
            withdrawalAmount: withdrawalAmount
        };
        if (validate()) {
            dispatch(setLoaderHint());
            withdrawalApi.sendRequest(moneyWithdrawalToSave).then((res) => {
                if (res.data.success) {
                    setWithdrawalResult(res.data.result);
                    dispatch(unmarkChanges());
                    setShowErrors(false);
                }
            });
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
            setShowErrors(true);
        }
    }

    const reset = useCallback(() => {
        dispatch(unmarkChanges());
        setWithdrawalResult(undefined);
        setBalanceWithdrawal({withdrawalAmount: formatAmount(balance, currentLanguage)} as BalanceWithdrawalType);
        setWithAllAmount(true);
        loadBalanceWithdrawals();
    }, [balance, currentLanguage, loadBalanceWithdrawals, dispatch]);

    const handleDelete = useCallback((withdrawalId: number) => {
        withdrawalApi.delete(withdrawalId).then((response) => {
            loadBalanceWithdrawals();

            if (response.data.success) {
                dispatch(showMessage('Finance.BALANCE_WITHDRAWAL.DELETE_SUCCESS'));
                dispatch(searchTransactions(transactionFilter));
                dispatch(reloadBalance());
            }
        });
    }, [loadBalanceWithdrawals, dispatch, transactionFilter]);

    const determineAmountErrorMessage = useCallback(() => {
        if (!balanceWithdrawal?.withdrawalAmount || balanceWithdrawal?.withdrawalAmount === 0) {
            return t('Validation.REQUIRED');
        }

        const convertedAmount = convertAmount(balanceWithdrawal?.withdrawalAmount, currentLanguage);
        if (convertedAmount === null || isNaN(convertedAmount)) {
            return t('Validation.INVALID_NUMBER');
        } else if (convertedAmount <= 0) {
            return t('Finance.BALANCE_WITHDRAWAL.NOT_POSITIV_AMOUNT');
        } else {
            return t('Finance.BALANCE_WITHDRAWAL.INVALID_AMOUNT', {balance: formatter.format(balance)});
        }
    }, [balanceWithdrawal, balance, formatter, currentLanguage, t]);

    const toggleWithdrawalAmountInput = () => {
        if (withAllAmount) {
            setWithAllAmount(false);
            setBalanceWithdrawal({} as BalanceWithdrawalType);
        } else {
            setWithAllAmount(true);
            setBalanceWithdrawal({withdrawalAmount: formatAmount(balance, currentLanguage)} as BalanceWithdrawalType);
        }
    };

    const handleDone = () => {
        history.push('/finances');
    };

    const titleText = useMemo(() => {
        if (balance <= 0) {
            return t('Finance.BALANCE_WITHDRAWAL.NO_POSITIVE_BALANCE_HEADER');
        }

        if (withdrawalResult) {
            return withdrawalResult.strategy === 'OFFER_PAYMENT_REFUND' ?
                t('Finance.BALANCE_WITHDRAWAL.CHOOSE_PAYMENT') : t('Finance.BALANCE_WITHDRAWAL.PAYMENT_ACCOUNT');
        } else {
            return t('Finance.BALANCE_WITHDRAWAL.CHOOSE_AMOUNT');
        }
    }, [balance, withdrawalResult, t]);

    return (
        <div className="container">
            <div className="center-screen">
                <BackTo path="/finances" labelKey="Finance.TITLE"/>
                <h3 className="mt-4"><Icon src={<PayoutIcon size={2}/>}/> {t('Finance.BALANCE_WITHDRAWAL.TITLE')}</h3>
                <Card
                    title={titleText}>
                    <div>
                        {
                            balance <= 0 &&
                            <p>{t('Finance.BALANCE_WITHDRAWAL.NO_POSITIVE_BALANCE', {balance: formatter.format(balance)})}</p>
                        }
                        {
                            balance > 0 && !withdrawalResult &&
                            <>
                                <Toggle toggled={withAllAmount} onClick={toggleWithdrawalAmountInput}
                                        label={t('Finance.BALANCE_WITHDRAWAL.ALL_AMOUNT', {amount: formatter.format(balance)})}/>
                                {
                                    !withAllAmount &&
                                    <TextInput
                                        className="mt-2"
                                        label={t('Finance.BALANCE_WITHDRAWAL.WITHDRAWAL_AMOUNT_INPUT')}
                                        value={balanceWithdrawal?.withdrawalAmount}
                                        onChange={value => handleChange('withdrawalAmount', value)}
                                        onKeyDown={event => callOnEnter(event, handleConfirmAmount)}
                                        isValid={!showErrors || !!validation.withdrawalAmount}
                                        errorMessage={showErrors && determineAmountErrorMessage()}
                                        required={true}
                                        readonly={balance <= 0}
                                    />
                                }
                                <div className="text-right">
                                    <button className="btn btn-primary " disabled={balance <= 0}
                                            onClick={handleConfirmAmount}> {t('Button.NEXT')} <Icon className="ml-2" src={<ArrowRightIcon/>}/>
                                    </button>
                                </div>
                            </>
                        }
                        {
                            !withdrawalResult &&
                            <BalanceWithdrawalsWithTaskList
                                balanceWithdrawals={existingBalanceWithdrawals ?? []}
                                onDelete={handleDelete}
                            />
                        }
                        {
                            withdrawalResult?.strategy === 'OFFER_PAYMENT_REFUND' &&
                            <BalanceWithdrawalByPaymentSelection
                                withdrawalAmount={withdrawalResult.amount}
                                paymentMethods={withdrawalResult.paymentMethods}
                                onDone={handleDone}
                                onCancel={reset}
                            />
                        }
                        {
                            withdrawalResult?.strategy === 'CATERER_TASK' &&
                            <BalanceWithdrawalByCatererTask
                                withdrawalAmount={withdrawalResult.amount}
                                onDone={handleDone}
                                onCancel={reset}
                            />
                        }
                    </div>
                </Card>
            </div>
        </div>);
}

export default BalanceWithdrawal;
