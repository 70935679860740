import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SlideDownCard from '../../common/component/SlideDownCard';

type DirectDebitDisabledNoticeProps = {
    title?: string;
}

function DirectDebitDisabledNotice({title}: DirectDebitDisabledNoticeProps) {
    const [t] = useTranslation();
    const [isClosed, setIsClosed] = useState(false);

    function renderNoticeBody() {
        return <div><span className="pre-wrap">{t('Finance.DIRECT_DEBIT_DISABLED')}</span></div>;
    }

    if (!title) {
        return (<>
            {renderNoticeBody()}
        </>);
    } else {
        return (<div className={isClosed ? 'mb-3' : 'mb-5'}>
            <SlideDownCard
                title={title}
                closed={isClosed}
                onToggle={setIsClosed}
            >
                {renderNoticeBody()}
            </SlideDownCard>
        </div>);
    }
}

export default DirectDebitDisabledNotice;
