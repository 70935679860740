import {Dispatch} from 'react';
import {AppThunk} from '../reducer/reducers';

export const SHOW_LOADER = 'SHOW_LOADER';
export const CONFIGURE_LOADER = 'CONFIGURE_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

interface ShowLoaderAction {
    type: typeof SHOW_LOADER;
}

interface ConfigureLoaderAction {
    type: typeof CONFIGURE_LOADER;
    mightTakeMinutes: boolean;
}

interface HideLoaderAction {
    type: typeof HIDE_LOADER;
}

export interface LoaderState {
    waitCount: number,
    show: boolean,
    mightTakeMinutes: boolean
}

export type LoaderActionTypes = ShowLoaderAction | ConfigureLoaderAction | HideLoaderAction;

export const showLoader = (): AppThunk => {
    return (dispatch: Dispatch<ShowLoaderAction>) => {
        return dispatch({
            type: SHOW_LOADER
        });
    };
};

export const setLoaderHint = (): AppThunk => {
    return (dispatch: Dispatch<ConfigureLoaderAction>) => {
        return dispatch({
            type: CONFIGURE_LOADER,
            mightTakeMinutes: true
        });
    };
};

export const hideLoader = (): AppThunk => {
    return (dispatch: Dispatch<HideLoaderAction>) => {
        return dispatch({
            type: HIDE_LOADER
        });
    };
};
