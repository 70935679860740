import React from 'react';
import Icon from '../icons/Icon';
import '../style/Card.scss';

interface Props {
    title?: string,
    className?: string,
    size?: string,
    onClick?: () => void,
    icon?: React.ReactNode;
    showExtraInfo?: boolean,
    infoText?: string,
    infoIcon?: React.ReactNode,
    children?: React.ReactNode;
}

function Card(props: Readonly<Props>) {

    return (
        <div className={`shadow-card ${props.className ? props.className : ''} ${props.size === 'small' ? 'card-small' : ''}`} onClick={props.onClick}>
            <div className="d-flex">
                {
                    (props.title || props.icon) &&
                    <div className="card-title">{props.icon && <Icon src={props.icon}/>} {props.title}</div>
                }
                <div className="text-success card-info ml-auto">
                    <div className="d-flex">
                        {
                            props.showExtraInfo ?
                                <div>{props.infoIcon && <Icon src={props.infoIcon}/>}</div>
                                :
                                <div className="card-info-text">{props.infoText}</div>
                        }
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    );
}

export default Card;
