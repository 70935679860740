import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import '../../style/Dropdown.scss';

type Props = {
    label?: string,
    value?: DropdownOption,
    multiValues?: Array<DropdownOption>,
    options: Array<DropdownOption>,
    isMulti?: boolean,
    isClearable?: boolean,
    isDisabled?: boolean,
    onChange?: (value: DropdownOption) => void,
    onChangeMulti?: (value: Array<DropdownOption>) => void,
    placeholder?: string,
    required?: boolean,
    isValid?: boolean,
    errorMessage?: string,
    formClassName?: string,
    tabIndex?: number,
    formatOptionLabel?: string
}

export type DropdownOption = {
    value: string,
    label?: string
}

function Dropdown({
                      label,
                      value,
                      multiValues,
                      options,
                      isMulti = false,
                      isClearable = false,
                      isDisabled = false,
                      onChange,
                      onChangeMulti,
                      placeholder,
                      required = false,
                      isValid,
                      errorMessage,
                      formClassName,
                      tabIndex,
                      formatOptionLabel
                  }: Readonly<Props>) {

    const [t] = useTranslation();

    function getSelectedValue() {
        if (!isMulti && value) {
            return options.find(option => option.value === value.value);
        } else if (multiValues) {
            const selectedValues = [];
            for (const option of options) {
                for (const selectedValueElement of multiValues) {
                    if (option.value === selectedValueElement.value) {
                        selectedValues.push(option);
                    }
                }
            }
            return selectedValues;
        } else {
            return null;
        }
    }

    const handleChange = useCallback((newValue: DropdownOption | Array<DropdownOption>) => {
        if (isMulti && onChangeMulti) {
            onChangeMulti(newValue as Array<DropdownOption>);
        } else if (onChange) {
            onChange(newValue as DropdownOption);
        }
    }, [onChange, onChangeMulti, isMulti]);

    return (
        <div className={formClassName ?? 'form-group'}>
            {
                label &&
                <label>{`${label} ${required ? '*' : ''}`}</label>
            }
            <Select
                className={isValid === false ? 'is-invalid' : null}
                classNamePrefix="dropdown"
                options={options}
                placeholder={placeholder ?? t('Common.PLEASE_SELECT')}
                isClearable={isClearable}
                onChange={handleChange}
                value={getSelectedValue()}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                noOptionsMessage={() => t('Common.NO_OPTIONS')}
                isDisabled={isDisabled ?? false}
                tabIndex={tabIndex}
                formatOptionLabel={formatOptionLabel ?? null}
            />

            {
                isValid === false &&
                <div className="invalid-feedback">
                    {errorMessage ?? t('Validation.REQUIRED')}
                </div>
            }
        </div>
    );
}

export default Dropdown;
