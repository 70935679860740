import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        backend: {
            loadPath: (process.env.REACT_APP_LABEL_SERVICE_URL || process.env.REACT_APP_API_URL) + 'public/labels/{{lng}}/{{ns}}',
            parse: function (data) {
                if (typeof data === 'string') {
                    return JSON.parse(data).result;
                }

                return data.result;
            }
        },
        load: 'currentOnly',
        fallbackLng: 'de-DE',
        lng: ['de-DE', 'en-US'],
        ns: ['web_portal', 'Faq'],
        defaultNS: 'web_portal',
        fallbackNS: 'Faq',
        debug: false,
        interpolation: {escapeValue: false}  // React already does escaping
    });

export default i18n;
