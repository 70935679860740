const Role = {
    ADMINISTRATOR: 'ADMINISTRATOR',
    CATERER: 'CATERER',
    CARRIER: 'CARRIER',
    INSTITUTION: 'INSTITUTION',
    PARENT: 'PARENT',
    SUPPORT: 'SUPPORT'
};

export default Role;
