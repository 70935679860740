import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import ConfirmCard from '../../common/component/ConfirmCard';

const StripePaymentSelectionAsk = () => {
    const [t] = useTranslation();
    const history = useHistory();

    return (
        <div className="container finance">
            <div className="center">
                <ConfirmCard title={t('Finance.PAYMENT_METHODS.ASK_TITLE')}
                             description={t('Finance.PAYMENT_METHODS.ASK_DESCRIPTION')}
                             primaryButtonTitle={t('Finance.PAYMENT_METHODS.CHARGE_NOW')}
                             onClickPrimary={() => history.push('/finances/one-time-payment')}
                             hidePrimaryIcon={true}
                             secondaryButtonTitle={t('Button.NOT_NOW')}
                             onClickSecondary={() => history.push('/onboarding-done')}
                             hideSecondaryIcon={true}
                />
            </div>
        </div>
    );
};

export default StripePaymentSelectionAsk;