import {Trans, useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import isEmail from 'validator/es/lib/isEmail';
import {Link, useHistory} from 'react-router-dom';
import Address from '../../common/component/Address';
import '../style/Registration.scss';
import Person from '../../common/component/form/Person';
import accountApi from '../api/accountApi';
import {showError, showMessage} from '../../message/action/messageActions';
import {useDispatch, useSelector} from 'react-redux';
import Checkbox from '../../common/component/form/Checkbox';
import LoginCredentials from "../../common/component/form/LoginCredentials";
import {checkValidation} from "../../common/util/ValidationUtil";

function ParentRegistration() {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const catererId = useSelector(state => state.caterer.catererId);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isValidated, setIsValidated] = useState(false);
    const [approvedTermsAndConditions, setApprovedTermsAndConditions] = useState(false);
    const [approvedPrivacyPolicy, setApprovedPrivacyPolicy] = useState(false);
    const [mailErrorCode, setMailErrorCode] = useState(null);
    const [account, setAccount] = useState({
        login: {
            emailAddress: null,
            password: null,
            passwordRepeat: null
        },
        person: {
            firstName: null,
            lastName: null,
            salutation: null
        },
        address: {
            street: null,
            houseNumber: null,
            additionalAddressInformation: null,
            zip: null,
            city: null
        }
    });
    const [validation, setValidation] = useState({
        login: {
            emailAddress: true,
            password: true,
            passwordRepeat: true
        },
        person: {
            firstName: true,
            lastName: true,
            salutation: true
        },
        address: {
            street: true,
            houseNumber: true,
            zip: true,
            city: true
        },
        approvedTermsAndConditions: true,
        approvedPrivacyPolicy: true
    });

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [isValidated, account, approvedPrivacyPolicy, approvedTermsAndConditions]);

    function validate() {
        const newValidation = {
            login: {
                emailAddress: !!account.login.emailAddress && isEmail(account.login.emailAddress),
                password: !!account.login.password,
                passwordRepeat: !!account.login.passwordRepeat && account.login.password === account.login.passwordRepeat
            },
            person: {
                firstName: !!account.person.firstName,
                lastName: !!account.person.lastName,
                salutation: !!account.person.salutation
            },
            address: {
                street: !!account.address.street,
                houseNumber: !!account.address.houseNumber,
                zip: !!account.address.zip,
                city: !!account.address.city
            },
            approvedTermsAndConditions: approvedTermsAndConditions,
            approvedPrivacyPolicy: approvedPrivacyPolicy
        };
        setValidation(newValidation);
        setIsValidated(true);

        return checkValidation(newValidation);
    }

    function handleLoginChange(field, value) {
        if (field === 'emailAddress') {
            setMailErrorCode(null);
        }
        setAccount({
            ...account,
            login: {
                ...account.login,
                [field]: value
            }
        });
    }

    function handleAccountChange(field, value) {
        setAccount({
            ...account,
            [field]: value
        });
    }

    function register() {
        if (validate()) {
            const accountToSave = {...account};
            accountToSave.language = currentLanguage;
            accountToSave.login.role = 'PARENT';

            setAccount(accountToSave);

            accountApi.register(accountToSave, catererId).then((res) => {
                    if (res.data.success) {
                        const createdAccount = res.data.result;
                        history.push('/login');
                        dispatch(showMessage('Registration.SUCCESS_FOR_PARENT',
                            {username: createdAccount.login.emailAddress}));
                    } else {
                        setMailErrorCode(res.errorCode);
                    }
                }
            );
        } else {
            dispatch(showError('Error.MISSING_PARAMETER'));
        }
    }

    return (
        <div className="container">
            <h1>
                {t('Registration.TITLE')}
            </h1>
            <div className={'mb-3 account-type account-type-small account-type-parent'}/>
            {
                <div className="card mb-3">
                    <div className="card-body">
                        <p>{t('Registration.DESCRIPTION_PARENT.HEADER')}</p>
                        <ol className="ordered-list">
                            <li>{t('Registration.DESCRIPTION_PARENT.STEP_1')}</li>
                            <li>{t('Registration.DESCRIPTION_PARENT.STEP_2')}</li>
                            <li>{t('Registration.DESCRIPTION_PARENT.STEP_3')}</li>
                            <li>
                                <Trans i18nKey="Registration.HAVE_QUESTIONS" components={{1: <a href='/faq-and-contact'/>}}/>
                            </li>
                        </ol>
                    </div>
                </div>
            }
            <div className="card mb-3">
                <div className="card-body">
                    <Person
                        person={account.person}
                        onChange={value => handleAccountChange('person', value)}
                        validation={validation.person}/>

                    <Address
                        address={account.address}
                        handleChange={value => handleAccountChange('address', value)}
                        validation={validation.address}
                    />
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    {t('Registration.CREDENTIALS')}
                </div>
                <div className="card-body">
                    <LoginCredentials
                        login={account.login}
                        onChange={handleLoginChange}
                        validation={validation.login}
                        mailErrorCode={mailErrorCode}/>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <Checkbox value={approvedTermsAndConditions}
                              onChange={(value) => setApprovedTermsAndConditions(value)}
                              label={<>
                                  {t('Registration.I_HEREBY_CONFIRM')}
                                  <Link to="/terms-and-conditions" target="_blank">{t('Registration.TERMS_AND_CONDITIONS')}</Link>
                              </>}
                              required={true}
                              isValid={validation.approvedTermsAndConditions}/>

                    <Checkbox value={approvedPrivacyPolicy}
                              onChange={(value) => setApprovedPrivacyPolicy(value)}
                              label={<>
                                  {t('Registration.I_HEREBY_CONFIRM')}
                                  <Link to="/privacy-policy" target="_blank">{t('Registration.PRIVACY_POLICY')}</Link>
                              </>}
                              required={true}
                              isValid={validation.approvedPrivacyPolicy}/>


                    <div className="button-footer">
                        <button
                            className="btn btn-secondary mr-3"
                            onClick={() => history.push('/register')}>
                            {t('Button.CANCEL')}
                        </button>
                        <button
                            data-cy="submit"
                            className="btn btn-primary"
                            onClick={register}>
                            {t('Button.REGISTER')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ParentRegistration;
