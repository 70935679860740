import {Dispatch} from 'react';
import {AppThunk} from '../reducer/reducers';

export const NAVIGATE = 'NAVIGATE';
export const UNSAVED_CHANGES = 'UNSAVED_CHANGES';

interface NavigateAction {
    type: typeof NAVIGATE;
    nextLocation: Location | null;
}

interface UnsavedChangesAction {
    type: typeof UNSAVED_CHANGES;
    hasUnsavedChanges: boolean;
}

export type PageLeaveActions = NavigateAction | UnsavedChangesAction

export const navigate = (location: Location | null): AppThunk => (dispatch: Dispatch<NavigateAction>) => {
    dispatch({
        type: NAVIGATE,
        nextLocation: location
    });
};

export const markChanges = (): AppThunk => (dispatch: Dispatch<UnsavedChangesAction>) => {
    dispatch({
        type: UNSAVED_CHANGES,
        hasUnsavedChanges: true
    });
};

export const unmarkChanges = (): AppThunk => (dispatch: Dispatch<UnsavedChangesAction>) => {
    dispatch({
        type: UNSAVED_CHANGES,
        hasUnsavedChanges: false
    });
};
