import {Reducer} from 'redux';
import {CONFIGURE_LOADER, HIDE_LOADER, LoaderActionTypes, LoaderState, SHOW_LOADER} from '../action/LoaderAction';
import {InitialAction} from './reducers';

function getNewWaitCount(waitCount: number, inc: -1 | 1): number {
    if (inc < 0 && waitCount <= 1) {
        return 0;
    }
    return waitCount + inc;
}

function increasedWaitCount(waitCount: number): number {
    return getNewWaitCount(waitCount, 1);
}

function decreasedWaitCount(waitCount: number): number {
    return getNewWaitCount(waitCount, -1);
}

export const initialLoaderState: LoaderState = {
    waitCount: 0,
    show: false,
    mightTakeMinutes: false
};

// reducer for everything that's loader related
const loaderReducer: Reducer<LoaderState, LoaderActionTypes | InitialAction> = (state = initialLoaderState, action = {type: ''}) => {
    if (action.type === SHOW_LOADER) {
        const newWaitCount = increasedWaitCount(state.waitCount);
        return {
            ...state,
            waitCount: newWaitCount,
            show: newWaitCount > 0
        };
    } else if (action.type === CONFIGURE_LOADER) {
        return {
            ...state,
            mightTakeMinutes: action.mightTakeMinutes
        };
    } else if (action.type === HIDE_LOADER) {
        const newWaitCount = decreasedWaitCount(state.waitCount);
        return {
            ...state,
            waitCount: newWaitCount,
            show: newWaitCount > 0,
            mightTakeMinutes: false
        };
    }

    return state;
};

export default loaderReducer;
