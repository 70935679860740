import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {DeliverGroupActions, SET_DELIVERY_GROUP_FILTER} from '../action/deliveryGroupActions';

export type DeliverGroupState = {
    filter: string,
}
const initialState: DeliverGroupState = {
    filter: ''
};

const deliverGroupReducer: Reducer<DeliverGroupState, DeliverGroupActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    if (action.type === SET_DELIVERY_GROUP_FILTER) {
        return {
            ...state,
            filter: action.filter
        };
    }
    return state;
};

export default deliverGroupReducer;
