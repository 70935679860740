import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import OrderOption from '../../../common/enums/OrderOption';
import {DAY_IN_MILLISECONDS, getCurrentMonday} from '../../../common/util/DateUtil';
import mealPlanManagementApi from '../../../mealplan/api/mealPlanManagementApi';
import {reloadBalance} from '../../../user/action/userActions';
import mealPlanApi from '../../api/mealPlanApi';
import subscriptionApi from '../../api/subscriptionApi';
import OrderTypeSwitch from '../OrderTypeSwitch';
import ChildOrderHeader from './ChildOrderHeader';
import ChildSingleOrder from './ChildSingleOrder';
import ChildSubscription from './ChildSubscription';

function ChildOrder() {
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const childId = match && match.params ? parseInt(match.params.childId) : null;
    const children = useSelector(state => state.user.account.children);
    const child = children.find(c => c.id === childId);

    const now = new Date();
    const threeWeeksFromNow = new Date(now.getTime() + 20 * DAY_IN_MILLISECONDS);

    const [selectedOrderOption, setSelectedOrderOption] = useState(OrderOption.SUBSCRIPTION);

    const [meals, setMeals] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [childMenuGroups, setChildMenuGroups] = useState([]);

    const [finishedLoading, setFinishedLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        loadSubscription();
        loadChildMenuGroups();
        dispatch(reloadBalance());
    }, []);

    useEffect(() => {
        loadMeals();
    }, [subscription]);

    if (!children || !children.length) {
        history.goBack();
        return null;
    }

    if (!child) {
        history.goBack();
        return null;
    }

    function loadChildMenuGroups() {
        mealPlanManagementApi.fetchMenuGroupsForChild(childId).then(res => {
            if (res.data.success) {
                setChildMenuGroups(res.data.result);
            }
        });
    }

    function loadSubscription() {
        subscriptionApi.findByChildId(child.id).then(response => {
            if (response.data.success) {
                setSubscription(response.data.result);
            }
        });
    }

    function loadMeals() {
        mealPlanApi.findByTimespanAndChildId(getCurrentMonday().toISOString().slice(0, 10),
            threeWeeksFromNow.toISOString().slice(0, 10), child.id).then(response => {
            if (response.data.success) {
                const loadedMeals = response.data.result;
                setMeals(loadedMeals);
                setFinishedLoading(true);

                if (!isEditMode && (
                    subscription ||
                    loadedMeals && !!loadedMeals.length &&
                    loadedMeals.some(meal => meal.menus.some(menu => !!menu.order)))) {
                    // User has ordered something before
                    setIsEditMode(true);
                }
            }
        });
    }

    function handleMealsChange(updatedMeals) {
        setMeals(updatedMeals);
        dispatch(reloadBalance());
    }

    function handleSubscriptionUpdate(updatedSubscription) {
        if (isEditMode) {
            dispatch(reloadBalance());
            setSubscription(updatedSubscription);
        } else {
            history.push('/');
        }
    }

    if (!finishedLoading) {
        return null;
    }

    if (child && child.group && child.group.institutionPlacesOrders) {
        return <>
            <ChildOrderHeader child={child}/>
            <div className="font-weight-bold">
                {t('Child.INSTITUTION_PLACES_ORDERS')}
            </div>
        </>;
    }

    const subscriptionMenuGroups = childMenuGroups.filter(menuGroup => menuGroup.deactivationDate === null);

    return (
        <div className="container">
            <ChildOrderHeader child={child}/>
            {
                !isEditMode &&
                <OrderTypeSwitch
                    selectedOption={selectedOrderOption}
                    onSelectOption={orderOption => setSelectedOrderOption(orderOption)}
                    catererId={child.group.catererId}
                />
            }
            {
                ((selectedOrderOption === OrderOption.SUBSCRIPTION || isEditMode) && !subscription && childMenuGroups) &&
                <ChildSubscription
                    child={child}
                    subscription={subscription}
                    onUpdate={handleSubscriptionUpdate}
                    isEditMode={isEditMode}
                    childMenuGroups={subscriptionMenuGroups}/>
            }
            {
                (selectedOrderOption === OrderOption.SINGLE || isEditMode) &&
                <ChildSingleOrder
                    child={child}
                    meals={meals}
                    menuGroups={childMenuGroups}
                    onUpdateMeals={handleMealsChange}
                    isEditMode={isEditMode}
                    isFirstElement={!!subscription}
                    loadMeals={loadMeals}
                />
            }
            {
                ((selectedOrderOption === OrderOption.SUBSCRIPTION || isEditMode) && subscription && childMenuGroups) &&
                <ChildSubscription
                    child={child}
                    subscription={subscription}
                    onUpdate={handleSubscriptionUpdate}
                    isEditMode={isEditMode}
                    childMenuGroups={subscriptionMenuGroups}/>
            }
            <div className="text-muted text-center mt-5 pt-3">
                {t('Order.DISCLAIMER')}
            </div>
        </div>
    );
}

export default ChildOrder;
