import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Icon from '../icons/Icon';
import InfoIcon from '../icons/InfoIcon';
import '../style/Wallet.scss';
import Price from './Price';

interface Props {
    headerTitle: string | JSX.Element;
    subtitle?: string | JSX.Element;
    balance?: number,
    displayBig: boolean;
    pricePositionOffset?: string;
}

function PageTitleWithWallet(props: Readonly<Props>) {
    const [t] = useTranslation();

    const [showWalletTooltip, setShowWalletTooltip] = useState(false);

    return (
        <div className={`row ${showWalletTooltip ? 'mb-2' : 'mb-4'} mb-sm-4`}>
            <div className="col-10 text-left">
                <h1 className="mb-0">
                    {props.headerTitle}
                    {
                        props.subtitle &&
                        <span className="page-title-subtitle">{props.subtitle}</span>
                    }
                </h1>
            </div>

            {
                props.balance !== null && props.balance !== undefined &&
                <>
                    <div className="col" onClick={() => setShowWalletTooltip(!showWalletTooltip)}>
                        <div className="wallet text-right no-gutters align-items-center wallet-big ml-auto">
                            <div className="ml-auto">
                                {t('Billing.BALANCE')}
                                <span className="wallet-text ml-2">
                                            <Icon src={<InfoIcon/>} title={t('Billing.TOOL_TIP')} className="d-none d-sm-inline"/>
                                            <Icon src={<InfoIcon/>} className="d-sm-none"/>
                                        </span>
                            </div>
                        </div>
                    </div>
                    {
                        showWalletTooltip &&
                        <div className="col-12 d-block d-sm-none mb-3 mb-sm-0">
                            <div className="tooltip-text text-left">{t('Billing.TOOL_TIP')}</div>
                        </div>
                    }

                    <div className="col-12" style={props.pricePositionOffset ? {marginTop: props.pricePositionOffset} : {}}>
                        <Price
                            price={props.balance}
                            displayBig={props.displayBig}
                        />
                    </div>
                </>
            }
        </div>

    );
}

export default PageTitleWithWallet;
