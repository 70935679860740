import {resetInstitutions} from '../../administration/action/administrationActions';
import {resetParentFinance} from '../../finance/actions/financeActions';
import {resetTaskboard} from '../../taskboard/action/taskActions';
import {updateUser} from '../../user/action/userActions';
import loginApi from '../../user/api/loginApi';

export function logOut(dispatch, onLoggedOut) {
    loginApi.logout().then(res => {
        window.sessionStorage.removeItem('api-key');
        dispatch(resetTaskboard());
        dispatch(resetParentFinance());
        dispatch(updateUser(null));
        dispatch(resetInstitutions());
        onLoggedOut();
    });
}
