import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {BillType} from '../../types/BillType';
import {TransactionTypeEnum} from '../../types/enums/transaction/TransactionTypeEnum';
import {BaseFilterType} from '../../types/filter/BaseFilterType';
import {TransactionFilter} from '../../types/filter/TransactionFilter';
import {TransactionType} from '../../types/TransactionType';
import {
    FinanceActions,
    RESET_TRANSACTION_TYPE_FILTER,
    SET_BILLS,
    SET_TRANSACTION_FILTER,
    SET_TRANSACTIONS,
    UPDATE_TRANSACTION_FILTER,
    UPDATE_TRANSACTION_TYPE_FILTER
} from '../actions/financeActions';

type FinanceState = {
    transactionFilter: TransactionFilter
    transactions: TransactionType[]
    totalTransactions: number
    billFilter: BaseFilterType
    bills: BillType[]
    totalBills: number
}
const initialState: FinanceState = {
    transactionFilter: {
        page: 0,
        pageSize: 10,
        bookingDateFrom: undefined,
        bookingDateTo: undefined,
        mealDateFrom: undefined,
        mealDateTo: undefined,
        name: undefined
    },
    billFilter: {
        page: 0,
        pageSize: 10
    },
    bills: [],
    transactions: [],
    totalTransactions: 0,
    totalBills: 0
};

const financeReducer: Reducer<FinanceState, FinanceActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case SET_TRANSACTION_FILTER: {
            if (!action.filter) {
                return {
                    ...state,
                    transactionFilter: {
                        page: 0,
                        pageSize: 10
                    }
                };
            }
            return {
                ...state,
                transactionFilter: action.filter
            };
        }
        case UPDATE_TRANSACTION_FILTER: {
            if (!action.filterKey) {
                return {
                    ...state,
                    transactionFilter: {
                        ...state.transactionFilter,
                        page: 0,
                        pageSize: 10
                    }
                };
            }
            return {
                ...state,
                transactionFilter: {
                    ...state.transactionFilter,
                    [action.filterKey]: action.filterValue
                }
            };
        }
        case UPDATE_TRANSACTION_TYPE_FILTER: {
            return {
                ...state,
                transactionFilter: {
                    ...state.transactionFilter,
                    transactionType: action.transactionType,
                    creditType: action.creditType,
                    mealDateFrom: action.transactionType === TransactionTypeEnum.DEBIT ? state.transactionFilter.mealDateFrom : undefined,
                    mealDateTo: action.transactionType === TransactionTypeEnum.DEBIT ? state.transactionFilter.mealDateTo : undefined
                }
            };
        }
        case RESET_TRANSACTION_TYPE_FILTER: {
            return {
                ...state,
                transactionFilter: {
                    ...state.transactionFilter,
                    transactionType: undefined,
                    creditType: undefined
                }
            };
        }
        case SET_TRANSACTIONS: {
            if (!action.transactions || !action.total) {
                return {
                    ...state,
                    transactions: [],
                    totalTransactions: 0
                };
            }
            return {
                ...state,
                transactions: action.transactions,
                totalTransactions: action.total
            };
        }
        case SET_BILLS: {
            if (!action.bills || !action.total) {
                return {
                    ...state,
                    bills: [],
                    totalBills: 0,
                    billFilter: action.filter ? action.filter : {
                        page: 0,
                        pageSize: 10
                    }
                };
            }
            return {
                ...state,
                bills: action.bills,
                totalBills: action.total,
                billFilter: action.filter
            };
        }
    }
    return state;
};

export default financeReducer;
