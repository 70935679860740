import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DefaultState} from '../../common/reducer/reducers';
import {currencyFormatter} from '../../common/util/CurrencyUtil';
import {Child} from '../../types/Child';

type Props = {
    child: Child,
    onChange: (value: number) => void,
    showValidationError: boolean
}

function OnSiteOrderLimits(props: Props) {

    const [t] = useTranslation();
    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const amountFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);
    const PREDEFINED_OPTIONS = [5, 10, 20, 0];

    return (
        <div className="card mb-3">
            <div className="card-header">
                {t('Child.DAILY_LIMIT_TITLE')}
            </div>
            <div className="card-body row">
                {
                    PREDEFINED_OPTIONS.map((option) =>
                    <div className="col-12 col-sm-6 col-lg-3 px-4 mb-2" key={option}>
                        <div
                            className={`shadow-card-btn ${props.child.dailyOnSiteOrderLimit === option ? 'active' : ''}`}
                            onClick={() => props.onChange(option)}
                        >
                            {option > 0 ? amountFormatter.format(option) : t('Child.NO_ON_SITE_ORDER_ALLOWED')}
                        </div>
                    </div>
                    )
                }
                {
                    props.showValidationError &&
                    <div className="invalid-feedback d-block">
                        {t('Validation.MISSING_CHOICE_FROM_OPTIONS')}
                    </div>
                }
            </div>
        </div>
    );
}

export default OnSiteOrderLimits;
