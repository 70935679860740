import React from 'react';
import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PATH_SUPPORT_PARENTS} from '../../../AppRoutes';
import HomeIcon from '../../icons/HomeIcon';
import MultiplePersonIcon from '../../icons/MultiplePersonIcon';
import PersonIcon from '../../icons/PersonIcon';
import ServingIcon from '../../icons/ServingIcon';
import IconNavLink from './IconNavLink';

function NavigationSupport() {
    const [t] = useTranslation();

    return (
        <Nav className="ml-4 ml-lg-0">
            <IconNavLink to={PATH_SUPPORT_PARENTS} keyValue={PATH_SUPPORT_PARENTS} icon={<MultiplePersonIcon/>}>{t('Navigation.PARENTS')}</IconNavLink>
            <IconNavLink to="/support/institutions" keyValue="/support/institutions" icon={<HomeIcon/>}>{t('Navigation.INSTITUTION_ADMINISTRATION')}</IconNavLink>
            <IconNavLink to="/support/caterers" keyValue="/support/caterers" icon={<ServingIcon/>}>{t('Navigation.CATERERS')}</IconNavLink>
            <IconNavLink to="/support/profile" keyValue="/support/profile" icon={<PersonIcon/>}>{t('Navigation.PROFILE')}</IconNavLink>
        </Nav>
    );
}

export default NavigationSupport;
