import React, {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import DateInput from '../../common/component/form/DateInput';
import Toggle from '../../common/component/Toggle';
import {getNextDay, getPrevDay, max} from '../../common/util/DateUtil';
import {capitalizeFirstLetter} from '../../common/util/StringUtils';

type Props = {
    startDate: string | Date,
    endDate?: string | Date,
    pauseStartDate?: string | Date,
    pauseEndDate?: string | Date,
    initialStartDate: string | Date,
    minOrderableDate: string | Date,
    isSubscriptionPresent?: boolean,
    handleStartDateChanged: (startDate: Date) => void,
    handleEndDateChanged: (endDate: Date) => void,
    handlePauseStartDateChanged: (pauseStartDate: Date) => void,
    handlePauseEndDateChanged: (pauseEndDate: Date) => void,
    handleRemovePause: () => void,
    isStartDateValid: boolean,
    isEndDateValid: boolean,
    isPauseStartDateValid: boolean,
    isPauseEndDateValid: boolean,
    isInstitution?: boolean,
    isDescriptionEnabled?: boolean,
    startDateEnabled?: boolean
}

function SubscriptionDateDurationPicker({
                                            startDate,
                                            endDate,
                                            pauseStartDate,
                                            pauseEndDate,
                                            initialStartDate,
                                            minOrderableDate,
                                            isSubscriptionPresent,
                                            handleStartDateChanged,
                                            handleEndDateChanged,
                                            handlePauseStartDateChanged,
                                            handlePauseEndDateChanged,
                                            handleRemovePause,
                                            isStartDateValid,
                                            isEndDateValid,
                                            isPauseStartDateValid,
                                            isPauseEndDateValid,
                                            isInstitution = false,
                                            isDescriptionEnabled = true,
                                            startDateEnabled = true
                                        } : Readonly<Props>) {
    const [t] = useTranslation();
    const [showPauseDates, setShowPauseDates] = useState(!!pauseStartDate);

    const handleShowPauseDatesChanged = useCallback((enabled) => {
        setShowPauseDates(enabled);
        if (!enabled) {
            handleRemovePause();
        }
    }, [handleRemovePause]);

    function calculateMinPauseStartDate(): Date {
        return max(new Date(startDate), new Date(minOrderableDate));
    }

    function calculateMaxPauseStartDate(): Date | null {
        if (pauseEndDate) {
            return new Date(pauseEndDate);
        }

        if (endDate) {
            return new Date(endDate);
        }

        return null;
    }

    return (
        <div className="col-12 col-md-5 order-2 order-md-1">
            {
                isDescriptionEnabled &&
                <>
                    {
                        isSubscriptionPresent ?
                            <p>{t(`Subscription.TIME_RANGE${isInstitution ? '_INSTITUTION' : ''}`)}</p>
                            :
                            <p>{t(`Subscription.DESCRIPTION${isInstitution ? '_INSTITUTION' : ''}`)}</p>
                    }
                </>
            }
            <div className="row">
                <div className="col-sm-6">
                    <DateInput
                        isValid={isStartDateValid}
                        required={true}
                        label={capitalizeFirstLetter(t('Period.FROM'))}
                        value={new Date(startDate)}
                        onChange={handleStartDateChanged}
                        errorMessage={t('Error.INVALID_PARAMETER')}
                        readonly={!startDateEnabled}
                        minDate={new Date(initialStartDate)}
                        maxDate={endDate ? getPrevDay(endDate) : null}
                    />
                </div>
                <div className="col-sm-6">
                    <DateInput
                        minDate={getNextDay(startDate)}
                        isValid={isEndDateValid}
                        label={capitalizeFirstLetter(t('Period.TO'))}
                        value={endDate ? new Date(endDate) : null}
                        onChange={handleEndDateChanged}
                        errorMessage={t('Error.INVALID_PARAMETER')}
                        isClearable={true}
                    />
                </div>
            </div>

            {
                !isInstitution &&
                <div className={'mt-2 ' + (showPauseDates ? 'mb-1' : 'mb-3')}>
                    <Toggle
                        toggled={showPauseDates}
                        label={t('Subscription.PAUSE_HEADER')}
                        onClick={handleShowPauseDatesChanged}
                    />
                </div>
            }
            {
                showPauseDates &&
                <>
                    <div className="mt-1 mb-1">
                        <Trans
                            i18nKey={'Subscription.PAUSE_INFO'}
                            components={{b: <b/>}}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <DateInput
                                required={!!pauseEndDate}
                                label={capitalizeFirstLetter(t('Period.FROM'))}
                                value={pauseStartDate ? new Date(pauseStartDate) : null}
                                onChange={handlePauseStartDateChanged}
                                isValid={isPauseStartDateValid}
                                errorMessage={t('Error.INVALID_PARAMETER')}
                                minDate={calculateMinPauseStartDate()}
                                maxDate={calculateMaxPauseStartDate()}
                                isClearable={true}
                            />
                        </div>
                        <div className="col-sm-6 mb-2">
                            <DateInput
                                required={!!pauseStartDate}
                                label={capitalizeFirstLetter(t('Period.TO'))}
                                value={pauseEndDate ? new Date(pauseEndDate) : null}
                                onChange={handlePauseEndDateChanged}
                                isValid={isPauseEndDateValid}
                                errorMessage={t('Error.INVALID_PARAMETER')}
                                minDate={pauseStartDate ? new Date(pauseStartDate) : null}
                                maxDate={endDate ? new Date(endDate) : null}
                                isClearable={true}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default SubscriptionDateDurationPicker;
