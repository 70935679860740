import React from 'react';
import ReactDOM from 'react-dom';
import 'fast-text-encoding';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/styles.scss';
import {Provider} from 'react-redux';
import App from './App';
import {store} from './store';
import './i18n/i18n';
import {httpClient} from './common/api/HttpClient';
import {setGlobalRequestInterceptor, setGlobalResponseInterceptor} from './common/api/HttpInterceptors';

// transfers sessionStorage from one tab to another - https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs
const sessionStorageTransfer = function (event: StorageEvent) {
    if (!event && window.event) {
        event = window.event as StorageEvent; // ie suq
    }

    if (!event.newValue) {
        return;// do nothing if no value to work with
    }

    if (event.key === 'getSessionStorage') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
            sessionStorage.setItem(key, data[key]);
        }
    }
};

// listen for changes to localStorage
if (window.addEventListener) {
    window.addEventListener('storage', sessionStorageTransfer, false);
} else {
    // @ts-ignore
    window.attachEvent('onstorage', sessionStorageTransfer);
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
    localStorage.setItem('getSessionStorage', 'eventTrigger');
    localStorage.removeItem('getSessionStorage');
}

setGlobalRequestInterceptor(httpClient);
setGlobalResponseInterceptor(httpClient);

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('root')
);
