import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {hideLoader, showLoader, setLoaderHint} from '../action/LoaderAction';
import ConfirmCard from './ConfirmCard';
import Checkbox from './form/Checkbox';
import LoginCredentials from './form/LoginCredentials';

function PlaygroundArea() {
    const dispatch = useDispatch();

    const [emailAddress, setEmailAddress] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordRepeat, setPasswordRepeat] = useState<string>();
    const [submittedEmailAddress, setSubmittedEmailAddress] = useState<string>();
    const [submittedPassword, setSubmittedPassword] = useState<string>();
    const [submittedPasswordRepeat, setSubmittedPasswordRepeat] = useState<string>();

    const [showEmailAddress, setShowEmailAddress] = useState<boolean | null>(true);
    const [showPassword, setShowPassword] = useState<boolean | null>(true);
    const [isEmailReadonly, setIsEmailReadonly] = useState<boolean | null>(false);
    const [isPasswordReadonly, setIsPasswordReadonly] = useState<boolean | null>(false);

    const handleChange = useCallback((key: string, value: string) => {
        switch (key) {
            case 'emailAddress':
                setEmailAddress(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'passwordRepeat':
                setPasswordRepeat(value);
                break;
        }
    }, []);

    const handleClick = useCallback((event) => {
        event.preventDefault();
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    const handleClickForLongAction = useCallback((event) => {
        event.preventDefault();
        dispatch(setLoaderHint());
        dispatch(showLoader());
        setTimeout(() => {
            dispatch(hideLoader());
        }, 1000 * 10);
    }, [dispatch]);

    const submitCredentials = useCallback(() => {
        setSubmittedEmailAddress(emailAddress);
        setSubmittedPassword(password);
        setSubmittedPasswordRepeat(passwordRepeat);
    }, [emailAddress, password, passwordRepeat]);

    const resetCredentials = useCallback(() => {
        setSubmittedEmailAddress(undefined);
        setSubmittedPassword(undefined);
        setSubmittedPasswordRepeat(undefined);
    }, []);

    return (
        <div className="container">
            This is the playground area. Have fun!
            <div className="mt-3 mb-4">
                <button className="btn btn-primary" onClick={handleClick}>Loader!</button>
                <button className="btn btn-secondary ml-2" onClick={handleClickForLongAction}>Trigger long action</button>
            </div>

            <LoginCredentials
                login={{emailAddress, password, passwordRepeat}}
                onChange={handleChange}
                validation={{catererId: true, emailAddress: true, password: true, passwordRepeat: true}}
                onSubmit={submitCredentials}
                showEmail={showEmailAddress ?? undefined}
                showPassword={showPassword ?? undefined}
                emailReadOnly={isEmailReadonly ?? undefined}
                readOnly={isPasswordReadonly ?? undefined}
            />
            <div className="d-flex flex-nowrap">
                <Checkbox label="show-email-address" value={showEmailAddress} onChange={setShowEmailAddress}/>&nbsp;&nbsp;
                <Checkbox label="show-password" value={showPassword} onChange={setShowPassword}/>&nbsp;&nbsp;
                <Checkbox label="email read-only" value={isEmailReadonly} onChange={setIsEmailReadonly}/>&nbsp;&nbsp;
                <Checkbox label="passwordread-only" value={isPasswordReadonly} onChange={setIsPasswordReadonly}/>
            </div>
            <button className="btn btn-secondary" onClick={resetCredentials}>Reset</button>&nbsp;&nbsp;
            <button className="btn btn-primary" onClick={submitCredentials}>Submit!</button>

            <div className="mt-3">
                Submitted:<br/>
                Email Address: {submittedEmailAddress}<br/>
                Password: {submittedPassword}<br/>
                Repeat: {submittedPasswordRepeat}
            </div>

            <div className="my-3">
                <ConfirmCard
                    title="Test für ConfirmCard mit etwas längerem Titel als gewöhnlich bla bla foo bar"
                    description="Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard. Das ist der Test für das Layout der ConfirmCard."
                    primaryButtonTitle="Go Primary!"
                    onClickPrimary={() => console.log('You clicked primary')}
                    showCheckmark={true}
                    secondaryButtonTitle="Go Secondary!"
                    onClickSecondary={() => console.log('You clicked secondary')}
                />
            </div>

        </div>
    );
}

export default PlaygroundArea;
