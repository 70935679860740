import {Reducer} from 'redux';
import {InitialAction} from '../../common/reducer/reducers';
import {CarrierType} from '../../types/CarrierType';
import {CatererType} from '../../types/caterer/CatererType';
import {InstitutionType} from '../../types/InstitutionType';
import {MealPricesType} from '../../types/MealPricesType';
import {ParentType} from '../../types/ParentType';
import {AdministrationActions, SET_CARRIERS, SET_CATERERS, SET_INSTITUTIONS, SET_MEAL_PRICES, SET_PARENTS} from '../action/administrationActions';

export type AdministrationState = {
    carriers: CarrierType[],
    caterers: CatererType[],
    institutions: InstitutionType[],
    parents: ParentType[]
    mealPrices?: MealPricesType[]
}
const initialState: AdministrationState = {
    carriers: [],
    caterers: [],
    institutions: [],
    parents: []
};

const administrationReducer: Reducer<AdministrationState, AdministrationActions | InitialAction> = (state = initialState, action = {type: ''}) => {
    switch (action.type) {
        case SET_CARRIERS: {
            if (!action.carriers) {
                return {
                    ...state,
                    carriers: []
                };
            }
            return {
                ...state,
                carriers: action.carriers
            };
        }
        case SET_INSTITUTIONS: {
            if (!action.institutions) {
                return {
                    ...state,
                    institutions: []
                };
            }
            return {
                ...state,
                institutions: action.institutions
            };
        }
        case SET_CATERERS: {
            if (!action.caterers) {
                return {
                    ...state,
                    caterers: []
                };
            }
            return {
                ...state,
                caterers: action.caterers
            };
        }
        case SET_MEAL_PRICES: {
            if (!action.mealPrices) {
                return {
                    ...state,
                    mealPrices: []
                };
            }
            return {
                ...state,
                mealPrices: action.mealPrices
            };
        }
        case SET_PARENTS: {
            if (!action.parents) {
                return {
                    ...state,
                    parents: []
                };
            }
            return {
                ...state,
                parents: action.parents
            };
        }
    }
    return state;
};

export default administrationReducer;
