import React from 'react';

interface IconProps {
    size?: number;
}

const MultiplePersonIcon = ({
                                size = 1
                            }: IconProps) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M3.5 4C3.5 2.61929 4.61929 1.5 6 1.5C7.38071 1.5 8.5 2.61929 8.5 4C8.5 5.38071 7.38071 6.5 6 6.5C4.61929 6.5 3.5 5.38071 3.5
                   4ZM6 0C3.79086 0 2 1.79086 2 4C2 6.2091 3.79086 8 6 8C8.2091 8 10 6.2091 10 4C10 1.79086 8.2091 0 6 0ZM13.5 5C13.5 4.17157
                   14.1716 3.5 15 3.5C15.8284 3.5 16.5 4.17157 16.5 5C16.5 5.82843 15.8284 6.5 15 6.5C14.1716 6.5 13.5 5.82843 13.5 5ZM15 2C13.3431
                   2 12 3.34315 12 5C12 6.6569 13.3431 8 15 8C16.6569 8 18 6.6569 18 5C18 3.34315 16.6569 2 15 2ZM12.2484 15.0377C12.9507 15.3232
                   13.8517 15.5 15.001 15.5C17.2833 15.5 18.5867 14.8027 19.2979 13.9421C19.643 13.5244 19.8186 13.1027 19.9077 12.7795C19.9521
                   12.6181 19.9754 12.48 19.9875 12.377C19.9936 12.3254 19.997 12.2821 19.9988 12.2487C19.9997 12.232 20.0003 12.2177 20.0006
                   12.2059L20.0009 12.1903L20.001 12.1839V12.1811V12.1786C20.001 10.9754 19.0256 10 17.8224 10H12.1796C12.1521 10 12.1248 10.0005
                   12.0977 10.0015C12.4916 10.4126 12.7787 10.927 12.914 11.5H17.8224C18.194 11.5 18.4958 11.7986 18.5009 12.1689C18.5006 12.1746
                   18.4997 12.1855 18.4979 12.2011C18.4934 12.2387 18.4835 12.3015 18.4615 12.3812C18.4177 12.5402 18.3277 12.7613 18.1416
                   12.9865C17.7903 13.4116 16.9687 14 15.001 14C14.0209 14 13.3252 13.854 12.8302 13.655C12.7231 14.0551 12.5452 14.5378 12.2484
                   15.0377ZM2.25 10C1.00736 10 0 11.0074 0 12.25V12.5011V12.5022L1.00136e-05 12.5048L7.00951e-05 12.5111L0.000349998
                   12.5277C0.000639998 12.5406 0.00116999 12.5571 0.00209999 12.5771C0.00395999 12.6169 0.00744991 12.6705 0.0139799
                   12.7358C0.0270099 12.8661 0.05233 13.045 0.10165 13.2564C0.19995 13.6776 0.39654 14.2404 0.79183 14.8051C1.61066 15.9749
                   3.17178 17 6 17C8.8282 17 10.3893 15.9749 11.2082 14.8051C11.6035 14.2404 11.8001 13.6776 11.8983 13.2564C11.9477 13.045
                   11.973 12.8661 11.986 12.7358C11.9926 12.6705 11.996 12.6169 11.9979 12.5771C11.9988 12.5571 11.9994 12.5406 11.9996
                   12.5277L11.9999 12.5111L12 12.5048V12.5022V12.25C12 11.0074 10.9926 10 9.75 10H2.25ZM1.50047 12.5072L1.5 12.4947V12.25C1.5 11.8358
                   1.83579 11.5 2.25 11.5H9.75C10.1642 11.5 10.5 11.8358 10.5 12.25V12.4946L10.4995 12.5072C10.4988 12.5222 10.4972 12.5493 10.4935
                   12.5865C10.486 12.6612 10.4703 12.7753 10.4376 12.9155C10.3718 13.1974 10.2403 13.5721 9.9793 13.9449C9.4857 14.6501 8.4218 15.5 6
                   15.5C3.57822 15.5 2.51434 14.6501 2.02067 13.9449C1.75971 13.5721 1.62818 13.1974 1.56241 12.9155C1.5297 12.7753 1.514 12.6612
                   1.50653 12.5865C1.50281 12.5493 1.50117 12.5222 1.50047 12.5072Z"/>
        </svg>
    );
};

export default MultiplePersonIcon;
