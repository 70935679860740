import React, {ReactElement, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import SlideDown from 'react-slidedown';
import ReactTooltip from 'react-tooltip';
import TaskStatus from '../../taskboard/component/TaskStatus';
import {TaskStateEnum} from '../../types/enums/TaskStateEnum';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';
import Icon from '../icons/Icon';
import '../style/SlideDownCard.scss';

interface Props {
    title?: string | ReactElement,
    onlyMobile?: boolean,
    children?: React.ReactNode,
    className?: string,
    closed?: boolean,
    onToggle?: (toggle: boolean) => void,
    stateLabel?: string,
    state?: TaskStateEnum,
    scrollIntoViewOnOpen?: boolean
}

function SlideDownCard(props: Readonly<Props>) {
    const [t] = useTranslation();
    const headerRef = useRef<HTMLInputElement>(null);
    const bodyRef = useRef<HTMLInputElement>(null);

    function toggle() {
        if (props.onToggle) {
            props.onToggle(!props.closed);
            if (props.scrollIntoViewOnOpen && props.closed) {
                setTimeout(() => {
                    window.scrollTo({
                        // @ts-ignore
                        top: headerRef.current.getBoundingClientRect().top + window.scrollY - 100,
                        behavior: 'smooth'
                    });
                }, 501);
            }
        }
    }

    return (
        <div className={`${props.className === undefined ? '' : props.className} card slidedown-card`} ref={headerRef}>
            <div className={`card-header clickable ${props.closed ? 'slidedown-card-closed' : 'slidedown-card-open'}`}  onClick={toggle}>
                <div className={'float-right' + (props.onlyMobile ? ' d-md-none' : '')}>
                    <Icon
                        className="icon-neutral"
                        src={props.closed ? <ChevronDownIcon/> : <ChevronUpIcon/>}
                        title={t(props.closed ? 'Button.OPEN' : 'Button.CLOSE')}
                    />
                </div>
                <div className="row">
                    <div className="d-inline-flex w-100 mr-2">
                        {props.title}
                        {
                            props.closed && props.state &&
                            <span className="card-title-status ml-3">
                                <p className="mb-0" data-tip={props.stateLabel}><TaskStatus state={props.state}/></p><ReactTooltip/>
                            </span>
                        }
                    </div>
                </div>
            </div>
            <SlideDown closed={props.closed} className={props.onlyMobile ? 'only-mobile' : undefined}>
                <div className="card-body" ref={bodyRef}>
                    {props.children}
                </div>
            </SlideDown>
        </div>
    );
}

SlideDownCard.defaultProps = {
    scrollIntoViewOnOpen: false
};

export default SlideDownCard;
