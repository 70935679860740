import i18n from 'i18next';
import React, {Suspense, useCallback, useEffect, useState} from 'react';
import {I18nextProvider} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useMount} from 'react-use';
import AppRoutes from './AppRoutes';
import {setCatererNews, setCatererTimeManagement, updateCatererBasics, updateCatererIcon, updateCatererLogo} from './caterer/action/catererActions';
import publicCatererApi from './caterer/api/publicCatererApi';
import {CatererBinaryType} from './caterer/enums/catererBinaryType';
import {setDomainType} from './common/action/domainContextActions';
import {hideLoader, showLoader} from './common/action/LoaderAction';
import publicDomainContextApi from './common/api/publicDomainContextApi';
import Consent from './common/component/consent/Consent';
import Footer from './common/component/Footer';
import HandoutHeader from './common/component/header/HandoutHeader';
import Header from './common/component/header/Header';
import Loader from './common/component/Loader';
import ScrollToTop from './common/component/ScrollToTop';
import SuspenseLoader from './common/component/SuspenseLoader';
import Gabel1DomainType from './common/enums/Gabel1DomainType';
import {DefaultState} from './common/reducer/reducers';
import {setDynamicPrimaryColorHex, setDynamicPrimaryColorHsl} from './common/util/ColorUtil';
import Dummy from './Dummy';
import {fetchFinanceConfig} from './finance/actions/financeConfigActions';
import {checkState} from './user/action/userActions';
import {useOcWebSocket} from './websocket/OcWebSocketSupport';

function App() {
    const dispatch = useDispatch();
    const domainType = useSelector((state: DefaultState) => state.domainContext.domainType);
    const role = useSelector((state: DefaultState) => state.user.login ? state.user.login.role : null);
    const checkedState = useSelector((state: DefaultState) => state.user.checkedState);
    const [loadingDone, setLoadingDone] = useState(false);

    useMount(() => {
        setDynamicPrimaryColorHsl(document.documentElement, 0, 0, 0);
    });

    const initializeSupport = useCallback(() => {
        // Show default images:
        dispatch(updateCatererLogo());
        dispatch(updateCatererIcon());

        publicDomainContextApi.getSupportStylingConfig().then(response => {
            if (response.data.success) {
                setDynamicPrimaryColorHex(document.documentElement, response.data.result.primaryColor);
            }
        });
    }, [dispatch]);

    const initializeCaterer = useCallback((catererBasics) => {
        const catererId = catererBasics.id;
        dispatch(updateCatererBasics(
            catererId,
            catererBasics.companyName,
            catererBasics.companyShortName,
            catererBasics.menuLimit,
            catererBasics.displayName));

        publicCatererApi.findCatererBinaryResources(catererId, [CatererBinaryType.LOGO, CatererBinaryType.ICON]).then(resourcesResponse => {
            if (resourcesResponse.data.success) {
                dispatch(updateCatererLogo(resourcesResponse.data.result[CatererBinaryType.LOGO]?.binary));
                dispatch(updateCatererIcon(resourcesResponse.data.result[CatererBinaryType.ICON]?.binary));
            }
        });
        publicCatererApi.findPublicCatererStyling(catererId).then(catererStylingResponse => {
            if (catererStylingResponse.data.success) {
                const primaryColorHex = catererStylingResponse.data.result.primaryColor;
                setDynamicPrimaryColorHex(document.documentElement, primaryColorHex);
            }
        });
        publicCatererApi.findCatererNews(catererId).then(newsResponse => {
            if (newsResponse.data.success && newsResponse.data.result) {
                dispatch(setCatererNews(newsResponse.data.result));
            }
        });
        publicCatererApi.findCatererTimeManagements(catererId).then(catererTimeManagementResponse => {
            if (catererTimeManagementResponse.data.success && catererTimeManagementResponse.data.result.length > 0) {
                dispatch(setCatererTimeManagement(catererTimeManagementResponse.data.result));
            }
        });
    }, [dispatch]);

    /* The Http interceptors and the Messages compontent are not yet active in this place. They are located inside AppRoutes.
     * For this reason, showLoader must be called manually and no automatic error message is shown if no caterer is found. */
    useEffect(() => {
        setLoadingDone(false);
        dispatch(showLoader());
        publicDomainContextApi.getDomainContext().then(res => {
            setLoadingDone(true);
            if (res.data.success) {
                const domainType = res.data.result.domainType;
                dispatch(setDomainType(domainType));

                if (domainType === Gabel1DomainType.SUPPORT) {
                    initializeSupport();
                } else if (domainType === Gabel1DomainType.CATERER) {
                    initializeCaterer(res.data.result.caterer);
                }
            } else {
                dispatch(setDomainType(null));
            }
        })
        .finally(() => dispatch(hideLoader()));
    }, [initializeSupport, initializeCaterer, dispatch]);

    useEffect(() => {
        dispatch(checkState());
    }, [dispatch]);

    useEffect(() => {
        if (role === 'PARENT') {
            dispatch(fetchFinanceConfig());
        }
    }, [role, dispatch]);

    useOcWebSocket();

    if (!checkedState) {
        return null;
    }

    return (
        <I18nextProvider i18n={i18n}>
            <Suspense fallback={<SuspenseLoader/>}>
                <Loader/>
                {
                    loadingDone &&
                    <BrowserRouter basename={process.env.root}>
                        <ScrollToTop/> {
                        !domainType ?
                            <Dummy/> :
                            <>
                                <Switch>
                                    <Route path="/handout/:institutionId" key="/handout/:institutionId" exact render={() => <HandoutHeader/>}/>
                                    <Route exact={false}>
                                        <Header/>
                                    </Route>
                                </Switch>
                                <AppRoutes/>
                                <Footer/>
                                {
                                    domainType === Gabel1DomainType.CATERER &&
                                    <Consent/>
                                }
                            </>
                    }
                    </BrowserRouter>
                }
            </Suspense>
        </I18nextProvider>
    );
}

export default App;
