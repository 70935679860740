import React from 'react';
import {useTranslation} from 'react-i18next';
import WelfareDepartmentType from '../../../common/enums/WelfareDepartmentType';

type Props = {
    selectedDepartment: string;
    onClick: (department: keyof typeof WelfareDepartmentType) => void;
    label?: string;
    displayError?: boolean;

}

const DepartmentSelectionButtons = ({selectedDepartment, onClick, label, displayError}: Readonly<Props>) => {
    const [t] = useTranslation();

    return (
        <div className="form-group">
            <label>
                {`${label ? label : t('Welfare.DEPARTMENT_IN_CHARGE')} *`}
            </label>
            <div className="row ml-0">
                {
                    (Object.keys(WelfareDepartmentType) as Array<keyof typeof WelfareDepartmentType>).map(type => {
                        return (
                            <button key={type}
                                    className={`btn ${type === selectedDepartment ? 'btn-primary' : 'btn-secondary'} mr-2 mt-2`}
                                    onClick={() => onClick(type)}>
                                {t(`Welfare.${WelfareDepartmentType[type]}`)}
                            </button>
                        );
                    })
                }
            </div>
            {
                displayError &&
                <div className="alert-danger">
                    {t('Validation.REQUIRED')}
                </div>
            }
        </div>
    );
};

export default DepartmentSelectionButtons;
