export function getFileExtension(file) {
    if (file) {
        const extensionChar = file.charAt(0);
        switch (extensionChar) {
            case 'i': {
                return 'png';
            }
            case '/': {
                return 'jpg';
            }
        }
    }
    return 'jpeg';
}
