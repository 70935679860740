import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DefaultState} from '../reducer/reducers';
import '../style/Loader.scss';

function Loader() {
    const [t] = useTranslation();
    const show = useSelector((state: DefaultState) => state.loader.show);
    const showHint = useSelector((state: DefaultState) => state.loader.mightTakeMinutes);

    if (!show) {
        return null;
    }

    return (
        <div className="overlay">
            <div className="loader-container">
                <span className="sr-only">{t('Common.LOADING')}</span>
                <div className="loader-roller">
                    {/* All divs are necessary , each div represents a dot of the loader */}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {
                    showHint &&
                    <div className="mt-3">{t('Common.LOADING_MIGHT_TAKE_SOME_MINUTES')}</div>
                }
            </div>
        </div>
    );
}

export default Loader;
