import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CellProps, Column} from 'react-table';
import BackTo from '../../common/component/BackTo';
import DateInput from '../../common/component/form/DateInput';
import Dropdown, {DropdownOption} from '../../common/component/form/Dropdown';
import Price from '../../common/component/Price';
import SearchField from '../../common/component/SearchField';
import OcTable from '../../common/component/table/OcTable';
import {DefaultState} from '../../common/reducer/reducers';
import {formatDateAndTime} from '../../common/util/DateUtil';
import {callOnEnter} from '../../common/util/FormUtil';
import {CreditType} from '../../types/enums/transaction/CreditType';
import {TransactionTypeEnum} from '../../types/enums/transaction/TransactionTypeEnum';
import {TransactionType} from '../../types/TransactionType';
import {
    resetTransactionTypeFilter,
    searchTransactions,
    setTransactionFilter,
    updateTransactionFilter,
    updateTransactionTypeFilter
} from '../actions/financeActions';
import '../style/Finance.scss';

function TransactionHistory() {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    const currentLanguage = useSelector((state: DefaultState) => state.i18n.currentLanguage);
    const filter = useSelector((state: DefaultState) => state.finance.transactionFilter);
    const transactions = useSelector((state: DefaultState) => state.finance.transactions);
    const total = useSelector((state: DefaultState) => state.finance.totalTransactions);
    const children = useSelector((state: DefaultState) => state.user.account?.children);
    const inactiveChildren = useSelector((state: DefaultState) => state.user.account?.inactiveChildren);

    const search = useCallback(({pageIndex}) => {
        const newFilter = {
            ...filter,
            page: pageIndex ?? 0
        };
        dispatch(updateTransactionFilter('page', newFilter.page));
        dispatch(searchTransactions(newFilter));
    }, [filter, dispatch]);

    const updateFilter = useCallback((field, value) => {
        dispatch(updateTransactionFilter(field, value));
    }, [dispatch]);

    const resetFilter = useCallback(() => {
        const defaultFilter = { page: 0, pageSize: 10 };
        dispatch(setTransactionFilter(defaultFilter));
        dispatch(searchTransactions(defaultFilter));
    }, [dispatch]);

    const getChildName = useCallback(transaction => {
        if (TransactionTypeEnum.CREDIT === transaction.transactionType) {
            return '';
        }

        let child = children?.find(aChild => aChild.id === transaction.childId);
        if (!child) {
            child = inactiveChildren?.find(aChild => aChild.id === transaction.childId);
        }
        return child ? child.firstName : '';
    }, [children, inactiveChildren]);

    const getMenuDate = useCallback(transaction => {
        if (TransactionTypeEnum.CREDIT === transaction.transactionType) {
            return '';
        }

        return formatDateAndTime(transaction.mealDate, currentLanguage, true, false);
    }, [currentLanguage]);

    const transactionTypeOptions = useMemo<Array<DropdownOption>>(() => {
        const result = new Array<DropdownOption>();
        result.push({value: 'ORDER', label: t('Order.TITLE')});

        Object.keys(CreditType).forEach(creditType => result.push({value: creditType, label: t('Credit.CREDIT_TYPE_' + creditType)}));

        return result;
    }, [t]);

    const columns = useMemo<Array<Column<TransactionType>>>(() => [
        {
            Header: t('Finance.BOOKING_DATE'),
            accessor: 'bookingDate',
            Cell: (props: PropsWithChildren<CellProps<TransactionType>>) => formatDateAndTime(props.value, currentLanguage, false, false),
            disableSortBy: true,
            disableFilters: true
        }, {
            Header: t('Finance.TRANSACTION_TYPE'),
            accessor: 'transactionType',
            Cell: props => props.value === 'CREDIT' ? t(`Credit.CREDIT_TYPE_${props.row.original.creditType}`) : t('Order.TITLE')
        }, {
            Header: t('Common.CHILD_HEADER'),
            accessor: 'id',
            Cell: (props: PropsWithChildren<CellProps<TransactionType>>) => getChildName(props.row.original),
            disableSortBy: true,
            disableFilters: false
        },
        {
            Header: t('Finance.MENU_DATE'),
            accessor: 'mealDate',
            Cell: (props: PropsWithChildren<CellProps<TransactionType>>) => getMenuDate(props.row.original),
            disableSortBy: true,
            disableFilters: false
        },
        {
            Header: t('Finance.VALUE'),
            accessor: 'singleValue',
            Cell: (props: PropsWithChildren<CellProps<TransactionType>>) => <Price textRight={false} price={props.value * (props.row.original.amount ? props.row.original.amount : 1)}/>,
            disableSortBy: true,
            disableFilters: true
        }
    ], [currentLanguage, t, getChildName, getMenuDate]);

    const setTransactionTypeFilter = useCallback((option: DropdownOption) => {
        if (option === null) {
            dispatch(resetTransactionTypeFilter());
        } else if (option.value === 'ORDER') {
            dispatch(updateTransactionTypeFilter(TransactionTypeEnum.DEBIT));
        } else if (Object.values(CreditType).indexOf(option.value as CreditType) >= 0) {
            dispatch(updateTransactionTypeFilter(TransactionTypeEnum.CREDIT, option.value as CreditType));
        }
    }, [dispatch]);

    function getDropdownSelection() {
        if (filter.transactionType) {
            if (filter.transactionType === TransactionTypeEnum.DEBIT) {
                return {
                    value: 'ORDER'
                };
            } else {
                return {
                    value: filter.creditType as string
                }
            }
        } else {
            return undefined;
        }
    }

    return (
        <div className="container finance">
            <BackTo path="/finances" labelKey="Finance.TITLE"/>
            <h3 className="mt-4 mb-5">{t('Finance.TRANSACTION_HISTORY')}</h3>

            <div className="row">
                <div className="col-5 col-lg-3">
                    <SearchField
                        placeholder={t('Search.PLACEHOLDER_LABEL')}
                        setSearchTerm={searchTerm => updateFilter('name', searchTerm)}
                        searchTerm={filter.name}
                        onKeyPress={(event) => callOnEnter(event, () => search({pageIndex: 0}))}
                    />
                </div>
                <div className="col"/>
            </div>
            <div className="row">
                <div className="col-5 col-lg-3">
                    <Dropdown
                        label={t('Finance.TRANSACTION_TYPE')}
                        value={getDropdownSelection()}
                        options={transactionTypeOptions}
                        isClearable={true}
                        onChange={setTransactionTypeFilter}
                        required={false}
                        formClassName="form-group pt-4"
                    />
                </div>
                <div className="col-7 col-lg-4">
                    {t('Finance.BOOKING_DATE')}
                    <div className="row">
                        <div className="col">
                            <DateInput
                                label={t('Period.FROM')}
                                value={filter.bookingDateFrom}
                                isClearable
                                onChange={(value) => updateFilter('bookingDateFrom', value)}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col">
                            <DateInput
                                label={t('Period.TO')}
                                value={filter.bookingDateTo}
                                isClearable
                                onChange={(value) => updateFilter('bookingDateTo', value)}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
                <div className="col"/>
                {
                    (!filter.transactionType || filter.transactionType === TransactionTypeEnum.DEBIT) &&
                    <div className="col-7 col-lg-4">
                        {t('Finance.MENU_DATE')}
                        <div className="row">
                            <div className="col">
                                <DateInput
                                    label={t('Period.FROM')}
                                    value={filter.mealDateFrom}
                                    isClearable
                                    onChange={(value) => updateFilter('mealDateFrom', value)}
                                />
                            </div>
                            <div className="col">
                                <DateInput
                                    label={t('Period.TO')}
                                    value={filter.mealDateTo}
                                    isClearable
                                    onChange={(value) => updateFilter('mealDateTo', value)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="float-right mb-4">
                <button className="btn btn-secondary mr-2" onClick={() => resetFilter()}>{t('Button.RESET')}</button>
                <button className="btn btn-primary" onClick={() => search({pageIndex: 0})}>{t('Button.FILTER')}</button>
            </div>

            <OcTable
                data={transactions}
                columns={columns}
                fetchData={search}
                totalCount={total}
                pageIndex={filter.page}
            />
        </div>
    );
}

export default TransactionHistory;
