import {httpClient} from '../../common/api/HttpClient';

export const catererApi = {
    fetchActiveCaterers: () => httpClient.get('caterers'),
    findCatererById: (id) => httpClient.get(`caterers/profile/${id}`),
    findCatererTextResourcesById: (id, params) => httpClient.get(`caterers/${id}/resources/text`, params),
    findCatererBinaryResourcesById: (id, type) => httpClient.get(`caterers/${id}/resources/binary/${type}`),
    findCatererClosingTimes: (catererId) => httpClient.get(`closing-times/caterers/${catererId}`),
    findCatererContacts: (catererId) => httpClient.get(`caterers/contacts/${catererId}`),
    fetchCatererEmployees: (filter) => httpClient.get(`caterers/employees`, {params: filter}),
    findCatererTimeManagementsForChild: (catererId) => httpClient.get(`caterers/time-management/${catererId}/child`),
    findCatererNextOrderableDateForChild: (catererId) => httpClient.get(`caterers/next-orderable-date/${catererId}/child`),
    findCatererNextOrderableDateForGroup: (catererId) => httpClient.get(`caterers/next-orderable-date/${catererId}/group`),
    findCatererNextCancelableDateForChild: (catererId) => httpClient.get(`caterers/next-cancelable-date/${catererId}/child`),
    findCatererTimeManagementsForGroup: (catererId) => httpClient.get(`caterers/time-management/${catererId}/group`),
    createOrUpdateTimeManagements: (catererId, timeManagements) => httpClient.post(`caterers/time-management/${catererId}`, timeManagements),
    createOrUpdate: (caterer) => httpClient.post('caterers', caterer),
    findCatererWebsite: (id) => httpClient.get(`caterers/profile/website/${id}`),
    createOrUpdateWebsite: (catererWebsiteAddressDtos, id) => httpClient.post(`caterers/profile/websites/${id}`, catererWebsiteAddressDtos),
    deleteWebsites: (ids, id) => httpClient.post(`caterers/profile/websites/delete/${id}`, ids),
    resendTextResources: (id, type) => httpClient.post(`caterers/${id}/resources/${type}/trigger-parent-mail`),
    createOrUpdateTextResources: (id, textResourceDtos) => httpClient.post(`caterers/${id}/resources/text`, textResourceDtos),
    createOrUpdateBinaryResources: (id, binaryResourceDto) => httpClient.post(`caterers/${id}/resources/binary`, binaryResourceDto),
    createClosingTimes: (catererId, closingTimes) => httpClient.post(`closing-times/caterers/${catererId}`, closingTimes),
    updateCatererContacts: (catererId, contacts) => httpClient.post(`caterers/contacts/${catererId}`, contacts),
    updateCatererNews: (catererId, news) => httpClient.post(`caterers/news/${catererId}`, news),
    findCatererNews: (id) => httpClient.get(`caterers/profile/news/${id}`),
    existsEmailAddress: (emailAddress) => httpClient.get(`caterers/contacts/exists-email/${emailAddress}`),
    updateLimitManagement: (catererId, limitManagement) => httpClient.post(`caterers/limit-management/${catererId}`, limitManagement),
    findCatererLimitManagement: (id) => httpClient.get(`caterers/limit-management/${id}`)
};
export default catererApi;
