import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import FilePreview from '../../../common/component/FilePreview';
import DocumentIcon from '../../../common/icons/Document';
import DownloadIcon from '../../../common/icons/DownloadIcon';
import Icon from '../../../common/icons/Icon';
import {fileDownload} from '../../../common/util/fileDownloadUtil';
import taskApi from '../../../taskboard/api/taskApi';

function DocumentRow({
                         taskId,
                         documentToUpload
                     }) {
    const [documents, setDocuments] = useState([]);

    const [t] = useTranslation();
    useEffect(() => {
        let mounted = true;
        if (taskId) {
            taskApi.findTaskDocumentsById(taskId).then((res) => {
                if (res.data.success && mounted) {
                    setDocuments(res.data.result);
                }
            });
        }
        return () => mounted = false;
    }, [taskId]);

    function downloadDocument(id, filename) {
        fileDownload({
            url: `tasks/documents/${id}`,
            fileName: filename
        });
    }

    return (
        <>
            {
                documents.map((document, index) =>
                    <div className="d-inline-flex" id={index} key={`document-${document.id}`}>
                        <FilePreview
                            className="mr-1"
                            downloadFunction={taskApi.downloadTaskDocumentById}
                            id={document.id}
                            fileName={document.name}
                        />
                        <div className="file-preview-icon d-inline-block mr-2 mr-lg-4" data-for="file-preview-tooltip"
                             data-tip={t('FileButton.DOWNLOAD')}>
                            <Icon src={<DownloadIcon/>}
                                  onClick={() => downloadDocument(document.id, document.name)}
                                  className="icon-primary"
                            />
                            <ReactTooltip id={'file-preview-tooltip'} type="light" multiline={false}/>
                        </div>
                    </div>
                )
            }
            {
                documentToUpload &&
                <Icon src={<DocumentIcon/>}
                      className="icon-primary-not-clickable"
                      title={documentToUpload.name}
                />
            }
        </>
    );
}

export default DocumentRow;
