import React from 'react';
import CloseIcon from '../icons/CloseIcon';
import Icon from '../icons/Icon';
import '../style/Tag.scss';

interface Prop {
    className?: string;
    label?: string;
    type: string;
    onDeleteClick?: () => void;
}

function Tag(props: Prop) {
    return (
        <span className={`tag ${props.className ? props.className : ''} ${props.type}`}>
            {props.label}
            {
                (!!props.onDeleteClick) &&
                <Icon className="ml-2" onClick={props.onDeleteClick}
                      src={
                          <CloseIcon size={props.type === 'medium' ? 0.75 : 1}/>
                      }/>
            }
        </span>
    );
}

export default Tag;
