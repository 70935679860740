import React from 'react';

const ArrowBackIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M5.75 3C5.3703 3 5.0565 3.28215 5.0068 3.64823L5 3.75V20.25C5 20.6642 5.3358 21 5.75 21C6.1297 21
                  6.4435 20.7178 6.4932 20.3518L6.5 20.25V3.75C6.5 3.33579 6.1642 3 5.75 3ZM18.7803 3.21967C18.5141
                  2.9534 18.0974 2.9292 17.8038 3.14705L17.7197 3.21967L9.4697 11.4697C9.2034 11.7359 9.1792 12.1526
                  9.3971 12.4462L9.4697 12.5303L17.7197 20.7803C18.0126 21.0732 18.4874 21.0732 18.7803 20.7803C19.0466
                  20.5141 19.0708 20.0974 18.8529 19.8038L18.7803 19.7197L11.0607 12L18.7803 4.28033C19.0732 3.98744
                  19.0732 3.51256 18.7803 3.21967Z"/>
        </svg>
    );
};

export default ArrowBackIcon;
