import React from 'react';

interface Props {
    size?: number;
}

const CardIcon = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20 6.5H4C3.72386 6.5 3.5 6.72386 3.5 7V17C3.5 17.2761 3.72386 17.5 4 17.5H20C20.2761 17.5 20.5 17.2761
                  20.5 17V7C20.5 6.72386 20.2761 6.5 20 6.5ZM4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046
                  19 22 18.1046 22 17V7C22 5.89543 21.1046 5 20 5H4ZM4.75 9C4.75 8.58579 5.08579 8.25 5.5 8.25H18.5C18.9142 8.25
                  19.25 8.58579 19.25 9C19.25 9.41421 18.9142 9.75 18.5 9.75H5.5C5.08579 9.75 4.75 9.41421 4.75 9ZM16 14.25C15.5858
                  14.25 15.25 14.5858 15.25 15C15.25 15.4142 15.5858 15.75 16 15.75H18C18.4142 15.75 18.75 15.4142 18.75 15C18.75
                  14.5858 18.4142 14.25 18 14.25H16ZM11 14.5C11 15.3284 10.3284 16 9.5 16C8.67157 16 8 15.3284 8 14.5C8 13.6716
                  8.67157 13 9.5 13C10.3284 13 11 13.6716 11 14.5ZM6.5 13C6.80824 13 7.09477 13.093 7.33306 13.2524C7.12121 13.6196
                  7 14.0456 7 14.5C7 14.9544 7.12121 15.3804 7.33306 15.7476C7.09477 15.907 6.80824 16 6.5 16C5.67157 16 5 15.3284
                  5 14.5C5 13.6716 5.67157 13 6.5 13Z"
            fill="currentColor"/>
        </svg>
    );
};


export default CardIcon;
