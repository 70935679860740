import React from 'react';
import {useTranslation} from 'react-i18next';
import ScrollToTop from '../../common/component/ScrollToTop';

function RequestSuccess() {
    const [t] = useTranslation();
    return (
        <div className="container">
            <ScrollToTop/>
            <div className="text-center" dangerouslySetInnerHTML={{__html: t('Registration.SUCCESS_FOR_CARRIER')}}/>
        </div>
    );
}

export default RequestSuccess;
