import {httpClient} from '../../common/api/HttpClient';

export const groupApi = {
    findGroupById: (groupId) => httpClient.get(`groups/${groupId}`),
    getGroupByCode: (code) => httpClient.get(`groups/by-code/${code}`, {immediate: true}),
    deleteGroup: (groupId) => httpClient.delete(`groups/${groupId}`),
    findGroupClosingTimes: (groupId) => httpClient.get(`closing-times/group/${groupId}`),
    createOrUpdateClosingTimes: (groupId, closingTimes) => httpClient.post(`closing-times/group/${groupId}`, closingTimes)
};

export default groupApi;
