import {httpClient} from '../../common/api/HttpClient';

export const menuApi = {
        fetchMenuTimetableStandard: (institutionId, isLandscape = false) =>
            httpClient.get(`menus/institutions/${institutionId}/timetable`, {
                responseType: 'blob',
                params: {
                    includeAllMenuLines: false,
                    isLandscape: !!isLandscape
                }
            }),
        fetchMenuTimetableFull: (institutionId) =>
            httpClient.get(`menus/institutions/${institutionId}/timetable`, {
                responseType: 'blob',
                params: {
                    includeAllMenuLines: true
                }
            }),
        fetchMenuNutritionDetailsStandard: (institutionId) =>
            httpClient.get(`menus/institutions/${institutionId}/nutrition-details`, {
                responseType: 'blob',
                params: {
                    includeAllMenuLines: false
                }
            }),
        fetchMenuNutritionDetailsFull: (institutionId) =>
            httpClient.get(`menus/institutions/${institutionId}/nutrition-details`, {
                responseType: 'blob',
                params: {
                    includeAllMenuLines: true
                }
            }),
        findAllAllergensAndAdditives: () =>
            httpClient.get('menu/allergen-and-additive'),
        findAllMealTypes: (catererId) =>
            httpClient.get(`menu/${catererId}/meal-types`),
        findAllMeals: (catererId) =>
            httpClient.get(`meals/${catererId}`),
        findMeal: (catererId, mealId) =>
            httpClient.get(`meals/${catererId}/${mealId}`),
        deleteMeal: (catererId, mealId) =>
            httpClient.delete(`meals/${catererId}/${mealId}`),
        createOrUpdateMeal: (meal) =>
            httpClient.post(`meals`, meal),
        uploadImageForMeal: (mealId, img) =>
            httpClient.post(`meals/${mealId}/img`, img),
        fetchFutureMenusForMenuGroup: (catererId, menuGroupId) =>
            httpClient.get(`menu/${catererId}/menu-group/${menuGroupId}`),
        findChildMenusForDate: (childId, date) => httpClient.get(`menus/child/${childId}/single-day`, {
                params: {date: date}
            }
        )
    }
;

export default menuApi;
