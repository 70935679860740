import React from 'react';

type Props = {
    size?: number;
}

const ArrowLeftIcon = ({size = 1}: Props) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M11.2327 19.7905C11.5326 20.0762 12.0074 20.0646 12.2931 19.7647C12.5788 19.4648 12.5672 18.99 12.2673 18.7043L6.01587
                     12.7497H20.75C21.1642 12.7497 21.5 12.4139 21.5 11.9997C21.5 11.5855 21.1642 11.2497 20.75 11.2497H6.01577L12.2673
                     5.29502C12.5672 5.00933 12.5787 4.5346 12.2931 4.23467C12.0074 3.93475 11.5326 3.9232 11.2327 4.20889L3.81379 11.2756C3.64486
                     11.4365 3.54491 11.6417 3.51393 11.8551C3.50479 11.9019 3.5 11.9503 3.5 11.9997C3.5 12.0493 3.50481 12.0977 3.51398
                     12.1446C3.54502 12.3579 3.64496 12.563 3.81379 12.7238L11.2327 19.7905Z"
            />
        </svg>
    );
};

export default ArrowLeftIcon;
