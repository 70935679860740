import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {HIDE_LOADER, SHOW_LOADER} from '../../common/action/LoaderAction';
import '../style/StripePayment.scss';
import CheckIcon from '../../common/icons/CheckIcon';
import CloseIcon from '../../common/icons/CloseIcon';
import {PAYMENT_METHOD_TYPES} from '../util/StripePaymentMethods';

function StripePayment({onPaymentSent, onCancel}) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [paymentError, setPaymentError] = useState();
    const BILLING_DETAILS_COUNTRY = 'DE';

    const PAYMENT_ELEMENT_OPTIONS = {
        classes: {invalid: 'form-control.is-invalid border-danger'},
        // https://stripe.com/docs/api/payment_methods/object -> type
        paymentMethodOrder: PAYMENT_METHOD_TYPES
    };

    useEffect(() => {
        if (elements) {
            const paymentElement = elements.getElement(PaymentElement);
            paymentElement.on('change', event => {
                setPaymentError(null);
                setIsDataComplete(event.complete);
            });
        }
    }, [elements]);

    const submitPayment = useCallback((event) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        // Show the loader until the confirmCardPayment request in submitPayment is finished. (That request does not use our http client, thus the loader is triggered manually.)
        dispatch({type: SHOW_LOADER});
        stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: `${window.location.origin}/payment-confirmation`,
                payment_method_data: {
                    // Must be supplied because this field is set to 'never' in PAYMENT_ELEMENT_OPTIONS:
                    billing_details: {address: {country: BILLING_DETAILS_COUNTRY}}
                }
            }
        }).then(result => {
            if (result.error) {
                setPaymentError(result.error);
            } else {
                onPaymentSent();
            }
        }).catch(error => {
            setPaymentError(error);
        }).finally(() => {
                dispatch({type: HIDE_LOADER});
            }
        );
    }, [stripe, elements, dispatch, onPaymentSent]);

    return (
        <div className="form-group">
            <form id="payment-form" onSubmit={submitPayment} onReset={onCancel}>
                <PaymentElement options={PAYMENT_ELEMENT_OPTIONS}/>
                {
                    paymentError &&
                    <div className="invalid-feedback d-block mt-2">
                        {t('Finance.PAYMENT_FAILED')}&nbsp;{paymentError.message || ''}
                    </div>
                }
                <div className="mt-2 text-right">
                    <button type="reset" className="btn btn-transparent mt-3">
                        <CloseIcon/>
                        <span className="ml-2">{t('Button.CANCEL')}</span>
                    </button>
                    <button type="submit" className="btn btn-primary mt-3 ml-2" disabled={!stripe || !elements || !isDataComplete || !!paymentError} id="submit">
                        <CheckIcon/>
                        <span className="ml-2">{t('Finance.FINISH_BUTTON')}</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default StripePayment;
