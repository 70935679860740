import React from 'react';
import {useTranslation} from 'react-i18next';
import FormButtons from '../../../common/component/form/FormButtons';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';

type Props = {
    specialMenuGroupsOpen: Array<MenuGroup>,
    specialMenuGroupsAttest: Array<MenuGroup>,
    onRequest: () => void,
    onDiscard: () => void
}

function ChildSetupSpecialDietYesNo({specialMenuGroupsOpen, specialMenuGroupsAttest, onRequest, onDiscard}: Readonly<Props>) {
    const [t] = useTranslation();

    return <>
        {
            specialMenuGroupsOpen.length > 0 &&
            <>
                <span>{t('ChildSetup.SPECIAL_MEAL_NO_ATTEST')}:</span>
                <ul>
                    {specialMenuGroupsOpen.map(menuGroup => <li key={menuGroup.id}>{menuGroup.name}</li>)}
                </ul>
            </>
        }
        {
            specialMenuGroupsAttest.length > 0 &&
            <>
                <span>{t('ChildSetup.SPECIAL_MEAL_ATTEST_MANDATORY')}:</span>
                <ul>
                    {specialMenuGroupsAttest.map(menuGroup => <li key={menuGroup.id}>{menuGroup.name}</li>)}
                </ul>
            </>
        }

        <div className="d-flex justify-content-end mt-4">
            <FormButtons
                onSave={onRequest}
                onCancel={onDiscard}
                saveLabelKey="Button.YES"
                cancelLabelKey="Button.NO"
                cancelButtonStyle="secondary"
                buttonsClassName="px-5"
                sticky={false}
            />
        </div>
    </>;
}

export default ChildSetupSpecialDietYesNo;
