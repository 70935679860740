import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import catererApi from '../../../caterer/api/catererApi';
import publicCatererApi from '../../../caterer/api/publicCatererApi';
import {CatererTimeManagementData} from '../../../caterer/types/CatererTimeManagementData';
import {markChanges, unmarkChanges} from '../../../common/action/pageLeaveActions';
import {convertDateToIsoDateString} from '../../../common/util/DateUtil';
import {checkValidation} from '../../../common/util/ValidationUtil';
import mealPlanManagementApi from '../../../mealplan/api/mealPlanManagementApi';
import {showMessage} from '../../../message/action/messageActions';
import subscriptionApi from '../../../order/api/subscriptionApi';
import {validateChildSubscription} from '../../../order/util/SubscriptionUtils';
import {Child} from '../../../types/Child';
import {ChildSubscription} from '../../../types/child/ChildSubscription';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import ChildSetupSubscriptionDuration from './ChildSetupSubscriptionDuration';
import ChildSetupSubscriptionMenuGroup from './ChildSetupSubscriptionMenuGroup';
import ChildSetupSubscriptionWeekdays from './ChildSetupSubscriptionWeekdays';
import ChildSetupSubscriptionYesNo from './ChildSetupSubscriptionYesNo';

type Props = {
    catererId: number,
    accountId: number,
    child: Child,
    onProceed: () => void,
    onSkip: () => void
}

function ChildSetupSubscriptionWrapper({catererId, accountId, child, onProceed, onSkip}: Readonly<Props>) {
    const dispatch = useDispatch();

    const [stage, setStage] = useState<number>(0);
    const [subscription, setSubscription] = useState<Partial<ChildSubscription>>({
        childId: child.id,
        accountId
    });
    const [validation, setValidation] = useState({
        startDate: true,
        endDate: true,
        pauseStartDate: true,
        pauseEndDate: true
    });
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [catererTimeManagement, setCatererTimeManagement] = useState<CatererTimeManagementData>();
    const [childMenuGroups, setChildMenuGroups] = useState<Array<MenuGroup>>([]);
    const [minOrderableDate, setMinOrderableDate] = useState<string>();

    useEffect(() => {
        publicCatererApi.findCatererTimeManagements(catererId).then(response => {
            if (response.data.success && response.data.result.length > 0) {
                const timeManagementForChildOrders = response.data.result.find(
                    (timeManagement: CatererTimeManagementData) => timeManagement.timeManagementType === 'CHILD');

                if (timeManagementForChildOrders) {
                    setCatererTimeManagement(timeManagementForChildOrders);
                }
            }
        });

        mealPlanManagementApi.fetchMenuGroupsForChild(child.id).then(response => {
            if (response.data.success) {
                setChildMenuGroups(response.data.result);
            }
        });

        catererApi.findCatererNextOrderableDateForChild(catererId).then((res) => {
            if (res.data.success) {
                setSubscription(currentValue => ({
                    ...currentValue,
                    startDate: res.data.result
                }));
                setMinOrderableDate(res.data.result);
            }
        });
    }, [catererId, child.id]);

    const handleSubscriptionChange = useCallback((key: string, value: any) => {
        dispatch(markChanges());
        if ((key === 'startDate' || key === 'endDate' || key === 'pauseStartDate' || key === 'pauseEndDate') && !!value) {
            value = convertDateToIsoDateString(value);
        }

        setSubscription(currentValue => (
            {
                ...currentValue,
                [key]: value
            }
        ));
    }, [dispatch]);

    const handleRemovePause = useCallback(() => {
        dispatch(markChanges());
        setSubscription(currentValue => (
            {
                ...currentValue,
                pauseStartDate: undefined,
                pauseEndDate: undefined
            }
        ));
    }, [dispatch]);

    const increaseStage = useCallback(() => {
        setStage((currentStage) => {
            if (currentStage === 1 && childMenuGroups.length === 1) {
                // Skip menu group selection if only one menu group could be selected
                handleSubscriptionChange('menuGroupId', childMenuGroups[0].id);
                return currentStage + 2;
            }
            return currentStage + 1;
        });
    }, [childMenuGroups, handleSubscriptionChange]);

    const decreaseStage = useCallback(() => {
        setStage((currentStage) => {
            return currentStage - 1;
        });
    }, []);

    const validate = useCallback((): boolean => {
        const newValidation = validateChildSubscription(subscription as ChildSubscription);

        setShowValidationErrors(true);
        setValidation(newValidation);

        return checkValidation(newValidation);
    }, [subscription]);

    const handleSave = useCallback(() => {
        if (validate()) {
            subscriptionApi.createOrUpdate(subscription).then(response => {
                if (response.data.success) {
                    dispatch(showMessage('Child.SAVE_SUCCESS'));
                    dispatch(unmarkChanges());
                    onProceed();
                }
            });
        }
    }, [validate, subscription, dispatch, onProceed]);

    if (!catererTimeManagement || childMenuGroups.length === 0 || !minOrderableDate) {
        return null;
    }

    switch (stage) {
        case 1:
            return <ChildSetupSubscriptionWeekdays
                weekdays={catererTimeManagement.businessDays}
                subscription={subscription}
                onChange={handleSubscriptionChange}
                onProceed={increaseStage}
                onSkip={onSkip}
            />
        case 2:
            return <ChildSetupSubscriptionMenuGroup
                menuGroups={childMenuGroups}
                subscription={subscription}
                onChange={handleSubscriptionChange}
                onProceed={increaseStage}
                onGoBack={decreaseStage}
                onSkip={onSkip}
            />
        case 3:
            return <ChildSetupSubscriptionDuration
                subscription={subscription}
                minOrderableDate={minOrderableDate}
                validation={validation}
                onValidate={validate}
                showValidationErrors={showValidationErrors}
                onChange={handleSubscriptionChange}
                onRemovePause={handleRemovePause}
                onProceed={handleSave}
                onGoBack={decreaseStage}
                onSkip={onSkip}
            />
        default:
            return <ChildSetupSubscriptionYesNo
                child={child}
                onProceed={increaseStage}
                onSkip={onSkip}
            />;
    }
}

export default ChildSetupSubscriptionWrapper;
