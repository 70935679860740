import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import publicCarrierApi from '../../administration/api/publicCarrierApi';
import publicInstitutionApi from '../../administration/api/publicInstitutionApi';
import Checkbox from '../../common/component/form/Checkbox';
import Switch from '../../common/component/Switch';
import Role from '../../common/enums/Role';
import {checkValidation, validateEmailAddress} from '../../common/util/ValidationUtil';
import {showError} from '../../message/action/messageActions';
import '../style/Registration.scss';
import CarrierInquiryInput from './CarrierInquiryInput';
import InstitutionInquiryInput from './InstitutionInquiryInput';
import RequestSuccess from './RequestSuccess';

function Inquiry() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const catererId = useSelector(state => state.caterer.catererId);
    const [isValidated, setIsValidated] = useState(false);
    const [approvedTermsAndConditions, setApprovedTermsAndConditions] = useState(false);
    const [approvedPrivacyPolicy, setApprovedPrivacyPolicy] = useState(false);
    const [mailErrorCode, setMailErrorCode] = useState(null);
    const [showRequestSuccess, setShowRequestSuccess] = useState(false);

    const [inquiry, setInquiry] = useState({
        type: 'CARRIER',
        institutionType: null,
        company: {
            name: null,
            address: {
                street: null,
                houseNumber: null,
                additionalAddressInformation: null,
                zip: null,
                city: null
            }
        },
        contactPerson: {
            salutation: null,
            lastName: null,
            emailAddress: null
        },
        login: {
            emailAddress: null
        }
    });

    const [inquiryMetadataValidation, setInquiryMetadtaValidation] = useState({
        approvedPrivacyPolicy: true,
        approvedTermsAndConditions: true
    });

    const [carrierValidation, setCarrierValidation] = useState({
        type: true,
        company: {
            name: true,
            address: {
                street: true,
                houseNumber: true,
                zip: true,
                city: true
            }
        },
        contactPerson: {
            salutation: true,
            lastName: true
        },
        login: {
            emailAddress: true
        }
    });

    const [institutionValidation, setInstitutionValidation] = useState({
        type: true,
        company: {
            name: true,
            address: {
                street: true,
                houseNumber: true,
                zip: true,
                city: true
            }
        },
        contactPerson: {
            salutation: true,
            lastName: true,
            emailAddress: true,
            function: true
        }
    });

    useEffect(() => {
        if (isValidated) {
            validate();
        }
    }, [inquiry, approvedPrivacyPolicy, approvedTermsAndConditions]);

    function validate() {
        let subValidation = {};
        if (inquiry.type === Role.CARRIER) {
            subValidation = createCarrierValidation();
            setCarrierValidation(subValidation);
        } else if (inquiry.type === Role.INSTITUTION) {
            subValidation = createInstitutionValidation();
            setInstitutionValidation(subValidation);
        }
        const newInquiryMetadataValidation = {
            approvedPrivacyPolicy: !!approvedPrivacyPolicy,
            approvedTermsAndConditions: !!approvedTermsAndConditions
        };
        setInquiryMetadtaValidation(newInquiryMetadataValidation);

        setIsValidated(true);

        return checkValidation(newInquiryMetadataValidation) && checkValidation(subValidation);
    }

    function createCarrierValidation() {
        return {
            type: !!inquiry.type,
            company: {
                name: !!inquiry.company.name,
                address: {
                    street: !!inquiry.company.address.street,
                    houseNumber: !!inquiry.company.address.houseNumber,
                    zip: !!inquiry.company.address.zip,
                    city: !!inquiry.company.address.city
                }
            },
            contactPerson: {
                salutation: !!inquiry.contactPerson.salutation,
                lastName: !!inquiry.contactPerson.lastName
            },
            login: {
                emailAddress: validateEmailAddress(inquiry.login.emailAddress)
            }
        };
    }

    function createInstitutionValidation() {
        return {
            type: !!inquiry.type,
            company: {
                name: !!inquiry.company.name,
                address: {
                    street: !!inquiry.company.address.street,
                    houseNumber: !!inquiry.company.address.houseNumber,
                    zip: !!inquiry.company.address.zip,
                    city: !!inquiry.company.address.city
                }
            },
            contactPerson: {
                salutation: !!inquiry.contactPerson.salutation,
                lastName: !!inquiry.contactPerson.lastName,
                emailAddress: validateEmailAddress(inquiry.contactPerson.emailAddress),
                function: !!inquiry.contactPerson.function
            }
        };
    }

    function handleInquiryChange(field, value) {
        if (field === 'contactPerson' && !!value.emailAddress) {
            setMailErrorCode(null);
        }

        setInquiry({
            ...inquiry,
            [field]: value
        });
    }

    function handleCompanyChange(field, value) {
        setInquiry({
            ...inquiry,
            company: {
                ...inquiry.company,
                [field]: value
            }
        });
    }

    function handleContactPersonChange(field, value) {
        setInquiry({
            ...inquiry,
            contactPerson: {
                ...inquiry.contactPerson,
                [field]: value
            }
        });
    }

    function handleLoginChange(field, value) {
        if (field === 'emailAddress') {
            setMailErrorCode(null);
        }
        setInquiry({
            ...inquiry,
            login: {
                ...inquiry.login,
                [field]: value
            }
        });
    }

    function send() {
        if (!validate()) {
            dispatch(showError('Error.MISSING_PARAMETER'));
            return;
        }

        if (inquiry.type === Role.CARRIER) {
            sendCarrierInquiry();
        } else if (inquiry.type === Role.INSTITUTION) {
            sendInstitutionInquiry();
        }
    }

    function sendCarrierInquiry() {
        const carrierInquiry = {
            companyName: inquiry.company.name,
            address: inquiry.company.address,
            contacts: [{
                person: inquiry.contactPerson,
                emailAddress: inquiry.login.emailAddress,
                loginRequired: true
            }]
        };

        publicCarrierApi.createCarrierInquiry(carrierInquiry, catererId).then((res) => {
                if (res.data.success) {
                    setShowRequestSuccess(true);
                } else {
                    setMailErrorCode(res.errorCode);
                }
            }
        );
    }

    function sendInstitutionInquiry() {
        const institutionInquiry = {
            companyName: inquiry.company.name,
            type: inquiry.institutionType,
            address: inquiry.company.address,
            contacts: [{
                person: inquiry.contactPerson,
                function: inquiry.contactPerson.function,
                emailAddress: inquiry.contactPerson.emailAddress
            }]
        };

        publicInstitutionApi.createInstitutionInquiry(institutionInquiry, catererId).then((res) => {
                if (res.data.success) {
                    setShowRequestSuccess(true);
                } else {
                    setMailErrorCode(res.errorCode);
                }
            }
        );
    }

    if (showRequestSuccess) {
        return <RequestSuccess/>;
    }

    return (
        <div className="container">
            <h1>
                {t('Inquiry.TITLE')}
            </h1>
            <div className={`mb-3 account-type account-type-small account-type-${inquiry.type.toLowerCase()}`}/>
            <div className="card mb-3">
                <div className="card-body">
                    <p className="pre-wrap mb-0">{t('Registration.DESCRIPTION_INQUIRY')}</p>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="mb-3 text-center">
                        <Switch
                            options={
                                ['CARRIER', 'INSTITUTION']
                                    .map(key => ({
                                        id: key,
                                        label: t(`Role.${key}`)
                                    }))}
                            onSelect={value => handleInquiryChange('type', value)}
                            selectedOption={inquiry.type}
                        />
                    </div>
                    <>
                        {
                            inquiry.type === Role.CARRIER &&
                            <CarrierInquiryInput
                                inquiry={inquiry}
                                validation={carrierValidation}
                                handleInquiryChange={handleInquiryChange}
                                handleCompanyChange={handleCompanyChange}
                                handleLoginChange={handleLoginChange}
                                mailErrorCode={mailErrorCode}
                            />
                        }
                        {
                            inquiry.type === Role.INSTITUTION &&
                            <InstitutionInquiryInput
                                inquiry={inquiry}
                                validation={institutionValidation}
                                handleInquiryChange={handleInquiryChange}
                                handleCompanyChange={handleCompanyChange}
                                handleContactPersonChange={handleContactPersonChange}
                                mailErrorCode={mailErrorCode}
                            />
                        }
                    </>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <Checkbox value={approvedTermsAndConditions}
                              onChange={(value) => setApprovedTermsAndConditions(value)}
                              label={<>
                                  {t('Registration.I_HEREBY_CONFIRM')}
                                  <Link to="/terms-and-conditions" target="_blank">{t('Registration.TERMS_AND_CONDITIONS')}</Link>
                              </>}
                              required={true}
                              isValid={!!inquiryMetadataValidation.approvedTermsAndConditions}/>

                    <Checkbox value={approvedPrivacyPolicy}
                              onChange={(value) => setApprovedPrivacyPolicy(value)}
                              label={<>
                                  {t('Registration.I_HEREBY_CONFIRM')}
                                  <Link to="/privacy-policy" target="_blank">{t('Registration.PRIVACY_POLICY')}</Link>
                              </>}
                              required={true}
                              isValid={!!inquiryMetadataValidation.approvedPrivacyPolicy}/>


                    <div className="button-footer">
                        <button
                            className="btn btn-secondary mr-3"
                            onClick={() => history.push('/inquiry')}>
                            {t('Button.CANCEL')}
                        </button>
                        <button
                            data-cy="submit"
                            className="btn btn-primary"
                            onClick={send}>
                            {t('Button.SEND')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inquiry;
