import React from 'react';

const EditIcon = ({size = 1}) => {
    return (
        <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M20.9519 3.04791C19.5543 1.65039 17.2885 1.65046 15.8911 3.04806L3.94103 14.9995C3.5347
                  15.4059 3.2491 15.917 3.116 16.4761L2.02041 21.0775C1.96009 21.3309 2.03552 21.5974 2.21968
                  21.7816C2.40385 21.9657 2.67037 22.0412 2.92373 21.9808L7.52498 20.8853C8.08418 20.7521 8.59546
                  20.4664 9.00191 20.0599L20.952 8.10843C22.3493 6.71094 22.3493 4.44532 20.9519 3.04791ZM16.9518
                  4.10866C17.7634 3.2969 19.0795 3.29686 19.8912 4.10857C20.7028 4.92022 20.7029 6.23614 19.8913
                  7.04783L19 7.93927L16.0606 4.99994L16.9518 4.10866ZM15 6.06066L17.9394 9L7.94119 18.9993C7.73104
                  19.2095 7.46668 19.3572 7.17755 19.4261L3.76191 20.2393L4.57521 16.8235C4.64402 16.5345 4.79168
                  16.2702 5.00175 16.0601L15 6.06066Z"/>
        </svg>

    );
};

export default EditIcon;
