import {httpClient} from '../../common/api/HttpClient';

export const carrierApi = {
    fetchCarrierListByCatererId: (catererId) => httpClient.get(`carriers/caterer/${catererId}`),
    fetchCarrierList: () => httpClient.get('carriers'),
    findCarrierById: (carrierId) => httpClient.get(`carriers/${carrierId}`),
    createOrUpdateCarrier: (carrier) => httpClient.post('carriers', carrier),
    deactivateCarrier: (carrierId) => httpClient.delete(`carriers/${carrierId}`),
    exportCarriers: () => httpClient.get('carriers/export', {responseType: 'blob'}),
    fetchContacts: (filter) => httpClient.get(`carriers/contacts`, {params: filter})
};

export default carrierApi;
