export const setFavicon = (catererIcon: any, iconExtension: string) => {
    const favIconEl = document.getElementById('favicon');
    if (!favIconEl)
    {
        return;
    }

    if (catererIcon.data && iconExtension) {
        // @ts-ignore
        favIconEl.href = `data:image/${iconExtension};base64,${catererIcon.data}`;

    } else if (catererIcon.tried) {
        // @ts-ignore
        favIconEl.href = '/gabel1.ico';
    }
};

export const setGabel1Favicon = () => {
    const favIconEl = document.getElementById('favicon');
    if (!favIconEl)
    {
        return;
    }

    // @ts-ignore
    favIconEl.href = '/gabel1.ico';
}

export const setTabTitle = (companyName: string | undefined, defaultTitle: string, titlePrefix?: string) => {
    const titleEl = document.getElementById('tab-title');
    if (titleEl) {
        if (companyName && titlePrefix) {
            titleEl.innerText = `${titlePrefix} ${companyName}`;
        } else if (companyName) {
            titleEl.innerText = `${companyName}`;
        } else {
            titleEl.innerText = `${defaultTitle}`;
        }
    }
};
