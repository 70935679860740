import React from 'react';
import {useTranslation} from 'react-i18next';
import DownloadIcon from '../../icons/DownloadIcon';
import Icon from '../../icons/Icon';
import PlusIcon from '../../icons/PlusIcon';
import TableGlobalFilter from './TableGlobalFilter';

type TableActionsProps = {
    globalFilter: string
    exportLabel?: string
    setGlobalFilter: (value: string) => void
    onExport?: () => void
    onAdd?: () => void
}

function TableActions(props: TableActionsProps) {

    const [t] = useTranslation();
    return (
        <div className="row">
            <div className="col-12 col-lg-5 order-2 order-lg-1">
                <TableGlobalFilter
                    globalFilter={props.globalFilter}
                    setGlobalFilter={props.setGlobalFilter}
                />
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 text-right">
                {
                    props.onExport &&
                    <button className="btn btn-secondary" onClick={props.onExport}>
                        <Icon src={<DownloadIcon/>} className="mr-2"/>
                        {props.exportLabel}
                    </button>
                }
                {
                    props.onAdd &&
                    <button
                        className="btn btn-primary ml-3"
                        onClick={props.onAdd}
                        data-cy="addButton">
                        <Icon src={<PlusIcon/>} className="mr-2"/>
                        {t('Button.ADD')}
                    </button>
                }

            </div>
        </div>
    );
};
export default TableActions;
