import {ParentType} from '../../types/ParentType';

export function convertToTypedParent(parentIn: any): ParentType {
    return {
        id: parentIn.id,
        loginId: parentIn.login?.id,
        catererId: parentIn.catererId ?? parentIn.login?.catererId,
        accountStatus: parentIn.accountStatus,
        customerNumber: parentIn.customerNumber,
        salutation: parentIn.person.salutation,
        firstName: parentIn.person.firstName,
        lastName: parentIn.person.lastName,
        fullName: parentIn.person.fullName,
        emailAddress: parentIn.email ?? parentIn.login?.emailAddress,
        address: parentIn.address ? convertAddress(parentIn.address) : {},
        balance: parentIn.balance,
        children: parentIn.children ?? [],
        inactiveChildren: parentIn.inactiveChildren ?? [],
        stripeSetupIntentId: parentIn.stripeSetupIntentId,
        stripeSetupIntentRevokedByStripe: !!parentIn.stripeSetupIntentRevokedByStripe
    } as ParentType;
}

function convertAddress(addressIn: any) {
    return {
        additionalAddressInformation: addressIn.additionalAddressInformation,
        line1: addressIn.street + ' ' + addressIn.houseNumber,
        line2: addressIn.zip + ' ' + addressIn.city
    }
}
