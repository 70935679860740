import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import catererApi from '../../../caterer/api/catererApi';
import CardArrow from '../../../common/component/CardArrow';
import SubscriptionInfo from '../SubscriptionInfo';
import ChildSubscriptionEdit from './ChildSubscriptionEdit';

function ChildSubscription({
                               child,
                               subscription,
                               onUpdate,
                               isEditMode,
                               childMenuGroups
                           }) {
    const [t] = useTranslation();
    const [showInfo, setShowInfo] = useState(true);

    const allergyMenuGroup = useMemo(() =>
        child.allergies?.length > 0 ? childMenuGroups.find(menuGroup => menuGroup.nutritionClass?.id === child.allergies[0].nutritionClassId) : null,
        [child, childMenuGroups]);

    const menuGroupId = useMemo(() =>
        allergyMenuGroup ? allergyMenuGroup.id : child.group?.institutionDefaultMenuGroupId,
        [allergyMenuGroup, child]);

    const [minOrderableDate, setMinOrderableDate] = useState(null);

    const catererId = useSelector(state => state.caterer.catererId);
    useEffect(() => {
        if (catererId) {
            catererApi.findCatererNextOrderableDateForChild(catererId).then((res) => {
                if (res.data.success) {
                    setMinOrderableDate(res.data.result);
                }
            });
        }
    }, [catererId]);

    const subscriptionTemplate = useMemo(() => {
        if (minOrderableDate) {
            return {
                childId: child.id,
                startDate: new Date(minOrderableDate),
                menuGroupId
            };
        }
    }, [minOrderableDate, menuGroupId, child]);

    const currentSubscription = useMemo(() => {
        return subscription ?? subscriptionTemplate;
    }, [subscription, subscriptionTemplate]);

    if (!childMenuGroups) {
        return null;
    }

    return (
        <>
            {
                !isEditMode &&
                <div className="row">
                    <div className="col-12 col-md-6">
                        <CardArrow/>
                    </div>
                </div>
            }
            <div className="card selected mt-5">
                {
                    isEditMode &&
                    <div className="card-header">
                        {t(subscription ? 'Subscription.EDIT' : 'Subscription.ADD')}
                    </div>
                }
                <div className="card-body">
                    {
                        isEditMode && !subscription && showInfo ?
                            <SubscriptionInfo onSwitch={() => setShowInfo(false)} catererId={child.group.catererId} isInstitution={false}/>
                            :
                                currentSubscription?.startDate &&
                                <ChildSubscriptionEdit
                                    child={child}
                                    subscription={currentSubscription}
                                    onUpdate={onUpdate}
                                    isEditMode={isEditMode}
                                    childMenuGroups={childMenuGroups}
                                    minOrderableDate={minOrderableDate}
                                />
                    }
                </div>
            </div>
        </>
    );
}

export default ChildSubscription;
