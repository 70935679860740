import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import catererApi from '../../caterer/api/catererApi';
import InfoTooltip from '../../common/component/InfoTooltip';
import {sortWeekdays} from '../../common/util/DateUtil';

function Deadlines({
                       label,
                       showIcon,
                       isGroup = false,
                       catererId,
                       showAsCard = false
                   }) {
    const [t] = useTranslation();
    const [deadlineParams, setDeadlineParams] = useState({});

    const chooseDayDifferenceLabel = useCallback((dayDifference) => {
        if (dayDifference <= 0) {
            return t('Deadlines.UNTIL_SAME_DAY');
        } else if (dayDifference === 1) {
            return t('Deadlines.UNTIL_DAY_BEFORE');
        } else {
            return t('Deadlines.UNTIL_X_DAYS_BEFORE', {dayDifference: dayDifference});
        }
    }, [t]);

    const handleResult = useCallback((res) => {
        if (res?.data.success) {
            const result = res.data.result;
            const businessDays = [];
            const sortedWeekdays = sortWeekdays(result.businessDays);
            sortedWeekdays.forEach((w) => {
                businessDays.push(t('Weekday.' + w));
            });
            const param = {
                cancel: {
                    time: result.cancellationDeadline.substring(0, 5),
                    days: chooseDayDifferenceLabel(result.cancellationDayDifference)
                },
                order: {
                    time: result.orderDeadline.substring(0, 5),
                    days: chooseDayDifferenceLabel(result.orderDayDifference)
                },
                weekdays: businessDays.join(', ')
            };
            setDeadlineParams(param);
        }
    }, [t, chooseDayDifferenceLabel]);

    useEffect(() => {
        if (catererId) {
            if (isGroup) {
                catererApi.findCatererTimeManagementsForGroup(catererId).then((res) => handleResult(res));
            } else {
                catererApi.findCatererTimeManagementsForChild(catererId).then((res) => handleResult(res));
            }
        }
    }, [catererId, isGroup, handleResult]);

    function getDeadlinesBody() {
        return <>
            <div className="mb-2">
                <strong>{t('Deadlines.ORDERS')}:</strong> {t('Deadlines.INFO_ORDER', deadlineParams.order)}
            </div>
            <div className="mb-2">
                <strong>{t('Deadlines.CANCELLATIONS')}:</strong> {t('Deadlines.INFO_CANCELLATION', deadlineParams.cancel)}
            </div>
            <div className="mb-0 d-inline-flex">
                <strong>{t('Deadlines.WEEKDAYS')}:</strong>&nbsp;
                <div className="pre-wrap">
                    <Trans
                        i18nKey="Deadlines.INFO_WEEKDAYS"
                        components={{b: <strong/>}}
                        values={{weekdays: deadlineParams.weekdays}}
                    />
                </div>
            </div>
        </>;
    }

    if (showAsCard) {
        return (
            <div className="card selected">
                <div className="card-header">
                    {label ? label : t('Deadlines.DEADLINES')}
                </div>
                <div className="card-body">
                    {getDeadlinesBody()}
                </div>
            </div>
        );
    }

    return (
        <InfoTooltip
            label={label ? label : t('Deadlines.DEADLINES')}
            showIcon={showIcon}>
            {getDeadlinesBody()}
        </InfoTooltip>
    );
}

export default Deadlines;
