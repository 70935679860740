import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PageTitleWithWallet from '../../common/component/PageTitleWithWallet';
import {checkState} from '../../user/action/userActions';
import '../style/ChildrenOverview.scss';
import MealPlan from './MealPlan';

function ChildrenOverview() {
    const [t] = useTranslation();
    const account = useSelector(state => state.user.account);
    const dispatch = useDispatch();
    const history = useHistory();
    const [children, setChildren] = useState([]);

    useEffect(() => {
        if (account.children && account.children.length) {
            const childrenCopy = [...account.children];

            setChildren(childrenCopy);
        }
    }, [account]);

    useEffect(() => {
        dispatch(checkState());
    }, [dispatch]);

    if (!children) {
        return null;
    }

    return (
        <div className="container">
            <PageTitleWithWallet
                headerTitle={t('ChildrenOverview.TITLE')}
                balance={account.balance}
                displayBig={true}
                pricePositionOffset="-20px"
            />
            <div className="row no-gutters children-overview">
                {
                    children && children.map(child =>
                        <MealPlan child={child} key={child.id}/>
                    )
                }
            </div>
            <button
                data-cy="addChild"
                className="btn btn-secondary float-right"
                onClick={() => history.push('/children/new')}
            >
                {t('Onboarding.ADD_CHILD')}
            </button>
        </div>
    );
}

export default ChildrenOverview;
