import React from 'react';
import OnboardingInfo from '../../onboarding/component/OnboardingInfo';
import ChildrenOverview from './ChildrenOverview';
import {useSelector} from 'react-redux';

function Dashboard() {
    const account = useSelector(state => state.user.account);

    if (account.children && account.children.length) {
        return <ChildrenOverview/>;
    } else {
        return <OnboardingInfo/>;
    }
}

export default Dashboard;
