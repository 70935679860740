import React from 'react';
import {Nav} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import HomeIcon from '../../icons/HomeIcon';
import PersonIcon from '../../icons/PersonIcon';
import IconNavLink from './IconNavLink';

function NavigationCarrier() {
    const [t] = useTranslation();
    const catererContacts = useSelector(state => state.user.catererContacts);

    return (
        <Nav className="ml-4 ml-lg-0">
            <IconNavLink to="/institutions" icon={<HomeIcon/>}>{t('Navigation.INSTITUTION_ADMINISTRATION')}</IconNavLink>
            {
                catererContacts.length === 1 ?
                    <IconNavLink exact={true} to="/carriers/me" icon={<PersonIcon/>}>{t('Navigation.CARRIER_ADMINISTRATION')}</IconNavLink>
                    : <IconNavLink to="/carriers">{t('Navigation.CARRIER_ADMINISTRATION')}</IconNavLink>
            }
        </Nav>
    );
}

export default NavigationCarrier;
