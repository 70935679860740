import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Child from '../../child/component/Child';
import {addDays, convertDateToIsoDateString, formatDate} from '../../common/util/DateUtil';
import mealPlanApi from '../../order/api/mealPlanApi';
import MealSlider from './MealSlider';

function MealPlan({child}) {
    const [t] = useTranslation();
    const currentLanguage = useSelector(state => state.i18n.currentLanguage);
    const fromDate = useMemo(() => new Date(), []);
    const toDate = useMemo(() => addDays(fromDate, 20), [fromDate]);

    const [meals, setMeals] = useState([]);

    useEffect(() => {
        if (child && child.group && !child.group.institutionPlacesOrders) {
            loadMeals();
        }
    }, []);

    function loadMeals() {
        mealPlanApi.findByTimespanAndChildId(convertDateToIsoDateString(fromDate), convertDateToIsoDateString(toDate), child.id).then(response => {
            if (response.data.success) {
                setMeals(response.data.result);
            }
        });
    }

    if (child && child.group && child.group.institutionPlacesOrders) {
        return (
            <>
                <div className="col-sm-6 col-md-4 col-lg-3 mb-2 mb-sm-4 pr-sm-3">
                    <Child child={child}/>
                </div>
                <div className="col-sm-6 col-md-8 col-lg-9 mb-4">
                    <div className="card h-100">
                        <div className="card-body text-muted d-flex align-items-center justify-content-center">
                            {t('Child.INSTITUTION_PLACES_ORDERS')}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-2 mb-sm-4 pr-sm-3">
                <Child child={child}/>
            </div>
            <div className="col-sm-6 col-md-8 col-lg-9 mb-4">
                <div className="card h-100">
                    <div className="card-header">
                        {t('MealPlan.TITLE')} {formatDate(currentLanguage, fromDate)} {t('Period.TO')} {formatDate(currentLanguage, toDate)}
                    </div>
                    {
                        child.group ?
                            <div className="card-body pl-0 pr-0">
                                <MealSlider meals={meals} fromDate={fromDate} toDate={toDate}/>
                            </div>
                            :
                            <div className="mr-2 ml-2 card-body alert alert-danger">
                                <div className="pre-wrap">
                                    {t('MealPlan.NO_GROUP', {
                                        childFirstName: child ? child.firstName : '',
                                        childLastName: child ? child.lastName : ''
                                    })}
                                </div>
                            </div>
                    }
                    <div className="card-footer border-0 bg-transparent pt-0 text-right">
                        {
                            child.group ?
                                <Link to={`/order/${child.id}`} className="btn btn-primary order-button">
                                    {t('MealPlan.ORDER')}
                                </Link>
                                :
                                <button className="btn btn-primary order-button" disabled={true}>
                                    {t('MealPlan.ORDER')}
                                </button>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default MealPlan;
