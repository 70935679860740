import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import BackButton from '../../../common/component/BackButton';
import NextButton from '../../../common/component/NextButton';
import SubscriptionDateDurationPicker from '../../../order/component/SubscriptionDateDurationPicker';
import {ChildSubscription} from '../../../types/child/ChildSubscription';

type Props = {
    subscription: Partial<ChildSubscription>,
    minOrderableDate: string,
    validation: any,
    onValidate: () => boolean,
    showValidationErrors: boolean,
    onChange: (key: string, value: any) => void,
    onRemovePause: () => void,
    onProceed: () => void,
    onGoBack: () => void,
    onSkip: () => void
}

function ChildSetupSubscriptionDuration({subscription, minOrderableDate, validation, onValidate, showValidationErrors, onChange, onRemovePause, onProceed, onGoBack, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();

    useEffect(() => {
        if (showValidationErrors) {
            onValidate();
        }
    }, [subscription.startDate, subscription.endDate, subscription.pauseStartDate, subscription.pauseEndDate, showValidationErrors, onValidate]);

    return <div className="card">
        <div className="card-header">{t('ChildSetup.SUBSCRIPTION.DURATION_HEADER')}</div>
        <div className="card-body">
            <p className="pre-wrap">{t('ChildSetup.SUBSCRIPTION.DURATION_INSTRUCTIONS')}</p>

            <SubscriptionDateDurationPicker
                startDate={subscription.startDate!}
                endDate={subscription.endDate}
                pauseStartDate={subscription.pauseStartDate}
                pauseEndDate={subscription.pauseEndDate}
                initialStartDate={minOrderableDate}
                minOrderableDate={minOrderableDate}
                handleStartDateChanged={value => onChange('startDate', value)}
                handleEndDateChanged={value => onChange('endDate', value)}
                handlePauseStartDateChanged={value => onChange('pauseStartDate', value)}
                handlePauseEndDateChanged={value => onChange('pauseEndDate', value)}
                handleRemovePause={onRemovePause}
                isStartDateValid={validation.startDate}
                isEndDateValid={validation.endDate}
                isPauseStartDateValid={validation.pauseStartDate}
                isPauseEndDateValid={validation.pauseEndDate}
                isDescriptionEnabled={false}
            />

            <div className="d-flex justify-content-end mt-4">
                <BackButton onClick={onGoBack} className="mr-2"/>
                <button className="btn btn-transparent mr-2" onClick={onSkip}>{t('ChildSetup.SKIP_SUBSCRIPTION')}</button>
                <NextButton onClick={onProceed}/>
            </div>
        </div>
    </div>;
}

export default ChildSetupSubscriptionDuration;
