const FoodRestrictionState = {
    'UNSELECTED': 0,
    'SELECTED_NO_UPLOAD': 1, //selected, ready for file upload, not savable
    'SELECTED_UPLOADED': 2, //selected with uploaded file, savable
    'SELECTED_NO_VERIFICATION': 3, // selected and with upload, but still needs verification, savable
    'SELECTED_APPROVED': 4,
    'SELECTED_DECLINED': 5,
    'SELECTED_ACCEPTED': 6
};

export default FoodRestrictionState;
