import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {currencyFormatter} from '../../common/util/CurrencyUtil';
import {UserType} from '../../types/UserType';
import {formatPendingPayment} from '../util/FinanceUtils';

function PendingPaymentsNotice() {
    const [t] = useTranslation();
    const currentLanguage = useSelector((state: {i18n: any}) => state.i18n.currentLanguage);
    const pendingPayments = useSelector((state: {user: UserType}) => state.user.pendingPayments);
    const myCurrencyFormatter = useMemo(() => currencyFormatter(currentLanguage), [currentLanguage]);

    if (!pendingPayments) {
        return null;
    }

    return (<>
        <div className="notification">
            <h3>
                {t('Finance.PENDING_PAYMENTS.CARD_HEADER')}
            </h3>
            <div className="pre-wrap">
                <p>{t('Finance.PENDING_PAYMENTS.INFO')}</p>
                <ul>
                    {
                        pendingPayments.map((payment) => <li key={payment.stripePaymentIntentId}>
                            {formatPendingPayment(payment, t, currentLanguage, myCurrencyFormatter)}
                        </li>)
                    }
                </ul>
            </div>
        </div>
    </>);
}

export default PendingPaymentsNotice;
