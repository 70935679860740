import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {LoginType} from '../../../types/account/LoginType';
import {LoginValidation} from '../../../types/account/LoginValidation';
import ErrorCode from '../../enums/ErrorCode';
import TextInput from './TextInput';

type Props = {
    login: Partial<LoginType>,
    validation: LoginValidation,
    onChange: (key: string, value: string) => void,
    onSubmit?: () => void,
    readOnly?: boolean,
    showEmail?: boolean,
    emailReadOnly?: boolean,
    showPassword?: boolean,
    isPasswordRequired?: boolean,
    passwordLabel?: string,
    showPasswordRepeatInOneLine?: boolean,
    mailErrorCode?: number
}

function LoginCredentials({
                              login,
                              onChange,
                              onSubmit,
                              validation,
                              readOnly = false,
                              showEmail = true,
                              emailReadOnly,
                              showPassword = true,
                              isPasswordRequired = true,
                              mailErrorCode,
                              passwordLabel = 'User.PASSWORD',
                              showPasswordRepeatInOneLine = true
                          }: Readonly<Props>) {
    const [t] = useTranslation();

    function getMailErrorMessage(): string | undefined {
        if (!validation.emailAddress && !!login.emailAddress) {
            return t('Validation.INVALID_EMAIL');
        }
        if (mailErrorCode) {
            const errorTypeArray = Object.entries(ErrorCode).find((entryArray: Array<any>) => entryArray[1].code === mailErrorCode);
            if (!errorTypeArray) {
                return undefined;
            }
            return t(`Error.${errorTypeArray[0]}`);
        }
        return undefined;
    }

    const handleEmailAddressKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && showEmail && !showPassword && !readOnly && onSubmit) {
            event.preventDefault();
            onSubmit();
        }
    }, [showEmail, showPassword, readOnly, onSubmit]);

    const handlePasswordRepeatKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && showPassword && !readOnly && onSubmit) {
            event.preventDefault();
            onSubmit();
        }
    }, [showPassword, readOnly, onSubmit]);

    return (
        <>
            {
                showEmail &&
                <TextInput
                    id="email"
                    type="email"
                    label={t('User.EMAIL')}
                    value={login.emailAddress}
                    onChange={value => onChange('emailAddress', value)}
                    onKeyDown={handleEmailAddressKeyDown}
                    required={!emailReadOnly}
                    isValid={validation.emailAddress && !mailErrorCode}
                    errorMessage={getMailErrorMessage()}
                    readonly={emailReadOnly}
                />
            }
            {
                showPassword &&
                <div className="row">
                    <div className={`col-12 ${showPasswordRepeatInOneLine ? 'col-lg-6' : ''}`}>
                        <TextInput
                            id="password"
                            type="password"
                            autoComplete="new-password"
                            label={t(passwordLabel)}
                            value={login.password}
                            onChange={value => onChange('password', value)}
                            required={isPasswordRequired || !!login.passwordRepeat}
                            isValid={validation.password}
                            readonly={readOnly}
                        />
                    </div>
                    <div className={`col-12 ${showPasswordRepeatInOneLine ? 'col-lg-6' : ''}`}>
                        <TextInput
                            id="passwordRepeat"
                            type="password"
                            label={t(`${passwordLabel}_REPEAT`)}
                            value={login.passwordRepeat}
                            onChange={value => onChange('passwordRepeat', value)}
                            onKeyDown={handlePasswordRepeatKeyDown}
                            required={isPasswordRequired || !!login.password}
                            errorMessage={login.passwordRepeat && login.password !== login.passwordRepeat ? t('Error.PASSWORD_MISMATCH') : undefined}
                            isValid={validation.passwordRepeat}
                            readonly={readOnly}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default LoginCredentials;
