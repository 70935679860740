import React from 'react';
import {useTranslation} from 'react-i18next';
import InfoTooltip from '../../common/component/InfoTooltip';
import OrderOption from '../../common/enums/OrderOption';
import '../style/OrderTypeAdvantages.scss';
import Deadlines from './Deadlines';

function OrderTypeAdvantages({
                                 orderOption,
                                 isInstitution = false,
                                 catererId
                             }) {
    const [t] = useTranslation();

    return (
        <ol className="ordered-list order-type-advantages mb-0">
            <li>
                <strong>{t(`OrderOption.ADVANTAGE.${orderOption}${isInstitution ? '_INSTITUTION' : ''}1`)}</strong>
                <span className="mr-2">{t(`OrderOption.DESCRIPTION.${orderOption}${isInstitution ? '_INSTITUTION' : ''}1`)}</span>
                {
                    !isInstitution &&
                    <InfoTooltip>
                        <strong>{t('OrderOption.BALANCE_INFO_TITLE')}</strong> {t('OrderOption.BALANCE_INFO')}
                    </InfoTooltip>
                }
            </li>
            <li>
                <strong>{t(`OrderOption.ADVANTAGE.${orderOption}${isInstitution ? '_INSTITUTION' : ''}2`)}</strong>
                {t(`OrderOption.DESCRIPTION.${orderOption}${isInstitution ? '_INSTITUTION' : ''}2`)}
                {
                    /* Show special tooltip and additional text for single order */
                    orderOption === OrderOption.SINGLE &&
                    <>
                        <Deadlines
                            label={t(`OrderOption.DESCRIPTION.SINGLE2${isInstitution ? '_INSTITUTION' : ''}_DEADLINES`)}
                            showIcon={false}
                            catererId={catererId}
                            isGroup={isInstitution}/>
                        {t(`OrderOption.DESCRIPTION.SINGLE2${isInstitution ? '_INSTITUTION' : ''}_2`)}
                    </>
                }
            </li>
            {
                /* Show third bullet for subscriptions */
                orderOption === OrderOption.SUBSCRIPTION &&
                <li>
                    <strong>{t(`OrderOption.ADVANTAGE.${orderOption}${isInstitution ? '_INSTITUTION' : ''}3`)}</strong>
                    {t(`OrderOption.DESCRIPTION.${orderOption}${isInstitution ? '_INSTITUTION' : ''}3`)}
                </li>
            }
        </ol>
    );
}

export default OrderTypeAdvantages;
