import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import BackButton from '../../../common/component/BackButton';
import Card from '../../../common/component/Card';
import NextButton from '../../../common/component/NextButton';
import CheckIcon from '../../../common/icons/CheckIcon';
import {ChildSubscription} from '../../../types/child/ChildSubscription';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import '../../style/childSetup/ChildSetup.scss';

type Props = {
    menuGroups: Array<MenuGroup>,
    subscription: Partial<ChildSubscription>,
    onChange: (key: string, value: any) => void,
    onProceed: () => void,
    onGoBack: () => void,
    onSkip: () => void
}

function ChildSetupSubscriptionMenuGroup({menuGroups, subscription, onChange, onProceed, onGoBack, onSkip}: Readonly<Props>) {
    const [t] = useTranslation();

    const [showMissingMenuGroup, setShowMissingMenuGroup] = useState(false);

    useEffect(() => {
        if (showMissingMenuGroup) {
            setShowMissingMenuGroup(!subscription.menuGroupId);
        }
    }, [subscription.menuGroupId, showMissingMenuGroup]);

    const handleClickNext = useCallback(() => {
        if (subscription.menuGroupId) {
            onProceed();
        } else {
            setShowMissingMenuGroup(true);
        }
    }, [subscription.menuGroupId, onProceed]);

    return <div className="card">
        <div className="card-header">{t('ChildSetup.SUBSCRIPTION.HEADER_MENU_GROUP')}</div>
        <div className="card-body">
            <p>{t('ChildSetup.SUBSCRIPTION.TEXT_MENU_GROUP')}</p>

            {
                showMissingMenuGroup &&
                <p className="text-error">{t('Validation.MISSING_CHOICE_FROM_OPTIONS')}</p>
            }

            <div className="row">
            {
                menuGroups.map(menuGroup => <div className="col-6 mb-4" key={menuGroup.id}>
                        <Card
                            className={`menu-group-selection-card ${subscription.menuGroupId === menuGroup.id ? 'active' : ''}`}
                            onClick={() => onChange('menuGroupId', menuGroup.id)}>
                            <div className="d-flex justify-content-between">
                                {menuGroup.name}
                                {subscription.menuGroupId === menuGroup.id && <div className="text-primary"><CheckIcon/></div>}
                            </div>
                        </Card>
                    </div>
                )
            }
            </div>

            <div className="d-flex justify-content-end mt-4">
                <BackButton onClick={onGoBack} className="mr-2"/>
                <button className="btn btn-transparent mr-2" onClick={onSkip}>{t('ChildSetup.SKIP_SUBSCRIPTION')}</button>
                <NextButton onClick={handleClickNext}/>
            </div>
        </div>
    </div>;
}

export default ChildSetupSubscriptionMenuGroup;
