import {httpClient} from '../../common/api/HttpClient';
import {convertDateToIsoDateString} from '../../common/util/DateUtil';
import {TransactionTypeEnum} from '../../types/enums/transaction/TransactionTypeEnum';

export const financeApi = {
    searchTransactionsForAccount: (accountId, filter) => {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 10;
        }
        const queryParams = searchCriteriaToQueryParams(accountId, filter, null);
        return httpClient.get(`transactions/search?${queryParams}`);
    },
    downloadBillById: (id) => {
        return httpClient.get(`billing/documents/${id}`, {responseType: 'blob'});
    },
    searchBills(accountId, institutionId, filter) {
        if (!filter.page) {
            filter.page = 0;
        }
        if (!filter.pageSize) {
            filter.pageSize = 10;
        }
        const queryParams = searchCriteriaToQueryParams(accountId, filter, institutionId);
        return httpClient.get(`billing/search?${queryParams}`);
    },
    fetchFinanceConfig: () => {
        return httpClient.get('config/gabel1/by-prefix/finance');
    }
};

const searchCriteriaToQueryParams = (accountId, filter, institutionId) => {
    const queryParams = new URLSearchParams();

    if (filter) {
        if (filter.page >= 0) {
            queryParams.append('page', filter.page);
        }
        if (filter.pageSize) {
            queryParams.append('pageSize', filter.pageSize);
        }
        if (accountId) {
            queryParams.append('accountId', accountId);
        }
        if (filter.transactionType) {
            queryParams.append('transactionType', filter.transactionType);
            if (filter.transactionType === TransactionTypeEnum.CREDIT && filter.creditType) {
                queryParams.append('creditType', filter.creditType);
            }
        }
        if (filter.bookingDateFrom) {
            queryParams.append('bookingDateFrom', convertDateToIsoDateString(filter.bookingDateFrom));
        }
        if (filter.bookingDateTo) {
            queryParams.append('bookingDateTo', convertDateToIsoDateString(filter.bookingDateTo));
        }
        if (filter.mealDateFrom) {
            queryParams.append('mealDateFrom', convertDateToIsoDateString(filter.mealDateFrom));
        }
        if (filter.mealDateTo) {
            queryParams.append('mealDateTo', convertDateToIsoDateString(filter.mealDateTo));
        }
        if (filter.name) {
            queryParams.append('name', filter.name);
        }
    }

    if (institutionId) {
        queryParams.append('institutionId', institutionId);
    }

    return queryParams.toString();
};

export default financeApi;
