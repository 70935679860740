import WelfareDepartmentType from '../../../common/enums/WelfareDepartmentType';
import React from 'react';
import '../../style/DepartmentSelection.scss';
import {useTranslation} from 'react-i18next';

function DepartmentSelection({
                                 selectedDepartment,
                                 onClick,
                                 label,
                                 displayError
                             }) {
    const [t] = useTranslation();

    return (
        <div className="form-group">
            <label>
                {`${label ? label : t('Welfare.DEPARTMENT_IN_CHARGE')} *`}
            </label>
            <div className="row department-selection no-gutters">
                {
                    Object.keys(WelfareDepartmentType).map((type, index) => {
                        return (
                            <div
                                className={`col btn ${type === selectedDepartment ? 'selected' : ''}`}
                                onClick={() => onClick(type)}
                                key={index}
                                role="button"
                            >
                                {t(`Welfare.${WelfareDepartmentType[type]}`)}
                            </div>
                        );
                    })
                }
            </div>
            {
                displayError &&
                <div className="alert-danger">
                    {t('Validation.REQUIRED')}
                </div>
            }
        </div>
    );
}

export default DepartmentSelection;
